import { Document, Page, Text, View } from '@react-pdf/renderer';

const styles = {
  page: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleSection: {
    marginBottom: 20,
    width: '60%'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    marginBottom: 10,
    width: '100%'
  },
  columnHeader: {
    width: '14%',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 12,
  },
  customerHeader: {
    width: '30%',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 12,
  },
  cell: {
    width: '14%',
    fontSize: 10,
    textAlign: 'left',
  },
  customerCell: {
    width: '30%',
    fontSize: 10,
    textAlign: 'left',
  },
  lastColumn: {
    borderRightWidth: 0,
  },
  tableBorder: {
    borderTopWidth: 1,
    borderTopColor: '#000',
  },
  totalsHeadersLeft: {
    width: '44%',
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: 12,
  },
  totalsValue: {
    width: '14%',
    textAlign: 'center',
    fontSize: 12,
  },
  totalsHeadersRight: {
    width: '28%',
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: 12,
  },

};

const DeliveriesPDF = ({ report, startDate, endDate }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleSection}>
          <Text style={styles.title}>Buckeye Hone On Time Deliveries Report - All Dates from {startDate || 'N/A'} to {endDate || 'N/A'}</Text>
        </View>
        {/* Table */}
        <View>
          <View style={styles.tableContainer}>
            <Text style={styles.columnHeader}>Order #</Text>
            <Text style={styles.customerHeader}>Customer</Text>
            <Text style={styles.columnHeader}>Promise Date</Text>
            <Text style={styles.columnHeader}>Invoice Date</Text>
            <Text style={styles.columnHeader}>Days</Text>
            <Text style={[styles.columnHeader, styles.lastColumn]}>On Time?</Text>
          </View>
          {report.data.map((item, index) => (
            <View key={index} style={styles.tableContainer}>
              <Text style={styles.cell}>{item.orderNumber}</Text>
              <Text style={styles.customerCell}>{item.customer}</Text>
              <Text style={styles.cell}>{item.promiseDate}</Text>
              <Text style={styles.cell}>{item.invoiceDate}</Text>
              <Text style={styles.cell}>{item.days}</Text>
              <Text style={[styles.cell, styles.lastColumn]}>{item.onTime}</Text>
            </View>
          ))}
          <View style={styles.tableContainer}>
            <Text style={styles.totalsHeadersLeft}>Number on Time:</Text>
            <Text style={styles.totalsValue}>{report.totals.numberOnTime}</Text>
            <Text style={styles.totalsHeadersRight}>Days on Time:</Text>
            <Text style={styles.totalsValue}>{report.totals.daysOnTime}</Text>
          </View>
          <View style={styles.tableContainer}>
            <Text style={styles.totalsHeadersLeft}>Number Not on Time:</Text>
            <Text style={styles.totalsValue}>{report.totals.numberNotOnTime}</Text>
            <Text style={styles.totalsHeadersRight}>Days Not on Time:</Text>
            <Text style={styles.totalsValue}>{report.totals.daysNotOnTime}</Text>
          </View>
        </View>
        <View style={styles.tableBorder} />
      </Page>
    </Document>
  )

}

export default DeliveriesPDF;