import { Document, Page, Text, View } from '@react-pdf/renderer';

const styles = {
  page: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleSection: {
    marginBottom: 20,
    width: '60%'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    marginBottom: 10,
    width: '60%'
  },
  columnHeader: {
    width: '50%',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12,
  },
  cell: {
    width: '50%',
    fontSize: 10,
    textAlign: 'center',
  },
  lastColumn: {
    borderRightWidth: 0,
  },
  tableBorder: {
    borderTopWidth: 1,
    borderTopColor: '#000',
  },
};

const PlugsPDF = ({ report, startDate, endDate }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleSection}>
          <Text style={styles.title}>Buckeye Hone Plugs Report - All Due Dates from {startDate || 'N/A'} to {endDate || 'N/A'}</Text>
        </View>
        {/* Table */}
        <View>
          <View style={styles.tableContainer}>
            <Text style={styles.columnHeader}>honeToMax</Text>
            <Text style={[styles.columnHeader, styles.lastColumn]}>plugs</Text>
          </View>
          {report.map((item, index) => (
            <View key={index} style={styles.tableContainer}>
              <Text style={styles.cell}>{item.honeToMax}</Text>
              <Text style={[styles.cell, styles.lastColumn]}>{item.plugs}</Text>
            </View>
          ))}
        </View>
        <View style={styles.tableBorder} />
      </Page>
    </Document>
  )

}

export default PlugsPDF;