import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Typography, Paper, Box, Button, Snackbar, TextField, Grid, useTheme, Modal, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import dayjs from 'dayjs';
import { PDFViewer } from '@react-pdf/renderer';
import EmployeeSearch from '../../../components/search/EmployeeSearch';
import EmployeeStore from '../../../stores/EmployeeStore';
import EmployeeTimeSheetPDF from '../../../pdf/EmployeeTimesheetPDF';
import ReportsStore from '../../../stores/ReportsStore';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EmployeeTimesheetReport = observer(() => {
  const [report, setReport] = useState({
    results: {
      employees: [],
      dates: []
    },
    message: '',
    status: ''
  });

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [timesheetDateFrom, setTimesheetDateFrom] = useState(null);
  const [timesheetDateTo, setTimesheetDateTo] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    const today = new Date();
    const sunday = new Date(today);
    sunday.setDate(today.getDate() - today.getDay());
    const saturday = new Date(sunday);
    saturday.setDate(sunday.getDate() + 6);

    setTimesheetDateFrom(dayjs(sunday));
    setTimesheetDateTo(dayjs(saturday));
  }, []);

  const handleClose = () => {
    setViewPdf(false);
  };

  const fetchEmployeeTimesheetReport = async (params) => {
    let allEmployees = [];

    if (!params.timesheetDateFrom) {
      setSnackbar({ open: true, message: 'Please select a start date', severity: 'info' });
      return;
    }

    if (!params.timesheetDateTo) {
      setSnackbar({ open: true, message: 'Please select an end date', severity: 'info' });
      return;
    }

    if (params.employeeId === '') {
      try {
        const { results: employees } = await EmployeeStore.fetchPaginatedEmployees(1, 100, 'lastName', { isActive: true });
        if (employees.length >= 100) {
          const { results: next100 } = await EmployeeStore.fetchPaginatedEmployees(2, 100, 'lastName', { isActive: true });
          employees.push(...next100);
        }
        if (employees.length >= 200) {
          const { results: next100 } = await EmployeeStore.fetchPaginatedEmployees(3, 100, 'lastName', { isActive: true });
          employees.push(...next100);
        }

        allEmployees = employees;

      } catch (error) {
        console.error("Error fetching employees:", error);
        setSnackbar({ open: true, message: 'Error fetching employees', severity: 'error' });
      }
    } else {
      const employee = await EmployeeStore.fetchEmployeeById(params.employeeId);
      allEmployees = [employee];
    }

    let dates = [];
    let currentDate = new Date(params.timesheetDateFrom);
    let endDate = new Date(params.timesheetDateTo);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setReport({
      results: {
        employees: allEmployees,
        dates
      }
    });
  };

  const generateReports = () => {
    const startDate = timesheetDateFrom ? dayjs(timesheetDateFrom).format('YYYY-MM-DD') : '';
    const endDate = timesheetDateTo ? dayjs(timesheetDateTo).format('YYYY-MM-DD') : '';

    const employeeTimesheetReportParams = {
      employeeId,
      timesheetDateFrom: startDate,
      timesheetDateTo: endDate
    };

    fetchEmployeeTimesheetReport(employeeTimesheetReportParams);
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: 2 }}>
          <Typography variant="h4">Employee Report</Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3} padding={2}>
            <Grid item xs={12} md={6}>
              <EmployeeSearch
                sx={{ width: '100%' }}
                handleChange={(event) => setEmployeeId(event ? event._id : '')}
                showAdd={false}
                marginTop={0}
                showInactive={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <DatePicker
                  label="Timesheet Date From"
                  value={timesheetDateFrom}
                  onChange={(newValue) => setTimesheetDateFrom(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />

                <DatePicker
                  label="Timesheet Date To"
                  value={timesheetDateTo}
                  onChange={(newValue) => setTimesheetDateTo(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
              <Button onClick={() => generateReports()} sx={{ width: '200px', fontWeight: 'bold' }}>Generate Report</Button>
              {report.results?.employees?.length > 0 && (
                <Button onClick={() => setViewPdf(true)} sx={{ width: '200px' }}>View as PDF</Button>
              )}
            </Box>
          </Grid>
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 4 }}>
        {viewPdf ? (
          <Modal
            open={viewPdf}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
              <IconButton
                onClick={handleClose}
                sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
              >
                <CloseIcon />
              </IconButton>
              <PDFViewer width="100%" height="100%">
                <EmployeeTimeSheetPDF employees={report.results.employees} dates={report.results.dates} />
              </PDFViewer>
            </Paper>
          </Modal>
        ) : null}
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default EmployeeTimesheetReport;
