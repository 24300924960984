import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  header: {
    fontSize: 18,
    marginBottom: 20,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '12.5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 12,
    padding: 3,
  },
  tableCol: {
    width: '12.5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 3,
    fontSize: 10,
  },
  tableCell: {
    margin: 5,
    fontSize: 8,
  },
});
const PayablesByVendorPDF = ({ report }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>Check Register Report</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableColHeader}>ID</Text>
          <Text style={styles.tableColHeader}>Invoice Amount</Text>
          <Text style={styles.tableColHeader}>Paid Amount</Text>
          <Text style={styles.tableColHeader}>Date Paid</Text>
          <Text style={styles.tableColHeader}>Due Date</Text>
          <Text style={styles.tableColHeader}>Invoice Date</Text>
          <Text style={styles.tableColHeader}>Invoice No</Text>
          <Text style={styles.tableColHeader}>Vendor</Text>
          <Text style={styles.tableColHeader}>Void</Text>
        </View>
        {report.result.map((row, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCol}>{row.payablesId}</Text>
            <Text style={styles.tableCol}>{row.invoiceAmount}</Text>
            <Text style={styles.tableCol}>{row.paidAmount}</Text>
            <Text style={styles.tableCol}>{dayjs(row.datePaid).format('MM/DD/YYYY')}</Text>
            <Text style={styles.tableCol}>{dayjs(row.dueDate).format('MM/DD/YYYY')}</Text>
            <Text style={styles.tableCol}>{dayjs(row.invoiceDate).format('MM/DD/YYYY')}</Text>
            <Text style={styles.tableCol}>{row.invoiceNo}</Text>
            <Text style={styles.tableCol}>{row.vendor.vendorName}</Text>
            <Text style={styles.tableCol}>{row.void ? 'Yes' : 'No'}</Text>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export default PayablesByVendorPDF;
