import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomerStore from '../../stores/CustomerStore';
import CustomerForm from './CustomerForm';
import { Box, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';

const AddCustomer = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

  const handleSubmit = async (newCustomer) => {
    setLoading(true);
    try {
      await CustomerStore.createCustomer(newCustomer);
      navigate('/customers');
    } catch (error) {
      console.error('Failed to add new customer:', error);
      setSnackbar({ open: true, message: 'Failed to add new customer', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ m: 4 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Add New Customer</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <CustomerForm onSubmit={handleSubmit} />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddCustomer;
