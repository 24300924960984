import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OrderForm from './OrderForm';
import { Box, Typography, Snackbar, Alert, Button } from '@mui/material';
import CustomerSearch from '../../components/search/CustomerSearch';
import authStore from '../../stores/AuthStore';
import ShopOrdersStore from '../../stores/ShopOrdersStore';

const AddOrder = () => {
  const [newOrder, setNewOrder] = useState({
    salesOrderNumber: '',
    orderNumber: '',
    customerPONumber: '',
    purchaseOrderNumber: '',
    millJobNumber: '',
    commissionId: '',
    customer: '',
    isCancelled: false,
    isBucyrus: false,
    billingAddress: '',
    dueDate: '',
    promisedDate: '',
    shipDate: '',
    enteredDate: '',
    receivedDate: '',
    finishedDate: '',
    shippingAddress: '',
    pocBilling: '',
    pocShipping: '',
    tubeOuterDiameter: '',
    honeInnerDiameter: {
      lowerLimit: '',
      upperLimit: ''
    },
    actualInnerDiameter: '',
    stockRemoval: '',
    SurfaceFinish: {
      Type: 'RQ',
      Value: '',
    },
    rq: '',
    weight: '',
    value: '',
    totalFeet: {
      theoretical: '',
      actual: ''
    },
    tubeLength: {
      theoretical: '',
      actual: ''
    },
    totalPieces: {
      theoretical: '',
      actual: ''
    },
    bundleCount: '',
    bundles: [],
    cutPieces: '',
    cutOAL: '',
    OAL: '',
    bandingClips: '',
    Plugs: {
      noPlugs: false,
      Count: '',
    },
    rate: '',
    feetPerHour: '',
    totalHours: '',
    costPerFoot: '',
    costPerPiece:'',
    charges: '',
    total: 0,
    specialInstructions: '',
    notes: [],
    attachments: [],
    createdBy: ''
  });
  const [customer, setCustomer] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const updatedOrder = {
        ...newOrder,
        total: 10,
        createdBy: authStore.userId
      };
      await ShopOrdersStore.createShopOrder(updatedOrder);
      navigate('/orders');
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to create order. Make sure you aren't missing any fields.", severity: 'error' });
    }
  };

  const handleChange = (orderData) => {
    setNewOrder(orderData);
  };

  const handleCustomerChange = (customer) => {
    setCustomer(customer);
    if (customer) {
      setNewOrder({
        ...newOrder,
        customer: customer._id
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>Add New Order</Typography>
      <Box m={2}>
        <CustomerSearch handleChange={handleCustomerChange} showInactive={true} />
      </Box>
      {customer ? (
        <OrderForm order={newOrder} onChange={handleChange} onSubmit={handleSubmit} />
      ) : (
        <Box m={2}>
          <Typography variant="h6" sx={{ mb: 3 }}>Please select a customer</Typography>
        </Box>
      )}
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'right' }}>
        <Button variant='outlined' onClick={() => navigate('/orders')}>
          Back to Orders
        </Button>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddOrder;
