import React, { createContext, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthStore from '../stores/AuthStore';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  return (
    <AuthContext.Provider value={AuthStore}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const PrivateRoute = ({ children }) => {
  const authStore = useAuth();
  const location = useLocation();

  if (!authStore.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};
