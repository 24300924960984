import { useState } from 'react';
import {
  Box, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import PlugReport from './PlugReport';
import DeliveriesReport from './DeliveriesReport';
import OpenMasterReport from './OpenMasterReport';
import DaysInProcessReport from './DaysInProcessReport';
import ClosedMasterReport from './ClosedMasterReport';
import ProductionListReport from './ProductionListReport';
import ProductionFootageReport from './ProductionFootageReport';

const Products = () => {
  const [report, setReport] = useState(0);

  const handleReportChange = (event) => {
    setReport(event.target.value);
  };

  const renderReport = () => {
    switch (report) {
      case 0:
        return <ProductionListReport />;
      case 1: 
        return <ProductionFootageReport />;
      case 2:
        return <PlugReport />;
      case 3:
        return <DeliveriesReport />;
      case 4:
        return <OpenMasterReport />;
      case 5:
        return <DaysInProcessReport />;
      case 6:
        return <ClosedMasterReport />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box sx={{ margin: 2, marginTop: 4 }}>
        <FormControl fullWidth>
          <InputLabel id="product-report-select-label">Select Report</InputLabel>
          <Select
            labelId="product-report-select-label"
            value={report}
            onChange={handleReportChange}
          >
            <MenuItem value={0}>Production List</MenuItem>
            <MenuItem value={1}>Footage</MenuItem>
            <MenuItem value={2}>Plugs</MenuItem>
            <MenuItem value={3}>Deliveries</MenuItem>
            <MenuItem value={4}>Open Master</MenuItem>
            <MenuItem value={5}>Days in Process</MenuItem>
            <MenuItem value={6}>Closed Master</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {renderReport()}
    </Box>
  );
};

export default Products;
