import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Typography, Paper, Box, Button, Snackbar, Grid, Modal, IconButton, useTheme } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import { PDFViewer } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import CustomerSearch from '../../../components/search/CustomerSearch';
import SalesByCustomerPDF from '../../../pdf/SalesByCustomerPDF';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SalesByCustomerReport = observer(() => {
  const [salesByCustReport, setSalesByCustReport] = useState({
    results: {
      sales: []
    },
    message: '',
    status: ''
  });

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewSalesByCustomerPdf, setViewSalesByCustomerPdf] = useState(false);
  const [customerId, setCustomerId] = useState('');

  const theme = useTheme();

  const fetchSalesByCustomerReport = async (params) => {
    setLoading(true);
    ReportsStore.fetchSalesByCustomerReport(params).then(() => {
      setSalesByCustReport(ReportsStore.detailedReports.salesByCustomer);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch sales by customer report', severity: 'error' });
      setLoading(false);
    });
  }

  const generateSalesByCustomerReport = () => {
    const salesByCustomerReportParams = {
      customerId: customerId
    }
    fetchSalesByCustomerReport(salesByCustomerReportParams);
  }

  const handleClose = () => {
    setViewSalesByCustomerPdf(false);
  }

  const salesByCustColumns = [
    { field: 'customerName', headerName: 'Customer', flex: 1 },
    { field: 'orderNumber', headerName: 'Order Number', flex: 1 },
    { field: 'millJobNumber', headerName: 'Mill Job Number', flex: 1 },
    { field: 'dueDate', headerName: 'Due Date', flex: 1 },
    { field: 'total', headerName: 'Total', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 }
  ]

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: 2 }}>
          <Typography variant="h4">Sales by Customer Report</Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={12} md={6}>
            <CustomerSearch
              sx={{ width: '100%' }}
              handleChange={(event) => setCustomerId(event ? event._id : '')}
              showAdd={false}
              marginTop={0}
              showInactive={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
              <Button onClick={() => generateSalesByCustomerReport()} sx={{ width: '200px', fontWeight: 'bold' }}>Generate Sales By Customer Report</Button>
              {salesByCustReport.results?.sales.length > 0 && (
                <Button onClick={() => setViewSalesByCustomerPdf(true)} sx={{ width: '200px' }}>View as PDF</Button>
              )}
            </Box>
          </Grid>
        </LocalizationProvider>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 4, width: '100%' }}>
          {viewSalesByCustomerPdf ? (
            <Modal
              open={viewSalesByCustomerPdf}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
                <IconButton
                  onClick={handleClose}
                  sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
                >
                  <CloseIcon />
                </IconButton>
                <PDFViewer width={'100%'} height={'100%'}>
                  <SalesByCustomerPDF report={salesByCustReport.results} />
                </PDFViewer>
              </Paper>
            </Modal>
          ) : (
            <DataGridPro
              rows={salesByCustReport.results?.sales || []}
              columns={salesByCustColumns}
              autoHeight
              loading={loading}
              getRowId={(row) => row.orderNumber}
            />
          )}
        </Box>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default SalesByCustomerReport;
