import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MachineStore from '../../stores/MachineStore';
import MachineForm from './MachineForm';
import { Box, Typography, CircularProgress, IconButton, Button, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const NewMachineOperator = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
  const [machine, setMachine] = useState({
    machineId: '',
    name: '',
    description: '',
    operators: [],
    status: 'active',
    location: '',
    lastMaintenanceDate: '',
    nextMaintenanceDate: '',
    maintenanceHistory: [],
    comments: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    notes: [],
    attachments: []
  });

  useEffect(() => {
    findNewMachineId();
  }, []);

  const findNewMachineId = async () => {
    try {
      const machines = await MachineStore.fetchPaginatedMachines();
      const totalMachines = machines.totalCount;

      let newId = totalMachines;

      do {
        newId++;
      } while (!checkId(newId));

      setMachine({ ...machine, machineId: newId });

    } catch (error) {
      console.error('Failed to fetch machines:', error);
      setSnackbar({ open: true, message: 'Failed to fetch machines', severity: 'error' });
    }
  }

  const checkId = async (id) => {
    const filter = { machineId: id };
    try {
      const result = await MachineStore.fetchPaginatedMachines(1, 1, 'name', filter);
      return !(result && result.totalCount > 0);
    } catch (error) {
      console.error(error);
      setSnackbar({ open: true, message: 'Failed to check machine ID', severity: 'error' });
      return false;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await MachineStore.createMachine(machine);
      setLoading(false);
      navigate('/orders');
    } catch (error) {
      console.error('Failed to create machine:', error);
      setSnackbar({ open: true, message: 'Failed to create machine', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (updatedMachine) => {
    setMachine(updatedMachine);
  }

  const handleDelete = async () => {
    try {
      await MachineStore.deleteMachine(machine.machineId);
      navigate('/orders');
    } catch (error) {
      console.error('Failed to delete machine:', error);
      setSnackbar({ open: true, message: 'Failed to delete machine', severity: 'error' });
    }
  }

  const handleBack = () => {
    navigate('/orders');
  }

  return (
    <Box sx={{ m: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" mb={4}>New Machine</Typography>
        <Box>
          <IconButton onClick={handleBack}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <MachineForm machine={machine} onSubmit={handleSubmit} onChange={handleChange} back={handleBack} />
          <Button sx={{ mx: 3 }} variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
        </>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewMachineOperator;
