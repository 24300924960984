import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import CustomerStore from '../../stores/CustomerStore';
import {
  Box, Typography, CircularProgress, Paper, Divider, Grid,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CustomerDetails = () => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);

  const isAddressEmpty = ({ street, line2, city, state, postalCode, country }) => {
    const addressParts = [street, line2, city, state, postalCode, country];
    return addressParts.every(part => !part || part.trim() === '');
  };

  const formatAddress = ({ street, line2, city, state, postalCode, country }) => {
    const addressParts = [street, line2, city, state, postalCode, country];
    const nonEmptyParts = addressParts.filter(part => part && part.trim() !== '');

    return nonEmptyParts.join(', ');
  };

  const displayShippingAddress = () => {
    const shippingAddress = customer.shippingAddress;
    return isAddressEmpty(shippingAddress) ? 'Same As Billing' : formatAddress(shippingAddress);
  };

  const displayBillingAddress = () => {
    const billingAddress = customer.billingAddress;
    return billingAddress ? formatAddress(billingAddress) : 'Same As Shipping';
  };

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const fetchedCustomer = await CustomerStore.fetchCustomerById(customerId);
        setCustomer(fetchedCustomer);
      } catch (error) {
        console.error('Error fetching customer:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomer();
  }, [customerId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!customer) {
    return (
      <Box sx={{ m: 4, textAlign: 'center' }}>
        <Typography variant="h5">Customer not found</Typography>
        <Link to="/customers" style={{ textDecoration: 'none' }}>
          <Typography variant="body1" color="primary">
            Go back to Customer List
          </Typography>
        </Link>
      </Box>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 4, mx: 'auto', my: 2, maxWidth: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 3 }}>
        <Link to="/customers" style={{ textDecoration: 'none' }}>
          <ArrowBackIcon sx={{ color: 'primary.main', fontSize: 30, marginRight: '20px' }} />
        </Link>
        <Typography variant="h4">Customer Details</Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
              <Typography variant="h6" fontWeight="bold">General Information</Typography>
              <Typography variant="body1">Company ID: {customer.companyId}</Typography>
              <Typography variant="body1">Abbreviation: {customer.companyAbbreviation}</Typography>
              <Typography variant="body1">Name: {customer.companyName}</Typography>
              <Typography variant="body1">Phone: {customer.companyPhone}</Typography>
              <Typography variant="body1">Fax: {customer.companyFax}</Typography>
              <Typography variant="body1">Website: {customer.website}</Typography>
              <Typography variant="body1">Email: {customer.companyEmail}</Typography>
              <Divider sx={{margin: '24px 0 24px 0'}} />
              <Typography variant="body1">Comments: {customer.comments}</Typography>
        </Grid>

        <Grid item xs={12} lg={6}>
              <Typography variant="h6" fontWeight="bold">Billing and Shipping</Typography>
              <Typography variant="body1">Billing Address: {displayBillingAddress()} </Typography>
              <Typography variant="body1">Shipping Address: {displayShippingAddress()} </Typography>
              <Divider sx={{margin: '24px 0 24px 0'}} />
              <Typography  variant="h6" fontWeight="bold">Payment Terms</Typography>
              <Typography variant="body1">Discount: {customer.discount}%</Typography>
              <Typography variant="body1">Billing Terms: {customer.billingTerms.days} days, Net {customer.billingTerms.net}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CustomerDetails;
