import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Box, Avatar, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SupervisedUserCircleOutlined from '@mui/icons-material/SupervisedUserCircleOutlined';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import BusinessIcon from '@mui/icons-material/BusinessOutlined';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import PaymentIcon from '@mui/icons-material/PaymentOutlined';
import PaymentsIcon from '@mui/icons-material/PaymentsOutlined';
import ReportIcon from '@mui/icons-material/ReportOutlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, useNavigate } from 'react-router-dom';
import authStore from '../stores/AuthStore';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';

const generateAvatarBg = () => {
  const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33F6', '#F6FF33'];
  return colors[Math.floor(Math.random() * colors.length)];
};

export default function Navbar({ children, username }) {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openUserMenu = Boolean(anchorEl);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [avatarBg, setAvatarBg] = useState(localStorage.getItem('avatarBg') || generateAvatarBg());

  useEffect(() => {
    if (!localStorage.getItem('avatarBg')) {
      localStorage.setItem('avatarBg', avatarBg);
    }
  }, [avatarBg]);

  const drawerWidth = 250;

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMobileOpen(open);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    authStore.logout();
    navigate('/login');
  };

  const initials = username ? username.charAt(0).toUpperCase() : 'U';


  const drawerItems = [
    { name: "Dashboard", icon: <DashboardIcon />, to: "/dashboard" },
    { name: "Orders", icon: <ReceiptIcon />, to: "/orders" },
    { name: "Receivables", icon: <ReceiptIcon />, to: "/receivables" },
    { name: "Employee", icon: <PeopleIcon />, to: "/employee" },
    { name: "Vendor", icon: <BusinessIcon />, to: "/vendor" },
    { name: "Productions", icon: <ProductionQuantityLimitsIcon />, to: "/productions" },
    { name: "Payroll", icon: <PaymentsIcon />, to: "/payroll"},
    { name: "Customers", icon: <PeopleIcon />, to: "/customers" },
    { name: "Payables", icon: <PaymentIcon />, to: "/payables" },
    { name: "Reports", icon: <ReportIcon />, to: "/reports" },
    { name: "Users", icon: <GroupIcon />, to: "/users" },
    { name: "Preferences", icon: <SupervisedUserCircleOutlined />, to: "/preferences" },
  ];

  const list = () => (
    <Box
      role="presentation"
      onClick={() => !isLargeScreen && toggleDrawer(false)}
      onKeyDown={() => !isLargeScreen && toggleDrawer(false)}
      sx={{ width: 250, display: 'flex', flexDirection: 'column', justifyContent: 'start', height: '100vh', position: 'relative' }}
    >
      <ListItem>
        <ListItemText
          primary="Buckeye Hone"
          primaryTypographyProps={{ variant: "h5" }}
        />
      </ListItem>
      <Divider />
      <List>
        {drawerItems.map((item) => (
          <ListItem
            button
            key={item.name}
            component={Link}
            to={item.to}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
        <Divider />
        <ListItem button component={Link} to="/help">
          <ListItemIcon>
            <HelpOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Help" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/licensing">
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Licensing" />
        </ListItem>
        <ListItem button component={Link} to="/privacy">
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          <ListItemText primary="Privacy" />
        </ListItem>
      </List>
      <Divider />
      <ListItem sx={{ position: 'absolute', bottom: 0 }}>
        <ListItemText primary="© Kratesoft LLC" />
      </ListItem>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ boxShadow: 'none', borderBottom: 'none', backgroundColor: 'white', color: 'black', borderRadius: 0, borderBottom: '2px solid', borderColor: 'primary.main', zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {!isLargeScreen && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Buckeye Hone - Elite Suite
          </Typography>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar sx={{ bgcolor: avatarBg }}>{initials}</Avatar>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openUserMenu}
            onClose={handleClose}
          >
            <MenuItem component={Link} to="/preferences" onClick={handleClose}>Preferences</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isLargeScreen ? 'permanent' : 'temporary'}
        anchor="left"
        open={isLargeScreen || mobileOpen}
        onClose={toggleDrawer(false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            borderRight: '2px solid',
            borderColor: 'primary.main',
            borderRadius: 0,
          },
        }}
      >
        {list()}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: 100,
          paddingTop: '64px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
