import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Typography, Box, Grid, Card, CardContent, IconButton, Menu, MenuItem, useTheme, TextField, Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SortIcon from '@mui/icons-material/Sort';
import ReportsStore from '../stores/ReportsStore';
import CustomerStore from '../stores/CustomerStore';
import InvoiceStore from '../stores/InvoiceStore';
import ShopOrdersStore from '../stores/ShopOrdersStore';
import dayjs from 'dayjs';
import FrequencyPicker from '../components/FrequencyPicker';
import { LocalizationProvider, DateRangePicker, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const Dashboard = observer(() => {
  const theme = useTheme();
  const [dashboardData, setDashboardData] = useState([
    { id: 'monthlyIncome', title: `Monthly Income`, value: 0, isEmpty: true, settings: [] },
    { id: 'footageHoned', title: 'Footage Honed', value: 0, isEmpty: true, settings: [] },
    { id: 'paidVsUnpaidOrders', title: 'Paid vs. Unpaid Orders', value: '', isEmpty: true, settings: [] },
    { id: 'activeCustomers', title: 'Active Customers', value: 0, isEmpty: true, settings: [] },
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isCustom, setIsCustom] = useState(false);

  const paidFilter = {
    paymentStatus: 'paid'
  };

  const unpaidFilter = {
    $or: [
      { paymentStatus: 'unpaid' },
      { paymentStatus: { $exists: false } }
    ]
  }

  const fetchData = async (startDate, endDate, freq) => {

    const startDateString = dayjs(startDate).format('YYYY-MM-DD');
    const endDateString = dayjs(endDate).format('YYYY-MM-DD');

    const unpaidDates = {
      ...unpaidFilter,
      finishedDate: {
        $gte: new Date(startDate).toISOString(),
        $lte: new Date(endDate).toISOString()
      }
    }

    const paidDates = {
      ...paidFilter,
      finishedDate: {
        $gte: new Date(startDate).toISOString(),
        $lte: new Date(endDate).toISOString()
      }
    }

    let paid = 0;
    let unpaid = 0;


    try {
      const masterReportParams = {
        dueDateFrom: startDateString,
        dueDateTo: endDateString
      }
      await ReportsStore.fetchClosedMasterReport(masterReportParams);
    } catch (error) {
      if (error.response.data.message === 'No documents found.') {
      } else {
        console.error('Error fetching data for dashboard:', error);
      }
    }
    try {
      const unpaidOrders = await ShopOrdersStore.fetchPaginatedShopOrders(1, 10, 'salesOrderNumber', unpaidDates);
      unpaid = unpaidOrders.totalCount;
    } catch (error) {
      if (error.response && error.response.data.message === 'No documents found.') {
      } else {
        console.error('Error fetching data for dashboard:', error);
      }
      unpaid = 0;
    }
    try {
      const paidOrders = await ShopOrdersStore.fetchPaginatedShopOrders(1, 10, 'salesOrderNumber', paidDates);
      paid = paidOrders.totalCount;
    } catch (error) {
      if (error.response.data.message === 'No documents found.') {
      } else {
        console.error('Error fetching data for dashboard:', error);
      }
      paid = 0;
    }
    try {
      await CustomerStore.fetchPaginatedCustomers(1, 10, 'companyName', { isActive: true });
    } catch (error) {
      if (error.response.data.message === 'No documents found.') {
      } else {
        console.error('Error fetching data for dashboard:', error);
      }
    }
    try {
      await InvoiceStore.fetchTotalIncome(startDateString, endDateString);
    } catch (error) {
      if (error.response.data.message === 'No documents found.') {
      } else {
        console.error('Error fetching data for dashboard:', error);
      }
    }
    updateDashboardData(paid, unpaid, freq);
  };

  useEffect(() => {

    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    startDate.setMonth(startDate.getMonth() - 1);

    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    const initialFetch = async () => {
      await fetchData(startDate, endDate, 'Monthly');
    };

    initialFetch();

  }, []);

  const updateDashboardData = (paidOrders, unpaidOrders, frequency) => {
    setDashboardData(prevData => prevData.map(item => {
      switch (item.id) {
        case 'monthlyIncome': return { ...item, title: `${frequency} Income`, value: calculateMonthlyIncome(item.settings), isEmpty: false };
        case 'footageHoned': return { ...item, value: calculateFootageHoned(item.settings), isEmpty: false };
        case 'paidVsUnpaidOrders': return { ...item, value: `${paidOrders} Paid / ${unpaidOrders} Unpaid`, isEmpty: false };
        case 'activeCustomers': return { ...item, value: calculateActiveCustomers(item.settings), isEmpty: false };
        default: return item;
      }
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = reorder(dashboardData, result.source.index, result.destination.index);
    setDashboardData(items);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFrequencyChange = async (frequency) => {
    let startDate = new Date();
    let endDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    let freq = ''
    switch (frequency) {
      case 'daily':
        setIsCustom(false);
        freq = 'Daily'
        break;
      case 'weekly':
        startDate.setDate(startDate.getDate() - 7);
        setIsCustom(false);
        freq = 'Weekly'
        break;
      case 'monthly':
        startDate.setMonth(startDate.getMonth() - 1);
        setIsCustom(false);
        freq = 'Monthly'
        break;
      case 'quarterly':
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const quarterStartMonth = Math.floor(currentMonth / 3) * 3;
        startDate = new Date(currentDate.getFullYear(), quarterStartMonth, 1);
        setIsCustom(false);
        freq = 'Quarterly'
        break;
      case 'yearly':
        startDate.setFullYear(startDate.getFullYear() - 1);
        setIsCustom(false);
        freq = 'Yearly'
        break;
      case 'custom':
        setIsCustom(true);
        freq = 'Custom'
        break;
      default:
        console.log('Unknown frequency');
    }

    await fetchData(startDate, endDate, freq);
  };

  const handleCustomDate = async () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    await fetchData(start, end);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: theme.spacing(3) }}>
      <Typography variant='h4' gutterBottom>Dashboard</Typography>
      <FrequencyPicker onChange={handleFrequencyChange} />
      {isCustom && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3} padding={2}>
            <Grid item xs={12} md={5}>
              <DatePicker
                sx={{ width: '100%' }}
                label="Start Date"
                // value={startDate}
                onChange={(newValue) => {
                  setStartDate(new Date(newValue))
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <DatePicker
                sx={{ width: '100%' }}
                label="End Date"
                // value={endDate}
                onChange={(newValue) => {
                  setEndDate(new Date(newValue))
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={2} onClick={handleCustomDate}>
              <Button sx={{ width: '100%', margin: 1 }}>
                Apply
              </Button>
            </Grid>
          </Grid>
        </LocalizationProvider>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='dashboardDroppable' direction='horizontal'>
          {(provided) => (
            <Grid container spacing={2} {...provided.droppableProps} ref={provided.innerRef} sx={{ userSelect: 'none' }}>
              {dashboardData.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <Grid item xs={12} sm={6} md={3} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <Card>
                        <CardContent sx={{ textAlign: 'center' }}>
                          {item.isEmpty ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 140 }}>
                              <InfoIcon color='action' />
                              <Typography variant='subtitle1' color='textSecondary'>No data</Typography>
                            </Box>
                          ) : (
                            <>
                              <Typography variant='h6'>{item.title}</Typography>
                              <Typography variant='body1'>{item.value}</Typography>
                            </>
                          )}
                        </CardContent>
                        {!item.isEmpty && (
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                            <IconButton size='small' onClick={(event) => handleClick(event, item.id)}><SortIcon /></IconButton>
                            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                              {item.settings.map((setting, index) => (
                                <MenuItem key={index} onClick={handleClose}>
                                  {setting}
                                </MenuItem>
                              ))}
                            </Menu>
                          </Box>
                        )}
                      </Card>
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
});

const calculateMonthlyIncome = (settings) => {
  // const currentMonth = new Date().getMonth();
  // const monthlyIncome = ReportsStore.reports
  //   .filter(report => new Date(report.date).getMonth() === currentMonth)
  //   .reduce((sum, report) => sum + report.income, 0);
  const monthlyIncome = InvoiceStore.totalIncome || 0;
  return monthlyIncome;
};

const calculateFootageHoned = (settings) => {
  const totalFootage = ReportsStore.detailedReports.closedMaster.results?.data
    .reduce((sum, plug) => sum + plug.orders.reduce((sum, plug) => sum + plug.feet, 0), 0);
  return totalFootage;
};

const calculateActiveCustomers = (settings) => {
  return CustomerStore.paginatedCustomers.totalCount;
  // return CustomerStore.customers.filter(customer => customer.isActive).length;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export default Dashboard;
