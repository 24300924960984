import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CustomerStore from '../../stores/CustomerStore';
import ShopOrdersStore from "../../stores/ShopOrdersStore";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Grid,
  ListItem,
  ListItemText,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const OrderDetails = () => {
  const [order, setOrder] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { orderId } = useParams();

  useEffect(() => {
    ShopOrdersStore.fetchShopOrderById(orderId).then((fetchedOrder) => {
      if (fetchedOrder) {
        setOrder(fetchedOrder);
      } else {
        console.error("Fetched order is not valid");
        setOrder(null);
      }
    });
  }, [orderId]);

  useEffect(() => {
    if (order && order.customer) {
      async function fetchCustomer() {
        const customerData = await CustomerStore.fetchCustomerById(order.customer);
        setCustomer(customerData.companyName);
      }
      fetchCustomer();
    }
  }, [order]);

  if (!order) {
    return <CircularProgress />;
  }

  const handleEdit = () => {
    navigate(`/orders/edit/${orderId}`);
  };

  const handleBack = () => {
    navigate("/orders");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatDate = (date) => {
    return date
      ? new Date(date).toLocaleDateString(undefined, {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      : "N/A";
  };

  const formatNumber = (number) => {
    const parsedNumber = parseFloat(number);
    return !isNaN(parsedNumber) ? parsedNumber.toFixed(3) : "N/A";
  };
  
  const renderDetail = (label, value, isNumber = false) => (
    <ListItem>
      <ListItemText primary={label} secondary={isNumber ? (value !== null && value !== undefined ? formatNumber(value) : "N/A") : (value !== null && value !== undefined ? value : "N/A")} />
    </ListItem>
  );  

  const renderPlugs = (label, value) => (
    <ListItem>
      <ListItemText primary={label} secondary={value.noPlugs ? 'False' : value.Count} />
    </ListItem>
  );

  const calculateTotalCost = (order) => {
    const { totalFeet, costPerFoot, totalPieces, costPerPiece, charges } = order;
    let cost = 0;
  
    if (totalFeet?.actual && costPerFoot) {
      cost += parseFloat(totalFeet.actual) * costPerFoot;
    }
  
    if (totalPieces?.actual && costPerPiece) {
      cost += parseFloat(totalPieces.actual) * costPerPiece;
    }
  
    if (charges) {
      cost += charges;
    }
  
    return cost.toFixed(2);
  };

  const totalCost = calculateTotalCost(order);

  return (
    <Box sx={{ p: 3 }}>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Order Details - {order?.salesOrderNumber}
        </Typography>
        <Box display="flex">
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => { handleEdit(); handleClose(); }}>
              Edit
            </MenuItem>
            <MenuItem onClick={() => { handleBack(); handleClose(); }}>
              Back to Orders
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <Grid container spacing={3}>

        <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>
          General Information
        </Typography>
        {renderDetail("Shop Order #", order.salesOrderNumber)}
        {renderDetail("Purchase Order #", order.customerPONumber)}
        {renderDetail("Mill/Job #", order.millJobNumber)}
        {renderDetail("Commission ID", order.commissionCode? order.commissionCode.commissionDescription : "No Commission Available")}
        {renderDetail("Customer Name", order.customerName)}
        {renderDetail("Cancelled", order.isCancelled ? "Yes" : "No")}
        {renderDetail("Due Date", formatDate(order.dueDate))}
        {renderDetail("Promise Date", formatDate(order.promiseDate))}
        {renderDetail("Ship Date", formatDate(order.shipDate))}
        {renderDetail("Date Entered", formatDate(order.enteredDate))}
        {renderDetail("Date Received", formatDate(order.receivedDate))}
        {renderDetail("Finish Date", formatDate(order.finishedDate))}
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>
          Product and Manufacturing Details
        </Typography>
        {renderDetail("Tube OD", order.tubeOuterDiameter, true)}
        {renderDetail("Actual Inner Diameter", order.actualInnerDiameter, true)}
        {renderDetail("Stock Removal", order.stockRemoval, true)}
        {renderDetail("Total Pieces", order.totalPieces?.actual)}
        {renderDetail("Number of Bundles", order.bundleCount)}
        {renderDetail("Cut Pieces", order.cutPieces)}
        {renderDetail("Cut On Average Length", order.cutOAL)}
        {renderDetail("On Average Length", order.OAL)}
        {renderDetail("Banding Clips", order.bandingClips)}
        {renderPlugs("Plugs", order.Plugs)}
        {renderDetail(
          "Hone Inner Diameter (Lower)",
          order.honeInnerDiameter?.lowerLimit,
          true
        )}
        {renderDetail(
          "Hone Inner Diameter (Upper)",
          order.honeInnerDiameter?.upperLimit,
          true
        )}
      </Grid>

        
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>
          Cost Information
        </Typography>
        {renderDetail("Rate", order.rate)}
        {renderDetail("Feet per Hour", order.feetPerHour)}
        {renderDetail("Total Hours", order.totalHours)}
        {renderDetail("Cost Per Foot", order.costPerFoot)}
        {renderDetail("Cost Per Piece", order.costPerPiece)}
        {renderDetail("Charges", order.charges)}
        {renderDetail("Total Feet (Estimate)", order.totalFeet.theoretical)}
        {renderDetail("Total Feet (Actual)", order.totalFeet.actual)}
        {renderDetail("Tube Length (Estimate)", order.tubeLength.theoretical)}
        {renderDetail("Tube Length (Actual)", order.tubeLength.actual)}
        {renderDetail("Rq", order.SurfaceFinish?.Value)}
        {renderDetail("Weight", order.weight)}
        {renderDetail("Special Instructions", order.specialInstructions)}
        {renderDetail("Comments", order.comments)}
        {renderDetail("Total Cost", '$' + totalCost)}
      </Grid>

        {/* Shipping Address */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Shipping Address
          </Typography>
          {renderDetail("Business Name", order.shippingAddress.name || order.shippingAddress.businessName)}
          {renderDetail("Street", order.shippingAddress.street)}
          {renderDetail("City", order.shippingAddress.city)}
          {renderDetail("State", order.shippingAddress.state)}
          {renderDetail("Postal Code", order.shippingAddress.postalCode)}
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
        <Button variant="contained" onClick={handleEdit}>
          Edit
        </Button>
        <Button variant="outlined" onClick={handleBack}>
          Back to Orders
        </Button>
      </Box>
    </Box>
  );
};

export default OrderDetails;
