import { makeAutoObservable, toJS } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class ReceivablesStore {
  customers = [];
  orders = [];
  receivables = [];
  paginatedReceivables = {
    results: [],
    totalCount: 0,
    currentPage: 1,
    pageSize: 10,
    totalPages: 0
  };
  currentReceivable = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchCustomersAndOrders() {
    try {
      const customersResponse = await apiHandler.get('/customers');
      const ordersResponse = await apiHandler.get('/orders');
      
      this.customers = customersResponse.results || [];
      this.orders = ordersResponse.results || [];

      this.filterCustomersWithOpenOrders();
    } catch (error) {
      console.error("Error fetching customers and orders:", error);
    }
  }

  async fetchPaginatedReceivables(page = 1, pageSize = 10, sortBy = 'dueDate', filter = {}, search = '', searchField = 'invoiceNo') {
    try {
      const { results, ...pagination } = await apiHandler.get(`/receivables?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`);
      this.paginatedReceivables = { ...pagination, results };
      return { results, ...pagination };
    } catch (error) {
      console.error("Error fetching paginated receivables:", error);
    }
  }

  async fetchPaginatedReceivablesByCustomer(customerId, page = 1, pageSize = 10, invoiceFilter = {}, receivableFilter = {}) {
    try {
      const { results, ...pagination } = await apiHandler.get(`/receivables/byCustomer/${customerId}?page=${page}&limit=${pageSize}&invoiceFilter=${JSON.stringify(invoiceFilter)}&receivableFilter=${JSON.stringify(receivableFilter)}`);
      const filteredResults = results.filter(receivable => receivable.amtPaid < receivable.invoice.totalAmount);

      this.paginatedReceivables = { ...pagination, totalCount: filteredResults.length, results: filteredResults };
    } catch (error) {
      console.error("Error fetching paginated receivables by customer:", error);
    }
  };

  async createReceivable(receivableData) {
    try {
      const response = await apiHandler.post('/receivables', receivableData);
      this.receivables.push(response);
    } catch (error) {
      console.error("Error creating receivable:", error);
    }
  }

  async getReceivableById(receivableId) {
    try {
      const response = await apiHandler.get(`/receivables/${receivableId}`);
      this.currentReceivable = response;
    } catch (error) {
      console.error("Error getting receivable by ID:", error);
    }
  }

  async updateReceivable(receivableId, receivableData) {
    try {
      const response = await apiHandler.put(`/receivables/${receivableId}`, receivableData);
      this.receivables = this.receivables.map(receivable =>
        receivable._id === receivableId ? response : receivable
      );
    } catch (error) {
      console.error("Error updating receivable:", error);
    }
  }

  async deleteReceivable(receivableId) {
    try {
      await apiHandler.delete(`/receivables/${receivableId}`);
      this.receivables = this.receivables.filter(receivable => receivable._id !== receivableId);
    } catch (error) {
      console.error("Error deleting receivable:", error);
    }
  }

  filterCustomersWithOpenOrders() {
    const openOrders = this.orders.filter(order => order.status === 'Open');
    this.customers = this.customers.filter(customer => 
      openOrders.some(order => order.customerId === customer._id)
    );
  }
}

const receivablesStore = new ReceivablesStore();
export default receivablesStore;
