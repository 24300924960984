import {
  Button,
  Grid,
  TextField,
  Divider,
  Box,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EmployeeSearch from '../search/EmployeeSearch';
import MachineSearch from '../search/MachineSearch';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';

function CustomFooter(props) {

  const style = {
    flex: 1,
    pl: '10px',
    pr: '10px',
  }

  return (
    <>
      <Divider />
      <Box sx={{ display: 'flex', pt: 1, pb: 1 }}>
        <Box sx={style} />
        <Box sx={style} />
        <Box sx={style} />
        <Box sx={style} />
        <Box sx={style} />
        <Box sx={style} />
        <Box sx={style} />
        <Box sx={style}>
          <Typography variant="body2" align='right' pr={2} >Total:</Typography>
        </Box>
        <Box sx={style}>
          <Typography variant="body2" >{props.pieces}</Typography>
        </Box>
        <Box sx={style}>
          <Typography variant="body2" >{props.feet}</Typography>
        </Box>
        <Box sx={style}>
          <Typography variant="body2" >{props.hours}</Typography>
        </Box>
        <Box sx={style}></Box>
      </Box>
    </>
  )
}

const ReworkTab = ({ productions, handleAddProduction, deleteJob }) => {

  const [newProduction, setNewProduction] = useState({
    costFoot: 0,
    costPiece: 0,
    jobDate: '',
    employeeId: null,
    reworkEmpId: null,
    feet: 0,
    hours: 0,
    pieces: 0,
    machineId: null,
    rate: 0,
    reworkId: '',
    orderId: null,
    orderNumber: '',
    workType: 'R',
    comments: '',
  });

  const handleDateChange = (date) => {
    setNewProduction({ ...newProduction, jobDate: date.toISOString() });
  };

  const handleMachineChange = (machine) => {
    if (machine) {
      setNewProduction({ ...newProduction, machineId: machine._id });
    } else {
      setNewProduction({ ...newProduction, machineId: '' });
    }
  };

  const handleEmployeeChange = (employee) => {
    if (employee) {
      setNewProduction({ ...newProduction, employeeId: employee._id });
    } else {
      setNewProduction({ ...newProduction, employeeId: '' });
    }
  };

  const handleReworkEmployeeChange = (employee) => {
    if (employee) {
      setNewProduction({ ...newProduction, reworkEmpId: employee._id });
    } else {
      setNewProduction({ ...newProduction, reworkEmpId: '' });
    }
  };

  return (
    <>
      <Grid container margin={0} marginTop={0} spacing={2} display={'flex'} direction={'row'}>
        <Grid item xs={12} md={6}>
          <MachineSearch handleChange={handleMachineChange} />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ mt: 2, width: '100%' }}
              name='date'
              label='Date'
              value={newProduction.jobDate}
              onChange={handleDateChange}
            />
          </LocalizationProvider>

          <EmployeeSearch handleChange={handleEmployeeChange} label={'Employee'} />

          {newProduction.workType === 'R' && (
            <EmployeeSearch handleChange={handleReworkEmployeeChange} label={'Rework Employee'} />
          )}
        </Grid>
        <Grid item xs={12} md={6} mt={1}>
          <TextField
            label="Pieces"
            type="number"
            value={newProduction.pieces}
            fullWidth
            onChange={(event) => setNewProduction({ ...newProduction, pieces: event.target.value })}
            sx={{ m: 1 }}
          />
          <TextField
            label="Feet"
            type="number"
            value={newProduction.feet}
            fullWidth
            onChange={(event) => setNewProduction({ ...newProduction, feet: event.target.value })}
            sx={{ m: 1 }}
          />
          <TextField
            label="Hours"
            type="number"
            value={newProduction.hours}
            fullWidth
            onChange={(event) => setNewProduction({ ...newProduction, hours: event.target.value })}
            sx={{ m: 1 }}
          />
        </Grid>
        <TextField
          multiline
          rows={3}
          label="Comments"
          value={newProduction.comments}
          fullWidth
          onChange={(event) => setNewProduction({ ...newProduction, comments: event.target.value })}
          sx={{ m: 2 }}
        />
        <Button sx={{ margin: 'auto', display: 'flex', mt: 2 }} onClick={() => handleAddProduction(newProduction)}>
          Add Production
        </Button>

      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />

      <DataGridPro
        rows={productions.map((production, index) => ({ ...production, count: index + 1 }))}
        columns={[
          { field: 'count', headerName: 'Count', flex: 1 },
          { field: 'machineName', headerName: 'Machine', flex: 1, renderCell: (params) => params.row.machineId.name },
          { field: 'jobDate', headerName: 'Date', flex: 1, renderCell: (date) => format(new Date(date.value), 'MM/dd/yyyy') },
          { field: 'employee', headerName: 'Employee', flex: 2, renderCell: (params) => `${params.row.employeeId ? (params.row.employeeId.firstName + ',') : 'N/A'} ${params.row.employeeId?.firstName || ''} ${params.row.employeeId?.middleName || ''}` },
          { field: 'reworkEmployee', headerName: 'Rework Employee', flex: 2, renderCell: (params) => `${params.row.reworkEmpId ? (params.row.reworkEmpId.firstName + ',') : 'N/A'} ${params.row.reworkEmpId?.firstName || ''} ${params.row.reworkEmpId?.middleName || ''}` },
          { field: 'comments', headerName: 'Comments', flex: 1 },
          { field: 'pieces', headerName: 'Pieces', flex: 1 },
          { field: 'feet', headerName: 'Feet', flex: 1 },
          { field: 'hours', headerName: 'Hours', flex: 1 },
          { field: 'action', headerName: 'Action', flex: 1, renderCell: (params) => <Button onClick={() => deleteJob(params.row._id)}>Delete</Button> }
        ]}
        autoHeight
        getRowId={(row) => row._id}
        slots={{
          footer: CustomFooter,
        }}
        slotProps={{
          footer: {
            pieces: productions.reduce((total, production) => total + Number(production.pieces), 0),
            feet: productions.reduce((total, production) => total + Number(production.feet), 0),
            hours: productions.reduce((total, production) => total + Number(production.hours), 0),
          }
        }}
      />
    </>
  )
};

export default ReworkTab;