import React, { useState, useEffect } from "react";
import { Grid, TextField, Autocomplete, Button, Modal, Box, Typography, CircularProgress } from "@mui/material";
import CommissionCodeStore from "../../stores/CommissionCodeStore";
import { debounce } from "lodash";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '80%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  overflowY: 'scroll',
  p: 4,
};

const CommissionCodeSearch = ({ margin = 0, handleChange, marginTop, initialCommissionCode }) => {
  const [modalState, setModalState] = useState(false);
  const [commissionCodes, setCommissionCodes] = useState({
    results: [],
    totalCount: 0,
  });
  const [open, setOpen] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null);
  const loading = open && commissionCodes.totalCount === 0;

  const handleInputChange = debounce((event, value, reason) => {
    const numOptions = commissionCodes.results.filter((commissionCode) =>
      commissionCode.commissionCode.toLowerCase().includes(value.toLowerCase())
    );
    if (value.length > 0 && numOptions.length < 10) {
      const filter = {
        $or: [
          { commissionCode: { $regex: value, $options: 'i' } },
          { commissionDescription: { $regex: value, $options: 'i' } }
        ]
      };

      getPaginatedCommissionCodes(1, 20, 'commissionCode', filter, '', 'commissionCode');
    }
  }, 500);

  const getPaginatedCommissionCodes = async (page, pageSize, sortBy, filter, search, searchField) => {
    try {
      await CommissionCodeStore.fetchPaginatedCommissionCodes(page, pageSize, sortBy, filter, search, searchField);
      setCommissionCodes(CommissionCodeStore.paginatedCommissionCodes);
    } catch (error) {
      console.error('Failed to fetch commission codes:', error);
    }
  };

  const fetchInitialCommissionCode = async (id) => {
    try {
      const code = await CommissionCodeStore.fetchCommissionCodeById(id);
      setSelectedCode(code);
    } catch (error) {
      console.error('Failed to fetch initial commission code:', error);
    }
  };

  useEffect(() => {
    if (initialCommissionCode) {
      fetchInitialCommissionCode(initialCommissionCode);
    }
  }, [initialCommissionCode]);

  useEffect(() => {
    let active = true;
    let page = 1;
    let pageSize = 20;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        await getPaginatedCommissionCodes(
          page,
          pageSize,
          'commissionCode',
          {},
          '',
          'commissionCode'
        );
        if (active) {
          setCommissionCodes(CommissionCodeStore.paginatedCommissionCodes);
        }
      } catch (error) {
        console.error('Failed to fetch commission codes:', error);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setCommissionCodes({
        results: [],
        totalCount: 0,
      });
    }
  }, [open]);

  return (
    <>
      <Modal
        open={modalState}
        onClose={() => {
          setModalState(!modalState);
        }}
      >
        <Box sx={style}>
          <Typography variant="h4" sx={{ mb: 2 }}>Add New Commission Code</Typography>
        </Box>
      </Modal>
      <Grid container margin={margin} marginTop={(marginTop || marginTop === 0) ? marginTop : (margin + 3)}>
        {commissionCodes ? (
          <>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                fullWidth
                id="commissionCode"
                getOptionLabel={(option) => `${option.commissionCode} - ${option.commissionDescription}`}
                options={commissionCodes.results}
                value={selectedCode}
                onChange={(event, newValue) => {
                  setSelectedCode(newValue);
                  handleChange(newValue);
                }}
                onInputChange={handleInputChange}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                loading={loading}
                isOptionEqualToValue={(option, value) => {
                  return option._id === value._id;
                }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Search Commission Code"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                }
              />
            </Grid>
          </>
        ) : (
          <>
            Loading Commission Codes...
          </>
        )}
      </Grid>
    </>
  )
};

export default CommissionCodeSearch;
