import { Document, Page, Text, View } from '@react-pdf/renderer';

const styles = {
  page: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  titleSection: {
    width: '100%',
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 8,
    paddingTop: 8,
    paddingRight: 8
  },
  subtitleSection: {
    width: '100%',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 8
  },
  simpleCell: {
    border: 1,
    width: '30%',
    textAlign: 'center',
    margin: -0.5,
    padding: 2
  },
  table: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
    fontSize: 12,
    padding: 8,
    marginTop: 32
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    border: 1,
    margin: -0.5
  },
  tableCell: {
    borderLeft: 1,
    padding: 2,
  }
}

const InvoicePDF = ({ invoice }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleSection}>
          <Text style={{ flex: 1 }}>Buckeye Hone Company</Text>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flex: 1 }}>
            <Text>Invoice: {invoice.invoiceNumber}</Text>
          </View>
        </View>
        <View style={styles.subtitleSection}>
          <View style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 4 }}>
            <Text>PO Box 72</Text>
            <Text>Mercer, PA 16137-0072</Text>
            <Text style={{ height: 16 }} />
            <Text>(724) 748-3008</Text>
            <Text>Fed ID#:25-1366564</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 12 }}>
              <Text style={[styles.simpleCell, { width: '30%' }]}>Invoice Date</Text>
              <Text style={[styles.simpleCell, { width: '40%' }]}>Your Order No</Text>
              <Text style={[styles.simpleCell, { width: '30%' }]}>Our Order No</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.simpleCell, { width: '30%' }]}>{new Date(invoice.invoiceDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) || 'N/A'}</Text>
              <Text style={[styles.simpleCell, { width: '40%' }]}>{invoice.customerPONumber || 'N/A'}</Text>
              <Text style={[styles.simpleCell, { width: '30%' }]}>{invoice.salesOrderNumber || 'N/A'}</Text>
            </View>
          </View>
        </View>
        <View style={styles.subtitleSection}>
          {/* Billing Address */}
          <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <Text style={{ flex: 1 }}>Sold To:</Text>
            <View style={{ display: 'flex', flexDirection: 'column', gap: 4, flex: 5 }}>
              <Text>{invoice.customerName}</Text>
              <Text>{invoice.billingAddress.street}</Text>
              <Text>{invoice.billingAddress.line2}</Text>
              <Text>{invoice.billingAddress.city}, {invoice.billingAddress.state} {invoice.billingAddress.postalCode}</Text>
            </View>
          </View>
          {/* Shipping Address */}
          <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <Text style={{ flex: 1 }}>Ship To:</Text>
            <View style={{ display: 'flex', flexDirection: 'column', gap: 4, flex: 5 }}>
              <Text>{invoice.shippingAddress.name}</Text>
              <Text>{invoice.shippingAddress.street}</Text>
              <Text>{invoice.shippingAddress.city}, {invoice.shippingAddress.state} {invoice.shippingAddress.postalCode}</Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={{ flex: 1.5, padding: 2 }}>Terms: {invoice.customer.discountPercentage}% {invoice.customer.discountDays} Days, Net {invoice.customer.netDays} </Text>
            <Text style={{ flex: 1, padding: 2 }}>Ship Via:</Text>
            <Text style={{ flex: 1, padding: 2 }}>FOB: Mercer, PA</Text>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCell, { width: '10%', borderLeft: 0 }]}></View>
            <Text style={[styles.tableCell, { width: '90%' }]}>Rep:</Text>
          </View>
          <View style={[styles.tableRow, { backgroundColor: '#c0c0c0' }]}>
            <Text style={[styles.tableCell, { width: '10%', textAlign: 'center', borderLeft: 0  }]}>Quantity</Text>
            <Text style={[styles.tableCell, { width: '50%' }]}>Description</Text>
            <Text style={[styles.tableCell, { width: '20%', textAlign: 'center' }]}>Unit Price</Text>
            <Text style={[styles.tableCell, { width: '20%', textAlign: 'center' }]}>Total</Text>
          </View>
          <View style={[styles.tableRow, { height: 64 }]}>
            <View style={[styles.tableCell, { width: '10%', display: 'flex', flexDirection: 'column', borderLeft: 0  }]}>
              <Text style={{ height: 16 }} />
              <Text style={{ height: 16 }} />
              <Text style={{ height: 24 }} />
              <Text style={{ textAlign: 'center' }}>{invoice.feet}'</Text>
            </View>
            <View style={[styles.tableCell, { width: '50%', display: 'flex', flexDirection: 'column' }]}>
              <Text style={{ fontSize: 10 }}>{invoice.outerHone} X {invoice.innerHone} TUBES HONED {invoice.orderComments ? `- ${invoice.orderComments}` : ''}</Text>
            </View>
            <View style={[styles.tableCell, { width: '20%', display: 'flex', flexDirection: 'column' }]}>
              <Text style={{ height: 16 }} />
              <Text style={{ textAlign: 'center' }}>${invoice.costPerFoot} per ft.</Text>
              <Text style={{ height: 24 }} />
              <Text style={{ textAlign: 'center' }}>3% Surcharge</Text>
            </View>
            <View style={[styles.tableCell, { width: '20%', display: 'flex', flexDirection: 'column' }]}>
              <Text style={{ height: 16 }} />
              <Text style={{ textAlign: 'right' }}>${(invoice.costPerFoot * invoice.feet).toFixed(2) || 'N/A'}</Text>
              <Text style={{ height: 24 }} />
              <Text style={{ textAlign: 'right' }}>${invoice.additionalCharges.toFixed(2)}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, { height: 16 }]}>
            <Text style={[styles.tableCell, { width: '10%', borderLeft: 0  }]} />
            <Text style={[styles.tableCell, { width: '50%' }]} />
            <Text style={[styles.tableCell, { width: '20%' }]} />
            <Text style={[styles.tableCell, { width: '20%' }]} />
          </View>
          <View style={[styles.tableRow, { height: 16 }]}>
            <Text style={[styles.tableCell, { width: '10%', borderLeft: 0  }]} />
            <Text style={[styles.tableCell, { width: '50%' }]} />
            <Text style={[styles.tableCell, { width: '20%' }]} />
            <Text style={[styles.tableCell, { width: '20%' }]} />
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { width: '10%', borderLeft: 0  }]} />
            <Text style={[styles.tableCell, { width: '50%', textAlign: 'center' }]}>Mill No: {invoice.millNo}</Text>
            <Text style={[styles.tableCell, { width: '20%' }]} />
            <Text style={[styles.tableCell, { width: '20%' }]} />
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { width: '10%', borderLeft: 0  }]} />
            <Text style={[styles.tableCell, { width: '30%', textAlign: 'center' }]}>Bundles: {invoice.bundles}</Text>
            <Text style={[styles.tableCell, { width: '20%', textAlign: 'center' }]}>OAL: {invoice.oal}</Text>
            <Text style={[styles.tableCell, { width: '20%' }]} />
            <Text style={[styles.tableCell, { width: '20%' }]} />
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { width: '10%', borderLeft: 0  }]} />
            <Text style={[styles.tableCell, { width: '30%', textAlign: 'center' }]}>Pieces: {invoice.pieces}</Text>
            <Text style={[styles.tableCell, { width: '20%', textAlign: 'center' }]}>Weight: {invoice.weight}#</Text>
            <Text style={[styles.tableCell, { width: '20%' }]} />
            <Text style={[styles.tableCell, { width: '20%' }]} />
          </View>
          <View style={[styles.tableRow, { height: 16 }]} />
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { width: '60%', borderLeft: 0  }]} />
            <Text style={[styles.tableCell, { width: '20%' }]}>Total Due: </Text>
            <Text style={[styles.tableCell, { width: '20%', textAlign: 'right' }]}>${invoice.totalAmount.toFixed(2)}</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
};

export default InvoicePDF;