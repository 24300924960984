import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import PayablesStore from '../stores/PayablesStore';
import DynamicSearchFilter from '../components/DynamicSearchFilter';
import {
  Box, Typography, List, ListItem, CircularProgress, Snackbar, IconButton,
  ListItemText, TablePagination, ListItemButton, useTheme, ListItemSecondaryAction, Alert,
  Modal,
  Paper,
  Checkbox,
  Tooltip,
  Grid,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format, set } from 'date-fns';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import CheckPDF from '../pdf/CheckPDF';
import { formatCurrency } from '../utils/numberFormatting';
import VendorStore from '../stores/VendorStore';

const CustomFooterComponent = ({ outstandingAmount, paymentTotal, handleSave }) => {
  return (
    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 2, gap: 2 }}>
      <Grid item>
        <Typography fontWeight={'bold'}>Total Outstanding: {formatCurrency(outstandingAmount)}</Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight={'bold'}>Total To Be Paid: {formatCurrency(paymentTotal || 0)}</Typography>
      </Grid>
      <Grid item>
        <Button variant="outlined" color="primary" onClick={handleSave}>Save</Button>
      </Grid>
    </Grid>
  )
}

const Payables = observer(() => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [payables, setPayables] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 100,
    totalPages: 0,
    results: []
  });
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('-dueDate');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [searchQuery, setSearchQuery] = useState('');
  const [checkData, setCheckData] = useState({});
  const [viewPdf, setViewPdf] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [updatedPayables, setUpdatedPayables] = useState([]);
  const [outstandingOwed, setOutstandingOwed] = useState(0);
  const [totalToBePaid, setTotalToBePaid] = useState(0);

  useEffect(() => {
    fetchPaginatedPayables(1, sortBy, filter, search);
  }, [page, sortBy, filter, search]);

  const fetchPaginatedPayables = async (page, sortBy, filter, search = searchQuery) => {

    let searchFilter = {
      ...filter,
      payThis: false
    };

    setLoading(true);
    PayablesStore.fetchPaginatedPayables(page, 100, sortBy = "dueDate", searchFilter, search).then(() => {
      setPayables(PayablesStore.paginatedPayables);
      const owed = PayablesStore.paginatedPayables.results.reduce((total, payable) => total + parseFloat(payable.invoiceAmount), 0);
      setOutstandingOwed(owed);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch payables', severity: 'error' });
      setLoading(false);
    });
  };

  const handleCloseModal = () => {
    setViewPdf(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDynamicSearch = async (search, filter, sortBy) => {
    const vendors = await VendorStore.fetchPaginatedVendors(1, 100, 'vendorName', {}, search);
    const vendorIds = vendors?.results.map(vendor => vendor._id) || [];

    const updatedFilter = {
      ...filter,
      $or: [
        { vendor: { $in: vendorIds } },
        { invoiceNo: { $regex: search, $options: 'i' } },
      ]
    };

    const isDecimalNumber = /^\d+(\.\d+)?$/.test(search);
    if (isDecimalNumber) updatedFilter.$or.push({invoiceAmount: search});
    if (filter.dueDateFrom || filter.dueDateTo) updatedFilter.dueDate = {};
    if (filter.dueDateFrom) updatedFilter.dueDate.$gte = filter.dueDateFrom;
    if (filter.dueDateTo) updatedFilter.dueDate.$lte = filter.dueDateTo;
    if (filter.invoiceDateFrom || filter.invoiceDateTo) updatedFilter.invoiceDate = {};
    if (filter.invoiceDateFrom) updatedFilter.invoiceDate.$gte = filter.invoiceDateFrom;
    if (filter.invoiceDateTo) updatedFilter.invoiceDate.$lte = filter.invoiceDateTo;

    setFilter(updatedFilter);
  };

  const handleFilterChange = (model) => {
    let filterBuilder = {};
    if (model.items.length > 0) {
      model.items.forEach((item) => {
        if (item.value && item.operator === 'contains') {
          filterBuilder[item.field] = { $regex: item.value, $options: 'i' };
        }
      });
      setFilter(filterBuilder);
    } else {
      setFilter({});
    }
  };

  const handleTableSortChange = (model) => {
    let sort = '';
    if (model[0]) {
      sort = model[0].sort === 'asc' ? model[0].field : `-${model[0].field}`;
    } else {
      sort = '';
    }
    setSortBy(sort);
  };

  const handlePageChange = (event, page) => {
    fetchPaginatedPayables(page + 1, 100);
  };

  const handlePrintPayable = () => {
    const selectedPayables = payables.results.filter((payable) => selectedInvoices.includes(payable._id));
    const combinedPayables = selectedPayables.reduce((acc, curr) => {
      acc.amount += curr.invoiceAmount;
      acc.text += `Invoice No: ${curr.invoiceNo}, Amount: $${curr.invoiceAmount.toFixed(2)}\n`;
      return acc;
    }, { amount: 0, text: '' });

    const today = new Date();
    const formattedDate = Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    }).format(today);

    const data = {
      checkNumber: 'Combined Check',
      date: formattedDate,
      payee: selectedPayables[0]?.vendor.vendorName,
      amount: combinedPayables.amount,
      text: combinedPayables.text
    };
    setCheckData(data);
    setViewPdf(true);
  };

  const handleCreatePayable = () => {
    // Implement logic to create a new payable
    navigate('/payable/add');
  };

  const handlePayThisCheck = (payableId, payThis, invoiceAmount) => {
    const updatedPayable = updatedPayables.find(payable => payable._id === payableId);
    let updated = updatedPayables;
    if (updatedPayable) {
      if (!payThis) {
        updated = updatedPayables.filter(payable => payable._id !== payableId);
      }
    } else {
      updated = [...updatedPayables, { _id: payableId, payThis, invoiceAmount }];
    }

    const totalToPay = updated.reduce((total, payable) => total + (payable.payThis ? parseFloat(payable.invoiceAmount) : 0), 0);
    setTotalToBePaid(totalToPay);
    setUpdatedPayables(updated);
  }

  const handleSave = () => {

    updatedPayables.forEach(async payable => {

      try {
        const paid = {
          payThis: payable.payThis,
          paidAmount: payable.invoiceAmount,
          datePaid: new Date()
        };
        await PayablesStore.updatePayable(payable._id, paid);
      } catch (error) {
        setSnackbar({ open: true, message: 'Failed to update payable', severity: 'error' });
      }

    });

    fetchPaginatedPayables();
    setUpdatedPayables([]);
    setTotalToBePaid(0);
    setSnackbar({ open: true, message: 'Payables updated successfully', severity: 'success' });
  }

  const handleViewPayable = (payableId) => {
    navigate(`/payable/${payableId}`);
  };

  const handleEditPayable = (payableId) => {
    navigate(`/payable/edit/${payableId}`);
  };

  const handleSelectInvoice = (invoiceId) => {
    setSelectedInvoices((prevSelected) => {
      if (prevSelected.includes(invoiceId)) {
        return prevSelected.filter((id) => id !== invoiceId);
      }
      return [...prevSelected, invoiceId];
    });
  };

  const payableInterface = {
    invoiceNo: '',
    vendor: {
      _id: '',
      vendorId: '',
      vendorName: ''
    },
    invoiceDate: new Date(),
    dueDate: new Date(),
    totalAmount: 0
  };

  const columns = [
    {
      field: 'vendorName',
      headerName: 'Vendor Name',
      flex: 1,
      valueGetter: (params) => params.row.vendor?.vendorName
    },
    {
      field: 'invoiceNo',
      headerName: 'Invoice No',
      flex: 1
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 1,
      valueFormatter: (params) => params.value ? format(new Date(params.value), 'MM/dd/yyyy') : 'N/A'
    },
    {
      field: 'invoiceAmount',
      headerName: 'Invoice Amount',
      flex: 1,
      valueGetter: (params) => `$${params.row.invoiceAmount.toFixed(2)}`
    },
    {
      field: 'payThis',
      headerName: 'Pick to Pay',
      flex: 0.5,
      renderCell: (params) => (
        <Checkbox
          onChange={(e) => handlePayThisCheck(params.row._id, e.target.checked, params.row.invoiceAmount)}
        />
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <IconButton edge="end" aria-label="details" onClick={() => navigate(`/payable/${params.row._id}`)}>
            <InfoIcon />
          </IconButton>
          <IconButton edge="end" aria-label="edit" onClick={() => navigate(`/payable/edit/${params.row._id}`)}>
            <EditIcon />
          </IconButton>
          <IconButton edge="end" aria-label="print" onClick={handlePrintPayable}>
            <PrintIcon />
          </IconButton>
        </>
      )
    }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" gutterBottom>Payables</Typography>
        <DynamicSearchFilter
          defaultSort="dueDate"
          onSearch={handleDynamicSearch}
          objectInterface={payableInterface}
        />
        <Tooltip title="New Payable">
          <IconButton color="primary" onClick={handleCreatePayable}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {viewPdf && (
        <Modal
          open={viewPdf}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
            <IconButton
              onClick={handleCloseModal}
              sx={{ position: 'absolute', right: 0, top: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
            >
              <CloseIcon />
            </IconButton>
            <PDFViewer width={'100%'} height={'100%'}>
              <CheckPDF report={checkData} />
            </PDFViewer>
          </Paper>
        </Modal>
      )}
      {loading ? (
        <CircularProgress />
      ) : isLargeScreen ? (
        <DataGridPro
          rows={PayablesStore.paginatedPayables.results}
          columns={columns}
          pageSize={PayablesStore.paginatedPayables.pageSize}
          rowCount={PayablesStore.paginatedPayables.totalCount}
          loading={loading}
          getRowId={(row) => row._id}
          rowsPerPageOptions={[10, 50, 100]}
          slots={{
            footer: CustomFooterComponent,
          }}
          slotProps={{
            footer: {
              outstandingAmount: outstandingOwed,
              paymentTotal: totalToBePaid,
              handleSave: handleSave
            }
          }}
        />
      ) : (
        <List>
          {PayablesStore.paginatedPayables.results.map((payable) => (
            <ListItem key={payable._id}>
              <ListItemText primary={payable.vendor?.vendorName} secondary={`Invoice No: ${payable.invoiceNo}`} />
              <ListItemSecondaryAction>
                <Checkbox
                  checked={selectedInvoices.includes(payable._id)}
                  onChange={() => handleSelectInvoice(payable._id)}
                />
                <IconButton edge="end" aria-label="edit" onClick={() => navigate(`/payable/edit/${payable._id}`)}>
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      <TablePagination
        component="div"
        count={PayablesStore.paginatedPayables.totalCount}
        page={PayablesStore.paginatedPayables.currentPage > 0 ? PayablesStore.paginatedPayables.currentPage - 1 : 0}
        onPageChange={handlePageChange}
        rowsPerPage={PayablesStore.paginatedPayables.pageSize}
        rowsPerPageOptions={[10, 25, 50]}
      />

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default Payables;
