import React from 'react';
import { TextField, Grid, Typography } from '@mui/material';

const AddressEdit = ({
  address,
  onChange,
  label = 'Address',
  fieldNames = {
    name: 'businessName',
    street1: 'street',
    street2: 'line2',
    city: 'city',
    state: 'state',
    zip: 'postalCode',
    country: 'country'
  }
}) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange({ ...address, [name]: value });
  };

  return (
    <Grid container spacing={3} mb={1}>
      <Grid item xs={12}>
        <Typography variant="h6" mt={4} mb={1} gutterBottom>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Name"
          name={fieldNames.name}
          fullWidth
          value={address[fieldNames.name] || ''}
          onChange={handleChange}
          // margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Street Address Line 1"
          name={fieldNames.street1}
          fullWidth
          value={address[fieldNames.street1] || ''}
          onChange={handleChange}
          // margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Street Address Line 2"
          name={fieldNames.street2}
          fullWidth
          value={address[fieldNames.street2] || ''}
          onChange={handleChange}
          // margin="normal"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="City"
          name={fieldNames.city}
          fullWidth
          value={address[fieldNames.city] || ''}
          onChange={handleChange}
          // margin="normal"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="State / Province / Region"
          name={fieldNames.state}
          fullWidth
          value={address[fieldNames.state] || ''}
          onChange={handleChange}
          // margin="normal"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="ZIP / Postal Code"
          name={fieldNames.zip}
          fullWidth
          value={address[fieldNames.zip] || ''}
          onChange={handleChange}
          // margin="normal"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Country"
          name={fieldNames.country}
          fullWidth
          value={address[fieldNames.country] || ''}
          onChange={handleChange}
          // margin="normal"
        />
      </Grid>
    </Grid>
  );
};

export default AddressEdit;
