import React from 'react';
import { Typography, Paper, Box } from '@mui/material';

const LicensingPage = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Licensing Information
        </Typography>

        <Typography variant="body1" gutterBottom>
          This software is a custom solution developed and maintained by Kratesoft, LLC, and is licensed exclusively to the Buckeye Hone Company. 
        </Typography>

        <Typography variant="body1" gutterBottom>
          Kratesoft, LLC retains all rights to the software's source code, design, and intellectual property. It is protected under applicable intellectual property laws.
        </Typography>

        <Typography variant="body1" gutterBottom>
          The use of this software is governed by a Service Level Agreement (SLA) between Kratesoft, LLC and the Buckeye Hone Company. Please refer to the SLA for details on terms of use, support, maintenance, and updates.
        </Typography>

        <Typography variant="body1" gutterBottom>
          For any licensing inquiries or to request a copy of the SLA, please contact Kratesoft, LLC directly. 
        </Typography>
      </Paper>
    </Box>
  );
};

export default LicensingPage;