import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Typography, Paper, Box, Button, Snackbar, TextField, Grid, Modal, IconButton, useTheme
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import dayjs from 'dayjs';
import { PDFViewer } from '@react-pdf/renderer';
import ProductionListPDF from '../../../pdf/ProductionListPDF';
import CloseIcon from '@mui/icons-material/Close';
import CustomerSearch from '../../../components/search/CustomerSearch';
import MachineSearch from '../../../components/search/MachineSearch';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ListDetailPanelContent({ row: rowProp }) {

  const columns = [
    { field: 'soNumber', headerName: 'Order#' },
    { field: 'millNo', headerName: 'Mill No' },
    { field: 'pieces', headerName: 'Pieces' },
    { field: 'dueDate', headerName: 'DueDate', valueFormatter: ({ value }) => dayjs(value).format('MM/DD/YYYY') },
    { field: 'OAL', headerName: 'OAL', valueGetter: (params) => params.row.OAL?.$numberDecimal },
    { field: 'feet', headerName: 'Feet', valueGetter: (params) => params.row.feet?.$numberDecimal },
    { field: 'hours', headerName: 'Hours', valueGetter: (params) => params.row.hours?.$numberDecimal },
    { field: 'innerDiameter', headerName: 'ID', valueGetter: (params) => params.row.innerDiameter?.$numberDecimal },
    { field: 'innerDiameterUpper', headerName: 'ID Upper', valueGetter: (params) => params.row.innerDiameterUpper?.$numberDecimal },
    { field: 'innerDiameterLower', headerName: 'ID Lower', valueGetter: (params) => params.row.innerDiameterLower?.$numberDecimal },
    { field: 'outerDiameter', headerName: 'OD', valueGetter: (params) => params.row.outerDiameter?.$numberDecimal },
    { field: 'weight', headerName: 'Weight', valueGetter: (params) => params.row.weight?.$numberDecimal },
    { field: 'received', headerName: 'Received', valueFormatter: ({ value }) => dayjs(value).format('MM/DD/YYYY') },
  ]

  return (
    <Paper>
      <DataGridPro
        density='compact'
        rows={rowProp.jobs}
        columns={columns}
        sx={{ flex: 1, p: 1 }}
        hideFooter
        getRowId={(row) => row.soNumber}
        autosizeOptions={{
          columns: columns.map((column) => column.field),
          includeHeaders: true
        }}
      />
    </Paper>
  )
}

const ProductionListReport = observer(() => {
  const [report, setReport] = useState({
    results: {
      data: [],
      dailyData: {},
      monthlyTotals: {},
    },
    message: '',
    status: ''
  });

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [machineId, setMachineId] = useState('');
  const [serviceOrderNumber, setServiceOrderNumber] = useState('');
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');
  const [dueDateFrom, setDueDateFrom] = useState(null);
  const [dueDateTo, setDueDateTo] = useState(null);
  const [millNo, setMillNo] = useState('');
  const [promiseDateFrom, setPromiseDateFrom] = useState(null);
  const [promiseDateTo, setPromiseDateTo] = useState(null);
  const [enteredDateFrom, setEnteredDateFrom] = useState(null);
  const [enteredDateTo, setEnteredDateTo] = useState(null);

  const theme = useTheme();

  /**
   * Get the production list report
   * @param {*} startDate - YYYY-MM-DD
   * @param {*} endDate - YYYY-MM-DD
   */
  const fetchProductionListReport = async (params) => {
    setLoading(true);
    ReportsStore.fetchProductionListReport(params).then(() => {
      setReport(ReportsStore.detailedReports.productionList);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch production list report', severity: 'error' });
      setLoading(false);
    });

  }

  const generateListReport = () => {
    const dueStart = dueDateFrom ? dayjs(dueDateFrom).format('YYYY-MM-DD') : '';
    const dueEnd = dueDateTo ? dayjs(dueDateTo).format('YYYY-MM-DD') : '';
    const promiseStart = promiseDateFrom ? dayjs(promiseDateFrom).format('YYYY-MM-DD') : '';
    const promiseEnd = promiseDateTo ? dayjs(promiseDateTo).format('YYYY-MM-DD') : '';
    const enteredStart = enteredDateFrom ? dayjs(enteredDateFrom).format('YYYY-MM-DD') : '';
    const enteredEnd = enteredDateTo ? dayjs(enteredDateTo).format('YYYY-MM-DD') : '';

    const productionListParams = {
      customerId,
      SONum: serviceOrderNumber,
      dueDateFrom: dueStart,
      dueDateTo: dueEnd,
      PONum: purchaseOrderNumber,
      promiseDateFrom: promiseStart,
      promiseDateTo: promiseEnd,
      millJobNum: millNo,
      enteredDateFrom: enteredStart,
      enteredDateTo: enteredEnd,
      machineId
    }

    fetchProductionListReport(productionListParams);
  }

  const printRef = useRef();

  const handleClose = () => {
    setViewPdf(false);
  }

  const columns = [
    { field: 'machineDetails.name', headerName: 'Machine Name', flex: 2, valueGetter: (params) => `${params.row.machineDetails.name} / ${params.row.machineDetails.operators}` },
    { field: 'totalFeet', headerName: 'Total Feet', flex: 1, valueGetter: (params) => params.row.totalFeet?.$numberDecimal },
    { field: 'totalHours', headerName: 'Total Hours', flex: 1, valueGetter: (params) => params.row.totalHours?.$numberDecimal },
    { field: 'totalPieces', headerName: 'Total Pieces', flex: 1 },
    { field: 'totalWeight', headerName: 'Total Weight', flex: 1, valueGetter: (params) => params.row.totalWeight?.$numberDecimal },
  ]

  const getListDetailPanelContent = React.useCallback(
    ({ row }) => <ListDetailPanelContent row={row} />,
    [],
  );

  const getDetailPanelHeight = React.useCallback(
    () => 250,
    []
  )

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: 2 }}>
          <Typography variant="h4">Production List Report</Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3} padding={2}>
            {/* Customer Name Dropdown */}
            <Grid item xs={12} md={6}>
              <CustomerSearch
                sx={{ width: '100%' }}
                handleChange={(event) => setCustomerId(event ? event._id : '')}
                showAdd={false}
                marginTop={0}
                showInactive={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MachineSearch handleChange={(event) => setMachineId(event ? event._id : '')} marginTop={0} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  width: '100%',
                }}
              >
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible-1"
                  label="Service Order #"
                  multiline
                  onChange={(event) => setServiceOrderNumber(event.target.value)}
                  maxRows={4}
                />
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible-2"
                  label="Purchase Order #"
                  multiline
                  onChange={(event) => setPurchaseOrderNumber(event.target.value)}
                  maxRows={4}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <DatePicker
                  label="Due Date From"
                  value={dueDateFrom}
                  onChange={(newValue) => setDueDateFrom(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  label="Due Date To"
                  value={dueDateTo}
                  onChange={(newValue) => setDueDateTo(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  width: '100%',
                }}
              >
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible-1"
                  label="Mill/Job #"
                  multiline
                  onChange={(event) => setMillNo(event.target.value)}
                  maxRows={4}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <DatePicker
                  label="Promise Date From"
                  value={promiseDateFrom}
                  onChange={(newValue) => setPromiseDateFrom(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  label="Promise Date To"
                  value={promiseDateTo}
                  onChange={(newValue) => setPromiseDateTo(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <DatePicker
                  label="Entered Date From"
                  value={enteredDateFrom}
                  onChange={(newValue) => setEnteredDateFrom(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  label="Entered Date To"
                  value={enteredDateTo}
                  onChange={(newValue) => setEnteredDateTo(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
              <Button onClick={() => generateListReport()} sx={{ width: '200px', fontWeight: 'bold' }}>Generate Production List Report</Button>
              {report?.data?.length > 0 && (
                <Box>
                  <Button onClick={() => setViewPdf(true)} sx={{ width: '200px' }}>View as PDF</Button>
                </Box>
              )}
            </Box>
          </Grid>
        </LocalizationProvider>
      </Box>
      <Box ref={printRef} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 4 }}>
        {viewPdf ? (
          <Modal
            open={viewPdf}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >

            <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
              <IconButton
                onClick={handleClose}
                sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
              >
                <CloseIcon />
              </IconButton>
              <PDFViewer width={'100%'} height={'100%'}>
                <ProductionListPDF
                  data={report.data}
                  startDate={dayjs(dueDateFrom).format('MM/DD/YYYY')}
                  endDate={dayjs(dueDateTo).format('MM/DD/YYYY')}
                />
              </PDFViewer>
            </Paper>
          </Modal>
        ) : (

          <DataGridPro
            rows={report?.data || []}
            columns={columns}
            autoHeight
            loading={loading}
            getRowId={(row) => row._id}
            getDetailPanelContent={getListDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
          />
        )}

      </Box>
      {report?.result?.dailyData !== undefined && (
        <Box sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          m: 4,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'grey.300',
          borderRadius: 1,
          p: 1
        }}>
          <Typography variant="h5">Daily Data: ${typeof report.result.dailyData === 'number' ? report.result.dailyData.toFixed(2) : '0.00'}</Typography>
          <Typography variant="h5">Monthly Total: ${typeof report.result.monthlyTotals === 'number' ? report.result.monthlyTotals.toFixed(2) : '0.00'}</Typography>
        </Box>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default ProductionListReport;
