import { Grid, TextField, Autocomplete, CircularProgress } from "@mui/material";
import UsersStore from "../../stores/UsersStore";
import { debounce } from "lodash";
import { useState, useEffect } from "react";

/**
 * Component for searching for a user with autocomplete
 * MUST supply users array
 * @param {number} margin - margin for the component
 * @param {function} handleChange - function to handle change of user
 * @param {array} users - array of users
 * @param {string} value - value of the user
 * @returns  
 */

const UserSearch = ({ margin = 0, handleChange, label = "Search Users", initialUserId }) => {

    const [users, setUsers] = useState({
        results: [],
        totalCount: 0,
    });
    const [open, setOpen] = useState(false);
    const [initUser, setInitUser] = useState(null);
    const loading = open && users.totalCount === 0;

    // Search for users every 500ms with given search value
    const handleInputChange = debounce((event, value, reason) => {
        const numOptions = users.results.filter((user) =>
            user.username.toLowerCase().includes(value.toLowerCase())
        );
        if (value.length > 0 && numOptions.length < 10) {
            //Search for users with the search value
            getPaginatedUsers(1, 10, 'username', {}, value);
        }
    });

    const getPaginatedUsers = async (page, limit, sort, filters, search, searchBy) => {
        try {
            await UsersStore.fetchPaginatedUsers(page, limit, sort, filters, search, searchBy);
            setUsers(UsersStore.paginatedUsers);
        } catch (error) {
            console.error('Failed to fetch users:', error);
        }
    }

    useEffect(() => {

        if (initialUserId) {
            UsersStore.fetchUserById(initialUserId).then((user) => {
                setInitUser(user);
            })
        };

        let active = true;

        let page = 1;
        let pageSize = 10;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await getPaginatedUsers(
                page, 
                pageSize, 
                'username', 
            );
            if (active) {
                setUsers(UsersStore.paginatedUsers);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if(!open) {
            setUsers({
                results: [],
                totalCount: 0,
            });
        }
    }, [open]);

    return (
        <Grid container margin={margin} marginTop={margin + 3}>
            {users ? (
                <>
                    <Grid item xs={12} md={6}>

                        <Autocomplete
                            disablePortal
                            fullWidth
                            id="user"
                            getOptionLabel={(option) => option.username}
                            options={users.results}
                            onChange={(event, newValue) => {
                                handleChange(newValue);
                            }}
                            onInputChange={handleInputChange}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            loading={loading}
                            isOptionEqualToValue={(option, value) => {
                                return option._id === value._id;
                            }}
                            value={initUser}
                            renderInput={(params) =>
                                <TextField 
                                    {...params} 
                                    label={label}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            }
                        />
                    </Grid>
                </>
            ) : (
                <>
                    Loading Users...
                </>
            )}
        </Grid>

    )
}

export default UserSearch;