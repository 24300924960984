import React from 'react';
import { Navigate } from 'react-router-dom';
import authStore from '../stores/AuthStore';
import { observer } from 'mobx-react';

const ProtectedRoute = observer(({ children }) => {
  return authStore.isAuthenticated ? children : <Navigate to="/login" />;
});

export default ProtectedRoute;
