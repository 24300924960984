import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  Box, Typography, CircularProgress, Paper, Divider, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmployeeStore from '../../stores/EmployeeStore';
import DataContainer from '../../components/DataContainer';

const EmployeeDetails = () => {
  const { employeeId } = useParams();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    navigate(`/employee/edit/${employeeId}`);
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    // Perform delete action here
    setDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const fetchedEmployee = await EmployeeStore.fetchEmployeeById(employeeId);
        setEmployee(fetchedEmployee);
      } catch (error) {
        console.error('Error fetching Employee:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployee();
  }, [employeeId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!employee) {
    return (
      <Box sx={{ m: 4, textAlign: 'center' }}>
        <Typography variant="h5">Employee not found</Typography>
        <Link to="/employees" style={{ textDecoration: 'none' }}>
          <Typography variant="body1" color="primary">
            Go back to Employee List
          </Typography>
        </Link>
      </Box>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 4, mx: 'auto', my: 2, maxWidth: '100%' }}>
      <Box sx={{display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ flexGrow: 1 }}>Employee Details</Typography>
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Box>
      <Box sx={{ m: 3 }}>
        <Typography variant="h6" fontWeight="bold">Employee Information</Typography>

        <Divider sx={{ margin: '24px 0 24px 0' }} />

        <Typography variant="h6" fontWeight="bold">Identification</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
          <DataContainer label="Name" value={`${employee.firstName} ${employee.lastName}`} />
          <DataContainer label="Name ID" value={employee.nameID} />
          <DataContainer label="Email" value={employee.email} />
          <DataContainer label="Phone" value={employee.phoneNumber} />
          <DataContainer label="Birth Date" value={employee.birthDate} />
          <DataContainer label="Employee ID" value={employee.employeeId} />
          <DataContainer label="Social Security Number" value={employee.socialSecurityNumber} />
        </Box>

        <Divider sx={{ margin: '24px 0 24px 0' }} />

        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
          <DataContainer label="Email" value={employee.email} />
          <DataContainer label="Phone" value={employee.phoneNumber} />
          <DataContainer label="Birth Date" value={employee.birthDate} />
          <DataContainer label="Employee ID" value={employee.employeeId} />
          <DataContainer label="Social Security Number" value={employee.socialSecurityNumber} />
        </Box>
       
        <Divider sx={{ margin: '24px 0 24px 0' }} />
        <Typography variant="h6" fontWeight="bold">Pay Information</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
          <DataContainer label="Current Hourly Rate" value={employee.currentHourlyRate} />
          <DataContainer label="Current Overtime Rate" value={employee.currentOTRate} />
          <DataContainer label="Current Salary" value={employee.currentSalary} />
          <DataContainer label="Hire Hourly Rate" value={employee.hireHourlyRate} />
          <DataContainer label="Hire Overtime Rate" value={employee.hireOTRate} />
          <DataContainer label="Hire Salary" value={employee.hireSalary} />
          <DataContainer label="Employment Type" value={employee.employmentType} />
        </Box>
      </Box>

      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Delete Employee</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this employee?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default EmployeeDetails;
