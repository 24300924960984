import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Paper,
} from "@mui/material";
import authStore from "../stores/AuthStore";
import { styled, keyframes } from "@mui/material/styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../assets/bhlogo.png";

const fadeInBlur = keyframes`
  0% {
    filter: blur(5px);
    opacity: 0;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
`;

const Logo = styled(LazyLoadImage)({
  display: "block",
  width: "auto",
  height: "150px",
  margin: "1rem auto",
  animation: `${fadeInBlur} 1s ease-in`,
});

const StyledPaper = styled(Paper)({
  padding: "3rem",
  maxWidth: "500px",
  margin: "2rem auto",
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
});

const LoginPage = observer(() => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await authStore.login();
      setSeverity("success");
      setSnackbarMessage("Login successful");
      navigate("/");
    } catch (error) {
      setSeverity("error");
      setSnackbarMessage(error.message || "Login failed");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Logo src={logo} alt="Logo" />
      <StyledPaper
        elevation={10}
        style={{ width: "600px", maxWidth: "80%", height: "400px" }}
      >
        <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
          Login
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
        >
          <TextField
            label="Username"
            variant="outlined"
            value={authStore.username}
            onChange={(e) => authStore.setUsername(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleLogin()}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            value={authStore.password}
            onChange={(e) => authStore.setPassword(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleLogin()}
          />
          <Button variant="contained" color="primary" onClick={handleLogin}>
            Login
          </Button>
        </Box>

        <Snackbar
          open={openSnackbar}
          autoHideD
          uration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </StyledPaper>
    </Box>
  );
});

export default LoginPage;
