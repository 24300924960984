import React from 'react';
import Typography from '@mui/material/Typography';

const PrivacyPage = () => {
  return (
    <div>
      <Typography variant="h4" component="h1" gutterBottom>
        Privacy Policy
      </Typography>

      <Typography variant="body1" gutterBottom>
        Your privacy is important to us. This policy outlines the information we collect and how we use it.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Information We Collect
      </Typography>
      <Typography variant="body1" gutterBottom>
        We collect personal information necessary for the operation of our HR platform. This may include:
        <ul>
          <li>Name</li>
          <li>Contact information (email, address, etc.)</li>
          <li>Employment data</li> 
          </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        How We Use Your Information
      </Typography>
      <Typography variant="body1" gutterBottom>
        Your information is used exclusively for:
        <ul>
          <li>Managing HR processes</li>
          <li>Record keeping</li>
          </ul>
        We will not share your information with third parties without your explicit consent.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1" gutterBottom>
        For questions or concerns about our privacy practices, please contact us at [insert company email].
      </Typography>
    </div>
  );
};

export default PrivacyPage;