import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CustomerStore from "../../stores/CustomerStore";
import { Box, Typography, CircularProgress, Grid, TextField, Select, FormControl, InputLabel, MenuItem, Button, Divider, Snackbar, Alert } from "@mui/material";
import AddressEdit from "../../components/AddressEdit";
import { DataGridPro } from "@mui/x-data-grid-pro";
import authStore from "../../stores/AuthStore";

const emptyState = {
  shipToId: '',
  shippingPhone: '',
  attention: '',
  shipVia: '',
  shippingName: '',
  address: {
    name: '',
    street: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
  },
  shipComments: '',
}

const ShippingAddress = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [newAddress, setNewAddress] = useState(true);
  const [address, setAddress] = useState(emptyState);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    setLoading(true);
    const fetchShippingAddresses = async () => {
      try {
        if (!CustomerStore.customer) {
          await CustomerStore.fetchCustomerById(customerId);
        }
        setCustomer(CustomerStore.customer);
        await CustomerStore.getCustomerShippingAddresses(customerId);
        setShippingAddresses(CustomerStore.shippingAddresses);
      } catch (error) {
        console.error("Failed to fetch shipping addresses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchShippingAddresses();
  }, [customerId])

  const selectAddress = (selectedAddress) => {
    const addr = {
      ...selectedAddress,
      address: {
        ...selectedAddress.address,
        name: selectedAddress.address.name || selectedAddress.address.businessName
      }
    }
    setAddress(addr);
    setNewAddress(false);
  };

  const handleDeleteAddress = async (addressId) => {
    try {
      await CustomerStore.deleteCustomerShippingAddress(customerId, addressId);
      setShippingAddresses(shippingAddresses.filter(address => address._id !== addressId));
      setAddress(emptyState);
      setNewAddress(true);
    } catch (error) {
      console.error("Failed to delete shipping address:", error);
    }
  };

  const handleInputChange = (event) => {
    setAddress({ ...address, [event.target.name]: event.target.value });
  };

  const handleShipChange = (event) => {
    setAddress({ ...address, shipVia: event.target.value });
  }

  const handleAddressChange = (shipAddr) => {
    setAddress({ ...address, address: shipAddr, shippingName: shipAddr.name });
  }

  const handleSubmit = async () => {
    const validateAddress = (address) => {
      if (!address.shipToId) throw new Error("Ship To ID is required");
      if (!address.shippingName) throw new Error("Shipping Address Name is required");
      if (!address.address.street) throw new Error("Shipping Address Street is required");
      if (!address.address.city) throw new Error("Shipping Address City is required");
      if (!address.address.state) throw new Error("Shipping Address State is required");
      if (!address.address.postalCode) throw new Error("Shipping Address ZIP is required");
    };

    const removeDuplicatesById = (arr) => {
      const seen = new Set();
      return arr.filter(item => {
        if (seen.has(item._id)) return false;
        seen.add(item._id);
        return true;
      });
    };

    try {
      validateAddress(address);
      if (newAddress) {
        const reqAddress = {
          ...address,
          customer: customerId,
          updatedBy: authStore.userId,
          createdBy: authStore.userId,
          shipAddrID: `${customerId}-${address.shipToId}`
        };
        const newAddr = await CustomerStore.createCustomerShippingAddress(customerId, reqAddress);
        setShippingAddresses(removeDuplicatesById([...shippingAddresses, newAddr]));
        setAddress(emptyState);
      } else {
        const reqAddress = {
          ...address,
          customer: customerId,
          updatedBy: authStore.userId
        };
        const addrId = reqAddress._id;
        const newAddr = await CustomerStore.updateCustomerShippingAddress(customerId, addrId, reqAddress);
        const index = shippingAddresses.findIndex(a => a._id === newAddr._id);
        if (index > -1) {
          shippingAddresses[index] = newAddr;
          setShippingAddresses(removeDuplicatesById(shippingAddresses));
        }
        setAddress(emptyState);
        setNewAddress(true);
      }
    } catch (error) {
      const message = /duplicate key/.test(error.response?.data?.message)
        ? `Error adding address: Duplicate Shipping ID ${address.shipToId}`
        : `Error adding address: ${error.message}`;
      setSnackbar({ open: true, severity: 'error', message });
    }
  };

  const columns = [
    { field: 'shipToId', headerName: 'Code', flex: 1 },
    { field: 'shippingName', headerName: 'Shipping Name', flex: 1 },
    { field: 'attention', headerName: 'Attention', flex: 1 },
    {
      field: 'actions', headerName: 'Actions', flex: 1,
      renderCell: (params) =>
        <>
          <Button onClick={() => selectAddress(params.row)}>Edit</Button>
          <Button onClick={() => handleDeleteAddress(params.row._id)}>Delete</Button>
        </>
    }
  ];

  return (
    <Box sx={{ m: 4 }}>
      <Grid container alignItems='center' justifyContent='space-between'>
        <Grid item>
          <Typography variant="h4" sx={{ mb: 2 }}>Shipping Addresses</Typography>
        </Grid>
        <Grid item>
          <Button sx={{ mr: 4 }} variant="outlined" onClick={() => navigate(`/customers`)}>Back</Button>
        </Grid>
      </Grid>

      {loading ? (
        <CircularProgress />
      ) : (
        <Box>
          <Box sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Customer ID: {customer?.companyId}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Customer Name: {customer?.companyName}</Typography>
              </Grid>

              <Divider sx={{ width: '100%', mt: 2, mb: -2 }} />

              <Grid item xs={12} md={6}>
                <Typography variant="h6" mt={4} mb={2} gutterBottom>Shipping Information</Typography>
                <TextField
                  label="Ship to ID"
                  name="shipToId"
                  fullWidth
                  value={address.shipToId}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  label="Shipping Phone"
                  name="shippingPhone"
                  fullWidth
                  value={address.shippingPhone}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  label="Attention"
                  name="attention"
                  fullWidth
                  value={address.attention}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="shipViaLabel">Ship Via</InputLabel>
                  <Select
                    labelId="shipViaLabel"
                    id="shipVia"
                    name="shipVia"
                    value={address.shipVia || ''}
                    label="Ship Via"
                    onChange={handleShipChange}
                  >
                    <MenuItem value={0}>None</MenuItem>
                    <MenuItem value={1}>UPS</MenuItem>
                    <MenuItem value={2}>FedEx</MenuItem>
                    <MenuItem value={3}>USPS</MenuItem>
                    <MenuItem value={4}>Priority</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <AddressEdit
                  label="Shipping Address"
                  fieldNames={{
                    name: 'name',
                    street1: 'street',
                    street2: 'line2',
                    city: 'city',
                    state: 'state',
                    zip: 'postalCode',
                    country: 'country'
                  }}
                  address={address.address}
                  onChange={handleAddressChange}
                />
              </Grid>

              <Grid item xs={12} md={9}>
                <TextField
                  label="Shipping Comments"
                  name="shipComments"
                  fullWidth
                  value={address.shipComments}
                  onChange={handleInputChange}
                  margin="normal"
                  multiline
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Button variant="contained" sx={{ mt: 3, width: '90%' }} onClick={handleSubmit}>
                  {newAddress ? 'Add' : 'Update'}
                </Button>
              </Grid>

              <Divider sx={{ width: '100%', mt: 1, mb: 1 }} />
              <Grid item xs={12}>
                <DataGridPro
                  columns={columns}
                  rows={shippingAddresses}
                  autoHeight
                  getRowId={(row) => row._id}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ShippingAddress;
