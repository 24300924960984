import React from 'react';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@mui/material';

const DataContainer = ({ label, value, icon, justifyContent, ...props }) => {
    const theme = useTheme();

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: justifyContent || 'start' }}>
            {icon && <img src={icon} alt={label} style={{ marginRight: theme.spacing(1) }} />}
            <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: theme.spacing(2) }}>
                {label}
            </Typography>
            <Typography variant="body1" style={{ color: props.onClick ? theme.palette.primary.main : undefined }}>
                {value}
            </Typography>
        </div>
    );
};

DataContainer.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    justifyContent: PropTypes.string,
    icon: PropTypes.string,
};

export default DataContainer;