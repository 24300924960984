import React, { useState } from 'react';
import { Box, TextField, LinearProgress, Typography, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';

function PasswordStrengthChecker() {
  const [password, setPassword] = useState("");
  const [strength, setStrength] = useState("");
  const [progress, setProgress] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const calculateStrength = (passwordValue) => {
    const strengthChecks = {
      length: passwordValue.length >= 8,
      hasUpperCase: /[A-Z]+/.test(passwordValue),
      hasLowerCase: /[a-z]+/.test(passwordValue),
      hasDigit: /[0-9]+/.test(passwordValue),
      hasSpecialChar: /[^A-Za-z0-9]+/.test(passwordValue),
    };

    let score = Object.values(strengthChecks).filter(Boolean).length;
    let strengthLevel = score === 5 ? "Strong" : score >= 3 ? "Medium" : "Weak";

    setPassword(passwordValue);
    setStrength(strengthLevel);
    setProgress((score / 5) * 100);
  };

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getStrengthColor = () => {
    if (strength === "Strong") return "success";
    if (strength === "Medium") return "warning";
    return "error";
  };

  const renderStrengthCheck = (condition) => condition ? 
    <CheckCircle color="success" /> : <Cancel color="error" />;

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', my: 2 }}>
      <TextField
        label="Password"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => calculateStrength(e.target.value)}
        fullWidth
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleToggleShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <LinearProgress variant="determinate" value={progress} color={getStrengthColor()} sx={{ mt: 2 }} />
      {password && (
        <Box>
          <Typography color={getStrengthColor()} sx={{ mt: 1 }}>
            Your password is {strength}
          </Typography>
          <List dense>
            <ListItem>
              <ListItemIcon>{renderStrengthCheck(password.length >= 8)}</ListItemIcon>
              <ListItemText primary="At least 8 characters" />
            </ListItem>
            <ListItem>
              <ListItemIcon>{renderStrengthCheck(/[A-Z]+/.test(password))}</ListItemIcon>
              <ListItemText primary="Contains uppercase letters" />
            </ListItem>
            <ListItem>
              <ListItemIcon>{renderStrengthCheck(/[a-z]+/.test(password))}</ListItemIcon>
              <ListItemText primary="Contains lowercase letters" />
            </ListItem>
            <ListItem>
              <ListItemIcon>{renderStrengthCheck(/[0-9]+/.test(password))}</ListItemIcon>
              <ListItemText primary="Contains numbers" />
            </ListItem>
            <ListItem>
              <ListItemIcon>{renderStrengthCheck(/[^A-Za-z0-9]+/.test(password))}</ListItemIcon>
              <ListItemText primary="Contains special characters" />
            </ListItem>
          </List>
        </Box>
      )}
    </Box>
  );
}

export default PasswordStrengthChecker;
