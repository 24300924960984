import { Document, Page, Text, View } from '@react-pdf/renderer';

const styles = {
  page: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleSection: {
    width: '100%',
    border: 1
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 2,
    fontWeight: 'bold'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottomWidth: 1,
  },
  cell: {
    fontSize: 12,
    padding: 2,
    borderRightWidth: 1,
    height: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    fontWeight: 'bold',
  },
  instructionCell: {
    fontSize: 10,
    padding: 2,
    borderRightWidth: 1,
    height: 64,
    justifyContent: 'flex-end',
    fontWeight: 'bold',
  },
  headerCell: {
    flex: 1,
    fontSize: 12,
    padding: 2,
    borderRightWidth: 1,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  first: {
    borderLeftWidth: 1,
  },
  tableBorder: {
    borderTopWidth: 1,
    borderTopColor: '#000',
  },
};


const JobSheetsPDF = ({ orderInfo }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleSection}>
          <Text style={styles.title}>Buckeye Hone Job Sheet</Text>
        </View>
        {/* Top Row */}
        <View style={styles.row}>
          <View style={[styles.cell, styles.first, { width: '16%', textAlign: 'right' }]}>
            <Text>Employee:</Text>
          </View>
          <View style={[styles.cell, { width: '21%' }]}>
            {/* Left Intentionally Blank */}
          </View>
          <View style={[styles.cell, { width: '14%', textAlign: 'right' }]}>
            <Text>Mach. #:</Text>
          </View>
          <View style={[styles.cell, { width: '7%' }]}>
            {/* Left Intentionally Blank */}
          </View>
          <View style={[styles.cell, { width: '7%', textAlign: 'right' }]}>
            <Text>SO#:</Text>
          </View>
          <View style={[styles.cell, { width: '14%' }]}>
            <Text>{orderInfo.salesOrderNumber}</Text>
          </View>
          <View style={[styles.cell, { width: '7%', textAlign: 'right' }]}>
            <Text>Mill:</Text>
          </View>
          <View style={[styles.cell, { width: '14%' }]}>
            <Text>{orderInfo.millJobNumber}</Text>
          </View>
        </View>
        {/* Second Row */}
        <View style={styles.row}>
          <View style={[styles.cell, styles.first, { width: '16%', textAlign: 'right' }]}>
            <Text>OD:</Text>
          </View>
          <View style={[styles.cell, { width: '7%' }]}>
            <Text>{orderInfo.tubeOuterDiameter?.toFixed(3) || 'N/A'}</Text>
          </View>
          <View style={[styles.cell, { width: '7%', textAlign: 'right' }]}>
            <Text>ID:</Text>
          </View>
          <View style={[styles.cell, { width: '7%' }]}>
            <Text>{orderInfo.actualInnerDiameter?.toFixed(3) || 'N/A'}</Text>
          </View>
          <View style={[styles.cell, { width: '14%', textAling: 'right' }]}>
            <Text>Bundles:</Text>
          </View>
          <View style={[styles.cell, { width: '7%' }]}>
            <Text>{orderInfo.bundleCount || 0}</Text>
          </View>
          <View style={[styles.cell, { width: '14%', textAlign: 'right' }]}>
            <Text>Pieces:</Text>
          </View>
          <View style={[styles.cell, { width: '7%' }]}>
            <Text>{orderInfo.totalPieces?.actual || 0}</Text>
          </View>
          <View style={[styles.cell, { width: '7%', textAlign: 'right' }]}>
            <Text>Ftg:</Text>
          </View>
          <View style={[styles.cell, { width: '14%' }]}>
            <Text>{orderInfo.totalFeet?.actual || 0}</Text>
          </View>
        </View>
        {/* Third Row */}
        <View style={styles.row}>
          <View style={[styles.cell, styles.first, { width: '16%', textAlign: 'right' }]}>
            <Text>Hone To Min:</Text>
          </View>
          <View style={[styles.cell, { width: '7%' }]}>
            <Text>{orderInfo.honeInnerDiameter?.lowerLimit.toFixed(3) || 'N/A'}</Text>
          </View>
          <View style={[styles.cell, { width: '14%', textAlign: 'right' }]}>
            <Text>Hone To Max:</Text>
          </View>
          <View style={[styles.cell, { width: '7%' }]}>
            <Text>{orderInfo.honeInnerDiameter?.upperLimit.toFixed(3) || 'N/A'}</Text>
          </View>
          <View style={[styles.cell, { width: '14%', textAlign: 'right' }]}>
            <Text>Rq:</Text>
          </View>
          <View style={[styles.cell, { width: '7%' }]}>
            <Text>{orderInfo.SurfaceFinish?.Value || 'N/A'}</Text>
          </View>
          <View style={[styles.cell, { width: '14%', textAlign: 'right' }]}>
            <Text>Total Hours:</Text>
          </View>
          <View style={[styles.cell, { width: '21%' }]}>
            <Text>{orderInfo.totalHours || 0}</Text>
          </View>
        </View>
        {/* Gap */}
        <View style={[styles.row, { borderLeftWidth: 1, borderRightWidth: 1, height: 16 }]}></View>
        {/* Table Header */}
        <View style={styles.row}>
          <View style={[styles.headerCell, styles.first]}>
            <Text>Date</Text>
          </View>
          <View style={styles.headerCell}>
            <Text>Pieces</Text>
          </View>
          <View style={styles.headerCell}>
            <Text>OAL</Text>
          </View>
          <View style={styles.headerCell}>
            <Text>Hours</Text>
          </View>
          <View style={styles.headerCell}>
            <Text>Rate</Text>
          </View>
          <View style={styles.headerCell}>
            <Text>Stones</Text>
          </View>
          <View style={styles.headerCell}>
            <Text>Inspector</Text>
          </View>
        </View>
        {/* Table - 13 rows */}
        {Array(13).fill(0).map((_, index) => (
          <View style={styles.row} key={index}>
            <View style={[styles.cell, styles.first, { flex: 1 }]}>
              {/* Blank */}
            </View>
            {Array(6).fill(0).map((_, index) => (
              <View style={[styles.cell, { flex: 1 }]} key={index}>
                {/* Blank */}
              </View>
            ))}
          </View>
        ))}
        {/* Bottom Rows */}
        <View style={styles.row}>
          <View style={[styles.cell, styles.first, { flex: 1.5, textAlign: 'right' }]}>
            <Text>Stock Removal:</Text>
          </View>
          <View style={[styles.cell, { flex: 2 }]}>
            <Text>{orderInfo.stockRemoval !== undefined ? orderInfo.stockRemoval.toFixed(3) : 0}</Text>
          </View>
          <View style={[styles.cell, { flex: 1.5, textAlign: 'right' }]}>
            <Text>Banding Clips:</Text>
          </View>
          <View style={[styles.cell, { flex: 2 }]}>
            {orderInfo.bandingClips || ''}
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.cell, styles.first, { flex: 1.5, textAlign: 'right' }]}>
            <Text>Fixture Clamps:</Text>
          </View>
          <View style={[styles.cell, { flex: 2 }]}>
            <Text>{orderInfo.fixtureClamps || ''}</Text>
          </View>
          <View style={[styles.cell, { flex: 1.5, textAlign: 'right' }]}>
            <Text>Feet Per Hour:</Text>
          </View>
          <View style={[styles.cell, { flex: 2 }]}>
            <Text>{orderInfo.feetPerHour || ''}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.cell, styles.first, { flex: 1.465, textAlign: 'right', height: 64 }]}>
            <Text>Special Instruct.:</Text>
          </View>
          <View style={[styles.instructionCell, { flex: 5.5 }]}>
            <Text>{orderInfo.specialInstructions || ''}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.cell, styles.first, { flex: 1.465, textAlign: 'right' }]}>
            <Text>Bundle Nos:</Text>
          </View>
          <View style={[styles.cell, { flex: 5.5 }]}>
            <Text>{orderInfo.bundles.join(", ") || ''}</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
};

export default JobSheetsPDF;