import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Snackbar, Alert, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import OrdersPage from './order/Orders';
import ProductionPage from './order/Production';
import MachineAssignmentPage from './order/MachineAssignment';
import MachineOperatorsPage from './order/MachineOperators';
import { observer } from 'mobx-react';

const OrderTabs = observer(() => {
  const [tab, setTab] = useState('1');

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    // Get previous tab from local storage
    const previousTab = localStorage.getItem('orderTab');
    if (previousTab) {
      setTab(previousTab);
      // Clear local storage
      localStorage.removeItem('orderTab');
    }
  });

  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleTabChange} aria-label="Order Tabs">
          <Tab label="Orders" value="1" />
          <Tab label="Production" value="2" />
          <Tab label="Machine Assignment" value="3" />
          <Tab label="Machine Operators" value="4" />
        </TabList>
      </Box>
      <TabPanel value="1">
        <OrdersPage />
      </TabPanel>
      <TabPanel value="2">
        <ProductionPage />
      </TabPanel>
      <TabPanel value="3">
        <MachineAssignmentPage />
      </TabPanel>
      <TabPanel value="4">
        <MachineOperatorsPage />
      </TabPanel>
    </TabContext>
  );
});

export default OrderTabs;