import { useEffect, useState } from "react";
import { Box, Typography, Paper, Snackbar, Alert, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import HoursTab from './payroll/HoursTab';
import CalculationTab from './payroll/CalculationTab';

const PayrollTabs = () => {
  const [tab, setTab] = useState('1');

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleTabChange} aria-label="Payroll Tabs">
          <Tab label="Hours" value="1" />
          <Tab label="Calculation" value="2" />
        </TabList>
      </Box>
      <TabPanel value="1">
        <HoursTab />
      </TabPanel>
      <TabPanel value="2">
        <CalculationTab />
      </TabPanel>
    </TabContext>
  )
};

export default PayrollTabs;