import { Document, Page, Text, View } from '@react-pdf/renderer';

const styles = {
  page: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleSection: {
    marginBottom: 20,
    width: '80%'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    marginBottom: 10,
    width: '100%'
  },
  columnHeader: {
    width: '5%',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 8,
  },
  cell: {
    width: '5%',
    fontSize: 7,
    textAlign: 'left',
  },
  lastColumn: {
    width: '9%',
    borderRightWidth: 0,
  },
  tableBorder: {
    borderTopWidth: 1,
    borderTopColor: '#000',
  },
  totalsHeadersLeft: {
    width: '65%',
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: 7,
    paddingRight: 2
  },
  totalsValue: {
    width: '34%',
    textAlign: 'left',
    fontSize: 7,
  },
  totalsHeadersRight: {
    width: '36%',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 7,
  },
  customerHeader: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    margin: 8
  },
  dateColumn: {
    width: '7%',
  }
};

const ClosedMasterPDF = ({ report, startDate, endDate }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleSection}>
          <Text key={'title'} style={styles.title}>Buckeye Hone Closed Masters List - All Due Dates from {startDate || 'N/A'} to {endDate || 'N/A'}</Text>
        </View>
        {/* Table */}
        <View>
          {report.data.map((customer, index) => (
            <View key={`${customer.name} - ${index}`}>
              <Text style={styles.customerHeader}>{customer.name}</Text>
              <View style={styles.tableContainer}>
                <Text style={styles.columnHeader}>SO #</Text>
                <Text style={[styles.columnHeader, styles.dateColumn]}>Customer</Text>
                <Text style={[styles.columnHeader, styles.dateColumn]}>Mill No</Text>
                <Text style={styles.columnHeader}>PCS</Text>
                <Text style={styles.columnHeader}>Tube OD</Text>
                <Text style={styles.columnHeader}>Hone to Max</Text>
                <Text style={[styles.columnHeader, styles.dateColumn]}>Feet</Text>
                <Text style={[styles.columnHeader, styles.dateColumn]}>Due Date</Text>
                <Text style={[styles.columnHeader, styles.dateColumn]}>Date Recd</Text>
                <Text style={[styles.columnHeader, styles.dateColumn]}>Finish Date</Text>
                <Text style={styles.columnHeader}>Price</Text>
                <Text style={[styles.columnHeader, styles.dateColumn]}>Value</Text>
                <Text style={styles.columnHeader}>Ship ID</Text>
                <Text style={[styles.columnHeader, styles.dateColumn]}>Date Entered</Text>
                <Text style={[styles.columnHeader, styles.dateColumn]}>Ship Date</Text>
                <Text style={[styles.columnHeader, styles.lastColumn]}>Canc?</Text>
              </View>
              {customer.orders.map((order, index) => (
                <View key={`${order.soNumber} - ${index}`} style={styles.tableContainer}>
                  <Text style={styles.cell}>{order.soNumber}</Text>
                  <Text style={[styles.cell, styles.dateColumn]}>{order.customer}</Text>
                  <Text style={[styles.cell, styles.dateColumn]}>{order.millNo}</Text>
                  <Text style={styles.cell}>{order.PCS}</Text>
                  <Text style={styles.cell}>{order.tubeOD}</Text>
                  <Text style={styles.cell}>{order.honeToMax}</Text>
                  <Text style={[styles.cell, styles.dateColumn]}>{order.feet}</Text>
                  <Text style={[styles.cell, styles.dateColumn]}>{order.dueDate}</Text>
                  <Text style={[styles.cell, styles.dateColumn]}>{order.dateRecd}</Text>
                  <Text style={[styles.cell, styles.dateColumn]}>{order.finishDate}</Text>
                  <Text style={styles.cell}>${order.costPerPiece.toFixed(2)}</Text>
                  <Text style={[styles.cell, styles.dateColumn]}>${order.value.toFixed(2)}</Text>
                  <Text style={styles.cell}>{order.shipID}</Text>
                  <Text style={[styles.cell, styles.dateColumn]}>{order.dateEntered}</Text>
                  <Text style={[styles.cell, styles.dateColumn]}>{order.shipDate}</Text>
                  <Text style={[styles.cell, styles.lastColumn]}>{order.canc}</Text>
                </View>
              ))}
              <View style={styles.tableContainer}>
                <Text style={styles.totalsHeadersLeft}>{customer.name} Total: </Text>
                <Text style={styles.totalsValue}>${customer.totalValue.toFixed(2)}</Text>
              </View>
            </View>

          ))}
          <View style={styles.tableContainer}>
            <Text style={styles.totalsHeadersLeft}>Grand Total: </Text>
            <Text style={styles.totalsValue}>${report.grandTotal.toFixed(2)}</Text>
          </View>
          <View style={styles.tableContainer}>
            {/* Intentionally left blank */}
          </View>
        </View>
        <View style={styles.tableBorder} />
      </Page>
    </Document>
  )

}

export default ClosedMasterPDF;
