import React, { useState } from 'react';
import {
  Box, Select, MenuItem, Paper, FormControl, InputLabel
} from '@mui/material';
import EmployeeTimesheetReport from './EmployeeTimesheetReport';
import PayrollReport from './PayrollReport';
import AttendanceReport from './AttendanceReport';
import EmployeeScrapReport from './EmployeeScrapReport';

const Employees = () => {
  const [report, setReport] = useState(0);

  const handleReportChange = (event) => {
    setReport(event.target.value);
  };

  const renderReport = () => {
    switch (report) {
      case 0:
        return <EmployeeTimesheetReport />;
      case 1:
        return <PayrollReport />;
      case 2:
        return <AttendanceReport />;
      case 3:
        return <EmployeeScrapReport />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box sx={{ margin: 2, marginTop: 4 }}>
        <FormControl fullWidth>
          <InputLabel id="employee-report-select-label">Select Report</InputLabel>
          <Select
            labelId="employee-report-select-label"
            value={report}
            onChange={handleReportChange}
          >
            <MenuItem value={0}>Timesheets</MenuItem>
            <MenuItem value={1}>Payroll</MenuItem>
            <MenuItem value={2}>Attendance</MenuItem>
            <MenuItem value={3}>Scrap</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {renderReport()}
    </Box>
  );
};

export default Employees;
