import { makeAutoObservable } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class UserPreferencesStore {
  username = '';
  displayName = '';
  email = '';
  roles = [];
  fullName = '';
  address = {};
  phoneNumber = '';
  profileImage = '';
  isActive = true;

  selectedLanguage = 'English';
  selectedTimeZone = 'UTC';
  selectedDateFormat = 'MM/DD/YYYY';

  constructor() {
    makeAutoObservable(this);
    this.loadUserDetails();
  }

  async loadUserDetails() {
    const userId = localStorage.getItem('userId');
    if (userId) {
      try {
        const response = await apiHandler.get(`/users/${userId}`);
        const user = response.data;
        this.setUserDetails(user);
      } catch (error) {
        console.error('Error loading user details:', error);
      }
    }
  }

  setUserDetails(user) {
    this.username = user.username;
    this.displayName = user.displayName;
    this.email = user.email;
    this.roles = user.roles;
    this.fullName = user.fullName;
    this.address = user.address;
    this.phoneNumber = user.phoneNumber;
    this.profileImage = user.profileImage;
    this.isActive = user.isActive;

    this.selectedLanguage = user.settings.language;
    this.selectedTimeZone = user.settings.timeZone;
    this.selectedDateFormat = user.settings.dateFormat;
  }

  async updateUserDetails(updatedDetails) {
    const userId = localStorage.getItem('userId');
    if (userId) {
      try {
        await apiHandler.put(`/users/${userId}`, updatedDetails);
        this.setUserDetails(updatedDetails);
      } catch (error) {
        console.error('Error updating user details:', error);
      }
    }
  }

}

const userPreferencesStore = new UserPreferencesStore();

export default userPreferencesStore;
