import React, { useState } from 'react';
import {
  Box, Select, MenuItem, Paper, FormControl, InputLabel
} from '@mui/material';
import SalesReport from './SalesReport';
import MonthlySalesReport from './MonthlySalesReport';
import OrdersByMonthReport from './OrdersByMonthReport';
import SalesByMachineReport from './SalesByMachineReport';
import OrdersWrittenReport from './OrdersWrittenReport';
import SalesByCustomerReport from './SalesByCustomerReport';
import ReceivablesReport from './ReceivablesReport';

const Sales = () => {
  const [report, setReport] = useState(0);

  const handleReportChange = (event) => {
    setReport(event.target.value);
  };

  const renderReport = () => {
    switch (report) {
      case 0:
        return <SalesByCustomerReport />;
      case 1:
        return <MonthlySalesReport />;
      case 2:
        return <OrdersByMonthReport />;
      case 3: 
        return <SalesByMachineReport />;
      case 4: 
        return <OrdersWrittenReport />;
      case 5:
        return <ReceivablesReport />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box sx={{ margin: 2, marginTop: 4 }}>
        <FormControl fullWidth>
          <InputLabel id="sales-report-select-label">Select Report</InputLabel>
          <Select
            labelId="sales-report-select-label"
            value={report}
            onChange={handleReportChange}
          >
            <MenuItem value={0}>Sales By Customer</MenuItem>
            <MenuItem value={1}>Monthly Sales</MenuItem>
            <MenuItem value={2}>Orders By Month</MenuItem>
            <MenuItem value={3}>Sales By Machine</MenuItem>
            <MenuItem value={4}>Orders Written</MenuItem>
            <MenuItem value={5}>Receivables</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {renderReport()}
    </Box>
  );
};

export default Sales;
