import { useState, useEffect } from "react";
import { Box, Typography, Snackbar, Alert, TextField, Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PayrollStore from "../../stores/PayrollStore";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSearchParams } from "react-router-dom";

import EmployeeSearch from "../../components/search/EmployeeSearch";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE);

const HoursTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialEmployeeId = searchParams.get('id') || '';
  const [employee, setEmployee] = useState(initialEmployeeId);
  const [workDate, setWorkDate] = useState(new Date());
  const [hours, setHours] = useState(0);
  const [comments, setComments] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [payHours, setPayHours] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });

  const handleDateChange = (name, date) => {
    const validDate = date instanceof Date && !isNaN(date) ? date : null;
    setWorkDate(validDate);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    const payHour = {
      employee,
      workDate,
      hours,
      comments
    };

    if (!employee) {
      setSnackbar({ open: true, message: 'Please select an employee', severity: 'error' });
      return;
    } else if (!workDate) {
      setSnackbar({ open: true, message: 'Please select a date', severity: 'error' });
      return;
    } else if (!hours && hours !== 0) {
      setSnackbar({ open: true, message: 'Please enter hours', severity: 'error' });
      return;
    }

    try {
      await PayrollStore.createPayHour(payHour);
      setHours(0);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to record hours', severity: 'error' });
    } finally {
      await fetchEmployeePayHours(employee);
    }
  }

  const handleEmployeeChange = async (event) => {
    let newEmployeeId = '';
    if (event) {
      newEmployeeId = event._id;
      setEmployee(newEmployeeId);
      await fetchEmployeePayHours(newEmployeeId);
      setSearchParams({ id: newEmployeeId });
    } else {
      setEmployee('');
      setSearchParams({});
      setPayHours({ ...payHours, results: [], totalCount: 0 });
    }
  };

  const fetchEmployeePayHours = async (id) => {
    setIsLoading(true);
    try {
      const filter = {
        employee: id,
        workDate: {
          $gte: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1),
          $lt: new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)
        }
      };
      await PayrollStore.fetchPaginatedPayHours(1, 40, 'workDate', filter);
      setPayHours(PayrollStore.paginatedPayHours);
    } catch (error) {
      if (/No documents found./.test(error.response.data.message)) {
        setPayHours({ ...payHours, results: [], totalCount: 0 });
      } else {
        console.error('Failed to fetch pay hours:', error);
        setSnackbar({ open: true, message: 'Failed to fetch pay hours', severity: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleChangeMonth = (direction) => {
    if (direction === 'back') {
      setSelectedDate(new Date(selectedDate.setMonth(selectedDate.getMonth() - 1)));
    } else {
      setSelectedDate(new Date(selectedDate.setMonth(selectedDate.getMonth() + 1)));
    }
    fetchEmployeePayHours(employee);
  }

  const handleDeletePayHour = async (id) => {
    setIsLoading(true);
    try {
      await PayrollStore.deletePayHour(id);
      await fetchEmployeePayHours(employee);
      setSnackbar({ open: true, message: 'Pay hour deleted', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to delete pay hour', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    { field: 'workDate', headerName: 'Date', flex: 1, valueFormatter: (params) => new Date(params.value).toLocaleDateString() },
    { field: 'hours', headerName: 'Hours', flex: 1 },
    { field: 'comments', headerName: 'Comments', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: .5,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <IconButton variant="outlined" color="error" onClick={() => handleDeletePayHour(params.row._id)}><DeleteIcon /></IconButton>
    }
  ]

  const renderEmptyState = () => (
    <Box sx={{ textAlign: 'center', marginTop: 8 }}>
      <AddCircleOutlineIcon sx={{ fontSize: 32 }} />
      <Typography variant="h6" sx={{ mt: 2 }}>
        No Employee Selected
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        Select an employee to view and record hours.
      </Typography>
    </Box>
  );

  return (
    
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Typography variant="h4" gutterBottom>Hours</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: 2, gap: 2 }}>
          <EmployeeSearch handleChange={handleEmployeeChange} marginTop={1} />
          <DatePicker
            label="Date"
            value={workDate ? new Date(workDate) : null}
            sx={{ mt: 2, mb: 1, width: '100%' }}
            onChange={(date) => {
              handleDateChange('workDate', date)
            }}
          />
          <TextField fullWidth type="number" label="Hours" value={hours} margin="normal" onChange={(event) => setHours(event.target.value)} />
          <TextField fullWidth label="Comments" value={comments} margin="normal" onChange={(event) => setComments(event.target.value)} multiline />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ px: 6, mt: .5 }}
            startIcon={<AddIcon />}
          >
            Record
          </Button>
        </Box>
      </LocalizationProvider>
      {employee ? (
        <>
      {!employee ? (
        <Typography variant="subtitle1" mt={2} mb={2}>Please select an employee to view hours</Typography>
      ) : (
        <>
          <Typography variant="h6" mt={4} gutterBottom sx={{ textAlign: 'center' }}>
            {selectedDate.toLocaleString('default', { month: 'long', year: 'numeric' })}  Hours
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2, gap: 2 }}>
            <Button variant="outlined" onClick={() => handleChangeMonth('back')}>Previous Month</Button>
            <Button variant="outlined" onClick={() => handleChangeMonth('forward')}>Next Month</Button>
          </Box>
          {payHours.totalCount > 0 ? (
            <>
              <DataGridPro
                rows={payHours.results}
                columns={columns}
                pageSize={payHours.pageSize}
                rowCount={payHours.totalCount}
                loading={isLoading}
                getRowId={(row) => row._id}
              />
            </>
          ) : (
            <Typography variant="subtitle1" mt={2} mb={2}>No hours recorded for this employee</Typography>
          )}
        </>
      )}
      </>
      ) : 
        renderEmptyState()}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )

};

export default HoursTab;