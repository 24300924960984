import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ShopOrdersStore from '../../stores/ShopOrdersStore';
import OrderForm from './OrderForm';
import { Box, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';

const EditOrder = () => {
  const [order, setOrder] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
  const { orderId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    ShopOrdersStore.fetchShopOrderById(orderId)
      .then((shopOrder) => {
        const formatDecimal = (value, places) => (value != null ? parseFloat(value).toFixed(places) : '0.000');
        shopOrder.tubeOuterDiameter = formatDecimal(shopOrder.tubeOuterDiameter, 3);
        shopOrder.honeInnerDiameter = {
          ...shopOrder.honeInnerDiameter,
          lower: formatDecimal(shopOrder.honeInnerDiameter?.lower, 3),
          upper: formatDecimal(shopOrder.honeInnerDiameter?.upper, 3)
        };
        shopOrder.actualInnerDiameter = formatDecimal(shopOrder.actualInnerDiameter, 3);
        shopOrder.stockRemoval = formatDecimal(shopOrder.stockRemoval, 3);
        shopOrder.totalFeet = {
          ...shopOrder.totalFeet,
          theoretical: formatDecimal(shopOrder.totalFeet?.theoretical, 2),
          actual: formatDecimal(shopOrder.totalFeet?.actual, 2)
        };
        shopOrder.tubeLength = {
          ...shopOrder.tubeLength,
          theoretical: formatDecimal(shopOrder.tubeLength?.theoretical, 2),
          actual: formatDecimal(shopOrder.tubeLength?.actual, 2)
        };
        shopOrder.rate = formatDecimal(shopOrder.rate, 2);
        shopOrder.costPerFoot = formatDecimal(shopOrder.costPerFoot, 2);
        shopOrder.costPerPiece = formatDecimal(shopOrder.costPerPiece, 2);
        shopOrder.totalCharges = formatDecimal(shopOrder.totalCharges, 2);
        shopOrder.charges = formatDecimal(shopOrder.charges, 2);
        shopOrder.total = formatDecimal(shopOrder.total, 2);
        setOrder(shopOrder);
      })
      .catch(() => {
        setSnackbar({ open: true, message: 'Failed to fetch order', severity: 'error' });
      });
  }, [orderId]);
  
  
  const handleSubmit = async () => {
    try {
      await ShopOrdersStore.updateShopOrder(orderId, order);
      navigate('/orders');
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to update order', severity: 'error' });
    }
  };

  const handleChange = (updatedOrder) => {
    setOrder(updatedOrder);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  if (!order) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>Edit Order - {order.salesOrderNumber}</Typography>
      <OrderForm order={order} onChange={handleChange} onSubmit={handleSubmit} />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditOrder;
