import React, { useState, useEffect } from "react";
import { Grid, TextField, Autocomplete, Button, Modal, Box, Typography, CircularProgress } from "@mui/material";
import CustomerStore from "../../stores/CustomerStore";
import CustomerForm from "../../pages/customer/CustomerForm";
import { debounce } from "lodash";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '80%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    overflowY: 'scroll',
    p: 4,
};

/**
 * Component for searching for a customer with autocomplete
 * and adding a new customer.
 * MUST supply customers array
 * @param {number} margin - margin for the component
 * @param {function} handleChange - function to handle change of customer
 * @param {array} customers - array of customers
 * @param {string} value - value of the customer
 * @returns  
 */
const CustomerSearch = ({ margin = 0, handleChange, showAdd = true, marginTop, showInactive=false }) => {

    const [modalState, setModalState] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState({
        results: [],
        totalCount: 0,
    });
    const [open, setOpen] = useState(false);
    const loading = open && customers.totalCount === 0;

    const onCustomerSubmit = async (newCustomer) => {
        try {
            await CustomerStore.createCustomer(newCustomer);
            setModalState(!modalState);
        } catch (error) {
            console.error('Failed to add new customer:', error);
        }
    };

    // Search for customers every 500ms with given search value
    const handleInputChange = debounce((event, value, reason) => {
        const numOptions = customers.results.filter((customer) =>
            customer.companyName.toLowerCase().includes(value.toLowerCase())
        );
        if (value.length > 0 && numOptions.length < 10) {
            // Search for customers with the search value
            // Search on CustomerId, name, abbreviation, invoiceNumber, salesOrderNumber, customer, totalAmount, invoiceDate, paymentDate
            const filter = {
                $or: [
                    { companyName: { $regex: value, $options: 'i' } },
                    { companyAbbreviation: { $regex: value, $options: 'i' } },
                    { companyId: { $regex: value, $options: 'i' } },
                    { invoiceNumber: { $regex: value, $options: 'i' } },
                    { salesOrderNumber: { $regex: value, $options: 'i' } },
                    { customer: { $regex: value, $options: 'i' } },
                    { totalAmount: { $regex: value, $options: 'i' } },
                    { invoiceDate: { $regex: value, $options: 'i' } },
                    { paymentDate: { $regex: value, $options: 'i' } }
                ]
            };
    
            // Make sure to not also set search parameter - it will be handled by the filter
            getPaginatedCustomers(1, 20, 'companyName', filter, '', 'companyName')
        }
    }, 500);
    

    const getPaginatedCustomers = async (page, pageSize, sortBy, filter, search, searchField) => {
        const activeFilter = {
            ...filter,
            isActive: true
        }
        const usedFilter = showInactive ? filter : activeFilter; 
        try {
            await CustomerStore.fetchPaginatedCustomers(page, pageSize, sortBy, usedFilter, search, searchField);
            setCustomers(CustomerStore.paginatedCustomers);
        } catch (error) {
            console.error('Failed to fetch customers:', error);
        }
    };
    

    useEffect(() => {
        let active = true;

        let page = 1;
        let pageSize = 20;

        if (!loading) {
            return undefined;
        }

        (async () => {
            try {
                await getPaginatedCustomers(
                    page,
                    pageSize,
                    'companyName',
                    {},
                    '',
                    'companyName'
                );
                if (active) {
                    setCustomers(CustomerStore.paginatedCustomers);
                }
            } catch (error) {
                console.error('Failed to fetch customers:', error);
            }
        }
        )();

    }, [loading]);

    useEffect(() => {
        if (!open) {
            setCustomers({
                results: [],
                totalCount: 0,
            });
        }
    }, [open]);

    return (
        <>
            <Modal
                open={modalState}
                onClose={() => {
                    setModalState(!modalState);
                }}
            >
                <Box sx={style}>
                    <Typography variant="h4" sx={{ mb: 2 }}>Add New Customer</Typography>
                    {/* {loading ? (
                        <CircularProgress />
                    ) : ( */}
                    <CustomerForm onSubmit={onCustomerSubmit} />
                    {/* )} */}
                </Box>
            </Modal>
            <Grid container margin={margin} marginTop={(marginTop || marginTop === 0) ? marginTop : (margin + 3)}>
                {customers ? (
                    <>
                        <Grid item xs={12} md={showAdd ? 6 : 12}>

                            <Autocomplete
                                disablePortal
                                fullWidth
                                id="customer"
                                getOptionLabel={(option) => `${option.companyId} - ${option.companyName} - ${option.companyAbbreviation}`}
                                options={customers.results}
                                onChange={(event, newValue) => {
                                    handleChange(newValue);
                                }}
                                onInputChange={handleInputChange}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                loading={loading}
                                isOptionEqualToValue={(option, value) => {
                                    return option._id === value._id;
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Search Customer"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        {showAdd && (
                            <Grid item xs={12} md={6} p={1}>
                                <Button
                                    onClick={() => {
                                        setModalState(!modalState);
                                    }}
                                >
                                    Add Customer
                                </Button>
                            </Grid>
                        )}

                    </>
                ) : (
                    <>
                        Loading Customers...
                    </>
                )}
            </Grid>
        </>

    )
};

export default CustomerSearch;