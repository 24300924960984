import { makeAutoObservable } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class PayrollStore {
  paginatedPayHours = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 40,
    totalPages: 0,
    results: [],
  };
  paginatedPayAmounts = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 40,
    totalPages: 0,
    results: [],
  };
  
  constructor() {
    makeAutoObservable(this);
  }

  async fetchPaginatedPayHours(
    page = 1,
    pageSize = 40,
    sortBy = 'workDate',
    filter = {},
    search = '',
    searchField = 'date'
  ) {
    try {
      const { results, ...pagination } = await apiHandler.get(
        `/payHours?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`
      );
      this.paginatedPayHours = { ...pagination, results: results };
      return { ...pagination, results: results };
    } catch (error) {
      throw error;
    }
  }

  async fetchPaginatedPayAmounts(
    page = 1,
    pageSize = 40,
    sortBy = 'payStartDate',
    filter = {},
    search = '',
    searchField = 'date'
  ) {
    try {
      const { results, ...pagination } = await apiHandler.get(
        `/payAmounts?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`
      );
      this.paginatedPayAmounts = { ...pagination, results: results };
      return { ...pagination, results: results };
    } catch (error) {
      console.error("Error fetching paginated pay amounts:", error);
      return null;
    }
  }

  async fetchPayHourById(payHourId) {
    try {
      const response = await apiHandler.get(`/payHours/${payHourId}`);
      return response;
    } catch (error) {
      console.error(`Error fetching pay hour with ID ${payHourId}:`, error);
      return null;
    }
  }

  async fetchPayAmountById(payAmountId) {
    try {
      const response = await apiHandler.get(`/payAmounts/${payAmountId}`);
      return response;
    } catch (error) {
      console.error(`Error fetching pay amount with ID ${payAmountId}:`, error);
      return null;
    }
  }

  async createPayHour(payHourData) {
    try {
      const newPayHour = await apiHandler.post('/payHours', payHourData);
      this.paginatedPayHours.results.push(newPayHour);
      return newPayHour;
    } catch (error) {
      console.error("Error creating pay hour:", error);
    }
  }

  async createPayAmount(payAmountData) {
    try {
      const newPayAmount = await apiHandler.post('/payAmounts', payAmountData);
      this.paginatedPayAmounts.results.push(newPayAmount);
      return newPayAmount;
    } catch (error) {
      console.error("Error creating pay amount:", error);
    }
  }

  async updatePayHour(payHourId, payHourData) {
    try {
      const updatedPayHour = await apiHandler.put(`/payHours/${payHourId}`, payHourData);
      const index = this.paginatedPayHours.results.findIndex(payHour => payHour._id === payHourId);
      if (index > -1) {
        this.paginatedPayHours.results[index] = updatedPayHour;
      }
    } catch (error) {
      console.error("Error updating pay hour:", error);
    }
  }

  async updatePayAmount(payAmountId, payAmountData) {
    try {
      const updatedPayAmount = await apiHandler.put(`/payAmounts/${payAmountId}`, payAmountData);
      const index = this.paginatedPayAmounts.results.findIndex(payAmount => payAmount._id === payAmountId);
      if (index > -1) {
        this.paginatedPayAmounts.results[index] = updatedPayAmount;
      }
    } catch (error) {
      console.error("Error updating pay amount:", error);
    }
  }

  async deletePayHour(payHourId) {
    try {
      await apiHandler.delete(`/payHours/${payHourId}`);
      this.paginatedPayHours.results = this.paginatedPayHours.results.filter(payHour => payHour._id !== payHourId);
      return true;
    } catch (error) {
      console.error("Error deleting pay hour:", error);
      return false;
    }
  }

  async deletePayAmount(payAmountId) {
    try {
      await apiHandler.delete(`/payAmounts/${payAmountId}`);
      this.paginatedPayAmounts.results = this.paginatedPayAmounts.results.filter(payAmount => payAmount._id !== payAmountId);
      return true;
    } catch (error) {
      console.error("Error deleting pay amount:", error);
      return false;
    }
  }

};

export default new PayrollStore();