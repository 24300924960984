import {
  Box, TextField, Grid, Button, Typography, FormControlLabel, Checkbox, IconButton, 
  Divider, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const MachineForm = ({ machine, onSubmit, onDelete, onChange, back }) => {

  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange({
      ...machine,
      [name]: value
    });
  };

  const handleDateChange = (name, date) => {
    const validDate = date instanceof Date && !isNaN(date) ? date : null;
    onChange({
      ...machine,
      [name]: validDate
    });
  }

  const handleOperatorChange = (event) => {
    onChange({
      ...machine,
      operators: [event.target.value]
    });
  }

  const initialDates = {
    lastMaintenanceDate: machine.lastMaintenanceDate ? new Date(machine.lastMaintenanceDate) : null,
    nextMaintenanceDate: machine.nextMaintenanceDate ? new Date(machine.nextMaintenanceDate) : null
  }

  return (
    <Box sx={{ p: 3 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" mt={4} mb={2} gutterBottom>Machine Details</Typography>
            <TextField fullWidth name="name" label="Machine Number" value={machine.name} onChange={handleChange} margin="normal" />
            <TextField fullWidth name="operators" label="Operators" value={machine.operators?.join(', ')} onChange={handleOperatorChange} margin="normal" />
            <TextField fullWidth name="description" label="Description" value={machine.description} onChange={handleChange} margin="normal" />
            <FormControl fullWidth margin="normal">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status-select"
                name='status'
                value={machine.status}
                onChange={handleChange}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
                <MenuItem value="maintenance">Maintenance</MenuItem>
              </Select>
            </FormControl>
            <TextField fullWidth name="comments" label="Comments" value={machine.comments} onChange={handleChange} margin="normal" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6" mt={4} mb={2} gutterBottom>Maintenance</Typography>
            <DatePicker sx={{ mt: 2, mb: 1, width: '100%' }} name="lastMaintenanceDate" label="Last Maintenance" value={initialDates.lastMaintenanceDate} onChange={(date) => handleDateChange('lastMaintenanceDate', date)} />
            <DatePicker sx={{ mt: 2, mb: 1, width: '100%' }} name="nextMaintenanceDate" label="Next Maintenance" value={initialDates.nextMaintenanceDate} onChange={(date) => handleDateChange('nextMaintenanceDate', date)} />
          </Grid>

          <Divider sx={{ margin: '24px 0 24px 0' }} />

          <Grid item xs={12}>
            <Typography variant="h6" mt={4} mb={2} gutterBottom>Maintenance History</Typography>
            {machine.maintenanceHistory.length === 0 ? (
              <Typography variant="subtitle1" mt={2} mb={2}>No maintenance history available</Typography>
            ) : (
              machine.maintenanceHistory.map((entry, index) => (
                <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <DatePicker
                    sx={{ mt: 2, mb: 1, width: '100%' }}
                    label="Date"
                    value={new Date(entry.date)}
                    onChange={(date) => {
                      const history = machine.maintenanceHistory;
                      history[index].date = date;
                      history.sort((a, b) => new Date(a.date) - new Date(b.date));
                      onChange({ ...machine, maintenanceHistory: history });
                    }}
                  />
                  <TextField
                    label="Notes"
                    value={entry.notes}
                    margin="normal"
                    fullWidth
                    onChange={(event) => {
                      const history = machine.maintenanceHistory;
                      history[index].notes = event.target.value;
                      onChange({ ...machine, maintenanceHistory: history });
                    }}
                  />
                  <TextField
                    label="Performed By"
                    value={entry.performedBy}
                    margin="normal"
                    fullWidth
                    onChange={(event) => {
                      const history = machine.maintenanceHistory;
                      history[index].performedBy = event.target.value;
                      onChange({ ...machine, maintenanceHistory: history });
                    }}
                  />
                  <IconButton
                    variant="contained"
                    onClick={() => {
                      const history = machine.maintenanceHistory;
                      history.splice(index, 1);
                      onChange({ ...machine, maintenanceHistory: history });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))
            )}
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                onChange({
                  ...machine,
                  maintenanceHistory: [
                    ...machine.maintenanceHistory,
                    { date: new Date(), description: "" }
                  ]
                });
              }}
            >
              Add Maintenance Entry
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Box>
  );

}

export default MachineForm;