import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import VendorStore from "../../stores/VendorStore";
import VendorForm from "./VendorForm";
import { Box, Typography, CircularProgress, Snackbar, Alert } from "@mui/material";

const EditVendor = () => {
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const [vendor, setVendor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "" });

  useEffect(() => {
    setLoading(true);
    VendorStore.fetchVendorById(vendorId)
      .then(() => {
        setVendor(VendorStore.vendor);
        setLoading(false);
      })
      .catch(error => {
        setSnackbar({ open: true, message: 'Failed to fetch vendor', severity: 'error' });
        setLoading(false);
      });
  }, [vendorId]);

  const handleSubmit = async (updatedVendor) => {
    try {
      await VendorStore.updateVendor(vendorId, updatedVendor);
      navigate("/vendor", { state: { message: 'Vendor updated successfully', severity: 'success' } });
    } catch (error) {
      console.error("Failed to update vendor:", error);
      setSnackbar({ open: true, message: 'Failed to update vendor', severity: 'error' });
    }
  };

  const handleDelete = async () => {
    try {
      await VendorStore.deleteVendor(vendorId);
      navigate("/vendor", { state: { message: 'Vendor deleted successfully', severity: 'success' } });
    } catch (error) {
      console.error("Failed to delete vendor:", error);
      setSnackbar({ open: true, message: 'Failed to delete vendor', severity: 'error' });
    }
  };

  const handleBack = () => {
    navigate("/vendor");
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: "", severity: "" });
  };

  return (
    <Box sx={{ m: 4 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Edit Vendor</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        vendor && (
          <>
            <VendorForm
              initialVendorData={vendor}
              onSubmit={handleSubmit}
              onDelete={handleDelete}
              back={handleBack}
            />
          </>
        )
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
};

export default EditVendor;
