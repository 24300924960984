import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => (
  <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: 'background.paper' }}>
    <Typography variant="body2" color="text.secondary" align="center">
      © 2023 Elite Suite by Kratesoft
    </Typography>
    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
      <Link href="#" color="inherit">
        Privacy Policy
      </Link>
      {' | '}
      <Link href="#" color="inherit">
        Terms of Use
      </Link>
    </Typography>
  </Box>
);

export default Footer;
