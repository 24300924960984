import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  Button,
  List,
  ListItem,
  Divider,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import AddressEdit from "../../components/AddressEdit";

const CustomerForm = ({ initialCustomerData, onSubmit, onDelete, shippingAddresses }) => {
  const [customer, setCustomer] = useState({
    companyId: "",
    companyName: "",
    companyAbbreviation: "",
    companyPhone: "",
    companyFax: "",
    companyEmail: "",
    website: "",
    discount: 0,
    discountDays: 0,
    discountPercentage: 0,
    billingTerms: { netDays: "" },
    billingAddress: { street: "", city: "", state: "", postalCode: "" },
    shippingAddress: { street: "", city: "", state: "", postalCode: "" },
    contacts: [],
    comments: "",
    isActive: false
  });

  useEffect(() => {
    if (initialCustomerData) {
      setCustomer(initialCustomerData);
    }
  }, [initialCustomerData]);

  const handleInputChange = (event) => {
    setCustomer({ ...customer, [event.target.name]: event.target.value });
  };

  const handleCheck = (event, name) => {
    setCustomer({ ...customer, [name]: event.target.checked });
  }

  const handleSubChange = (type, event) => {
    setCustomer({
      ...customer,
      [type]: { ...customer[type], [event.target.name]: event.target.value },
    });
  };

  const handleContactChange = (index, event) => {
    const updatedContacts = customer.contacts.map((contact, i) =>
      i === index
        ? { ...contact, [event.target.name]: event.target.value }
        : contact
    );
    setCustomer({ ...customer, contacts: updatedContacts });
  };

  const addContact = () => {
    setCustomer({
      ...customer,
      contacts: [
        ...customer.contacts,
        { name: "", role: "", email: "", phone: "" },
      ],
    });
  };

  const removeContact = (index) => {
    const filteredContacts = customer.contacts.filter((_, i) => i !== index);
    setCustomer({ ...customer, contacts: filteredContacts });
  };

  const handleBillingAddressChange = (address) => {
    setCustomer({
      ...customer,
      billingAddress: address
    });
  }

  const handleShippingAddressChange = (address) => {
    setCustomer({
      ...customer,
      shippingAddress: address
    });
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Customer ID"
            name="companyId"
            fullWidth
            value={customer.companyId}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Customer Name"
            name="companyName"
            fullWidth
            value={customer.companyName}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Customer Abbreviation"
            name="companyAbbreviation"
            fullWidth
            value={customer.companyAbbreviation}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Phone"
            name="companyPhone"
            fullWidth
            value={customer.companyPhone}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Fax"
            name="companyFax"
            fullWidth
            value={customer.companyFax}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Email"
            name="companyEmail"
            fullWidth
            value={customer.companyEmail}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Website"
            name="website"
            fullWidth
            value={customer.website}
            onChange={handleInputChange}
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={customer.isActive}
                onChange={(e) => handleCheck(e, 'isActive')}
              />
            }
            label="Active Customer"
            sx={{ m: 1 }}
          />

        </Grid>

        <Grid item xs={12} md={6}>
          <AddressEdit label="Billing Address" address={customer.billingAddress} onChange={handleBillingAddressChange} />
          <Button
            variant="contained"
            sx={{ m: 3, width: '90%', justifyContent: 'center' }}
            onClick={shippingAddresses}
          >
            Shipping Addresses
          </Button>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <AddressEdit label="Shipping Address" address={customer.shippingAddress} onChange={handleShippingAddressChange} />
        </Grid> */}

        <Divider sx={{ width: '100%', mt: 3, mb: 1 }} />

        <Grid item xs={12} md={4}>
          <TextField
            label="discount"
            name="discount"
            fullWidth
            value={customer.discountPercentage}
            onChange={handleInputChange}
            margin="normal"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="days"
            name="days"
            fullWidth
            value={customer.discountDays}
            onChange={(e) => handleSubChange("billingTerms", e)}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="net"
            name="net"
            fullWidth
            value={customer.billingTerms.netDays}
            onChange={(e) => handleSubChange("billingTerms", e)}
            margin="normal"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Comments"
            name="comments"
            fullWidth
            value={customer.comments}
            onChange={handleInputChange}
            margin="normal"
            multiline
            minRows={2}
          />
        </Grid>

        <Grid item xs={12}>
          {/* <Typography variant="h6" sx={{ mt: 2 }}>
            Contacts
          </Typography> */}
          <List>
            {/* {customer.contacts.map((contact, index) => (
              <ListItem key={index} divider>
                <ListItemText
                  primary={
                    <TextField
                      label="Name"
                      name="name"
                      value={contact.name}
                      onChange={(e) => handleContactChange(index, e)}
                      fullWidth
                    />
                  }
                  secondary={
                    <>
                      <TextField
                        label="Role"
                        name="role"
                        value={contact.role}
                        onChange={(e) => handleContactChange(index, e)}
                        fullWidth
                        sx={{ mt: 1 }}
                      />

                      <ListItemText
                        primary={
                          <TextField
                            label="Name"
                            name="name"
                            value={contact.name}
                            onChange={(e) => handleContactChange(index, e)}
                            fullWidth
                          />
                        }
                        secondary={
                          <>
                            <TextField
                              label="Role"
                              name="role"
                              value={contact.role}
                              onChange={(e) => handleContactChange(index, e)}
                              fullWidth
                              sx={{ mt: 1 }}
                            />
                            <TextField
                              label="Email"
                              name="email"
                              value={contact.email}
                              onChange={(e) => handleContactChange(index, e)}
                              fullWidth
                              sx={{ mt: 1 }}
                            />
                            <TextField
                              label="Phone"
                              name="phone"
                              value={contact.phone}
                              onChange={(e) => handleContactChange(index, e)}
                              fullWidth
                              sx={{ mt: 1 }}
                            />
                          </>
                        }
                      />

                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removeContact(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))} */}
            <ListItem>
              <Button startIcon={<AddCircleOutlineIcon />} onClick={addContact}>
                Add Contact
              </Button>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
        {onDelete && (
          <Button
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => onDelete(customer.companyId)}
            sx={{ mr: 2 }}
          >
            Delete
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmit(customer)}
        >
          {initialCustomerData ? "Update" : "Add"}
        </Button>
      </Box>
    </Box>
  );
};

export default CustomerForm;
