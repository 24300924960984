import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import CustomerStore from '../stores/CustomerStore';
import DynamicSearchFilter from '../components/DynamicSearchFilter';
import { useNavigate } from 'react-router-dom';
import {
  Box, Typography, List, ListItem, Paper, CircularProgress, Snackbar, IconButton,
  ListItemText, ListItemSecondaryAction, TablePagination, useMediaQuery
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { DataGridPro } from '@mui/x-data-grid-pro';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const filterObject = {
  companyName: '',
  companyAbbreviation: '',
  companyId: '',
  contactName: '',
  contactEmail: '',
  shippingAddress: '',
  invoiceNumber: '',
  salesOrderNumber: '',
  customer: '',
  totalAmount: '',
  invoiceDate: '',
  paymentDate: '',
};

const Customers = observer(() => {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({});
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('lg'));

  useEffect(() => {
    fetchPaginatedCustomers();
  }, []);

  const fetchPaginatedCustomers = async (page = 1, pageSize = 10, sortBy = 'companyName', filter = filterCriteria, search = searchQuery) => {
    setLoading(true);
    CustomerStore.fetchPaginatedCustomers(page, pageSize, sortBy, filter, search).then(() => {
      setCustomers(CustomerStore.paginatedCustomers);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch customers', severity: 'error' });
      setLoading(false);
    });
  };

  const handleDynamicSearch = (search, filter, sortBy) => {
    setSearchQuery(search);
    setFilterCriteria(filter);
    fetchPaginatedCustomers(1, customers.pageSize, sortBy, filter, search);
  };

  const handleViewCustomer = (customerId) => {
    navigate(`/customer/${customerId}`);
  };

  const handleEditCustomer = (customerId) => {
    navigate(`/customer/edit/${customerId}`);
  };

  const handleDeleteCustomer = async (customerId) => {
    try {
      await CustomerStore.deleteCustomer(customerId);
      // Handle successful deletion
      setSnackbar({ open: true, message: 'Customer deleted successfully', severity: 'success' });
      fetchPaginatedCustomers(); // Refresh the customer list after deletion
    } catch (error) {
      console.error('Failed to delete customer:', error);
      setSnackbar({ open: true, message: 'Failed to delete customer', severity: 'error' });
    }
  };

  const handleAddCustomer = () => {
    navigate('/customer/add');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePageChange = (event, page) => {
    fetchPaginatedCustomers(page + 1, customers.pageSize);
  };

  const handleLimitChange = (event) => {
    fetchPaginatedCustomers(1, event.target.value);
  };

  const columns = [
    { field: 'companyName', headerName: 'Company Name', flex: 1 },
    { field: 'companyAbbreviation', headerName: 'Company Abbreviation', flex: 1 },
    { field: 'companyId', headerName: 'Company ID', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      align: 'right',
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleViewCustomer(params.row._id)}>
            <InfoIcon />
          </IconButton>
          <IconButton onClick={() => handleEditCustomer(params.row._id)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteCustomer(params.row._id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" gutterBottom>Customers</Typography>
        <DynamicSearchFilter
          defaultSort="companyName"
          onSearch={handleDynamicSearch}
          objectInterface={filterObject}
          style={{ width: '100%' }}
        />
        <IconButton color="primary" onClick={handleAddCustomer}>
          <AddIcon />
        </IconButton>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        isLargeScreen ? (
          <DataGridPro
            rows={customers.results}
            columns={columns}
            pageSize={customers.pageSize}
            rowCount={customers.totalCount}
            loading={loading}
            autoHeight
            getRowId={(row) => row._id}
          />
        ) : (
          <List sx={{ width: '100%' }}>
            {customers.results.map((customer, index) => (
              <ListItem 
                key={customer._id}
                button
                onClick={() => handleViewCustomer(customer._id)}
              >
                <ListItemText
                  primary={customer.companyName}
                  secondary={`ID: ${customer.companyId} | Contact: ${customer.contactName}`}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEditCustomer(customer._id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCustomer(customer._id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )
      )}

      <TablePagination
        component="div"
        count={customers.totalCount}
        page={customers.currentPage > 0 ? customers.currentPage - 1 : 0}
        onPageChange={handlePageChange}
        rowsPerPage={customers.pageSize}
        onRowsPerPageChange={handleLimitChange}
        rowsPerPageOptions={[10, 25, 50]}
      />

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default Customers;
