import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    padding: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '8.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColName: {
    width: '24.99%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 8,
  },
  headerCell: {
    margin: 5,
    fontSize: 10,
    fontWeight: 'bold',
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center'
  }
});

const PayrollPDF = ({ data, startDate, endDate }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>Payroll Report: {new Date(startDate).toLocaleDateString() || ''} {new Date(endDate).toLocaleDateString() || '' }</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColName}>
            <Text style={styles.headerCell}>Employee Name</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.headerCell}>ID</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.headerCell}>-(Inc)</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.headerCell}>Reg Hrs</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.headerCell}>OT Hours</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.headerCell}>Vacation</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.headerCell}>Holiday</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.headerCell}>Attendance</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.headerCell}>Other</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.headerCell}>Incentive</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.headerCell}>Quality</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.headerCell}>Bonus</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.headerCell}>Total Pay</Text>
          </View>
        </View>
        {data.map((row, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableColName}>
              <Text style={styles.tableCell}>{row.name}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.employeeId}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.negIncentives}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.regularHours}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.overtimeHours}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.vacation}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.holiday}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.attendance}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.otherIncome}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.incentives}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.quality}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.bonus}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.totalPay.toFixed(2)}</Text>
            </View>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export default PayrollPDF;
