import { makeAutoObservable } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class ReportsStore {
  reports = [];
  detailedReports = {
    plugs: [],
    deliveries: [],
    openMaster: [],
    closedMaster: [],
    daysInProcess: [],
    productionFootage: [],
    productionList: [],
    employeeTimesheet: [],
    employeePayroll: [],
    employeeAttendance: [],
    employeeScrap: [],
    salesByCustomer: [],
    outstandingReceivables: [],
    payableAccountCodes: [],
    payableOpenInvoice: [],
    payableCheckRegister: [],
    payablesByVendor: [],
    accountsPayable: [],
    ordersByMonth: [],
    salesByMachine: [],
    ordersWritten: [],
    monthlySales: []
  };

  constructor() {
    makeAutoObservable(this);
  }

  async fetchReports() {
    try {
      this.reports = await apiHandler.get('/reports');
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  }

  async fetchReportById(reportId) {
    try {
      const report = await apiHandler.get(`/reports/${reportId}`);
      return report;
    } catch (error) {
      console.error(`Error fetching report with ID ${reportId}:`, error);
    }
  }

  async fetchPlugsReport({
    customerId,
    machineId,
    serviceOrderNumber,
    purchaseOrderNumber,
    dueDateFrom,
    dueDateTo,
    millNo,
    promiseDateFrom,
    promiseDateTo,
    enteredDateFrom,
    enteredDateTo
  }) {
    try {
      let queryParams = new URLSearchParams({
        customerId,
        machineId,
        serviceOrderNumber,
        purchaseOrderNumber,
        dueDateFrom,
        dueDateTo,
        millNo,
        promiseDateFrom,
        promiseDateTo,
        enteredDateFrom,
        enteredDateTo,
        startDate: dueDateFrom,
        endDate: dueDateTo
      });

      let keysToDelete = [];

      queryParams.forEach((value, key) => {
        if ((!value && value !== 0) || value === 'undefined' || value === '') {
          keysToDelete.push(key);
        }
      });

      keysToDelete.forEach(key => {
        queryParams.delete(key);
      });

      const url = `/reports/plugs?${queryParams}`;
      const plugsReport = await apiHandler.get(url);
      this.detailedReports.plugs = plugsReport;
    } catch (error) {
      console.error("Error fetching plugs report:", error);
    }
  }

  async fetchDeliveriesReport({
    startDate,
    endDate,
    layout
  }) {
    try {
      let queryParams = new URLSearchParams({
        startDate,
        endDate,
        layout
      });

      let keysToDelete = [];

      queryParams.forEach((value, key) => {
        if ((!value && value !== 0) || value === 'undefined' || value === '') {
          keysToDelete.push(key);
        }
      });

      keysToDelete.forEach(key => {
        queryParams.delete(key);
      });

      const url = `/reports/deliveries?${queryParams}`;
      const deliveriesReport = await apiHandler.get(url);
      this.detailedReports.deliveries = deliveriesReport;
    } catch (error) {
      console.error("Error fetching deliveries report:", error);
    }
  }

  async fetchOpenMasterReport({
    customerId,
    machineId,
    serviceOrderNumber,
    purchaseOrderNumber,
    dueDateFrom,
    dueDateTo,
    millNo,
    promiseDateFrom,
    promiseDateTo,
    enteredDateFrom,
    enteredDateTo
  }) {
    try {
      let queryParams = new URLSearchParams({
        customerId,
        machineId,
        serviceOrderNumber,
        purchaseOrderNumber,
        dueDateFrom,
        dueDateTo,
        millNo,
        promiseDateFrom,
        promiseDateTo,
        enteredDateFrom,
        enteredDateTo,
        startDate: dueDateFrom,
        endDate: dueDateTo
      });

      let keysToDelete = [];

      queryParams.forEach((value, key) => {
        if ((!value && value !== 0) || value === 'undefined' || value === '') {
          keysToDelete.push(key);
        }
      });

      keysToDelete.forEach(key => {
        queryParams.delete(key);
      });

      const url = `/reports/openMaster?${queryParams}`;
      const openMasterReport = await apiHandler.get(url);
      this.detailedReports.openMaster = openMasterReport;
    } catch (error) {
      console.error("Error fetching open master report:", error);
    }
  }

  async fetchClosedMasterReport({
    customerId,
    machineId,
    serviceOrderNumber,
    purchaseOrderNumber,
    dueDateFrom,
    dueDateTo,
    millNo,
    promiseDateFrom,
    promiseDateTo,
    enteredDateFrom,
    enteredDateTo
  }) {
    try {
      let queryParams = new URLSearchParams({
        customerId,
        machineId,
        serviceOrderNumber,
        purchaseOrderNumber,
        dueDateFrom,
        dueDateTo,
        millNo,
        promiseDateFrom,
        promiseDateTo,
        enteredDateFrom,
        enteredDateTo,
        startDate: dueDateFrom,
        endDate: dueDateTo
      });

      let keysToDelete = [];

      queryParams.forEach((value, key) => {
        if ((!value && value !== 0) || value === 'undefined' || value === '') {
          keysToDelete.push(key);
        }
      });

      keysToDelete.forEach(key => {
        queryParams.delete(key);
      });

      const url = `/reports/closedMaster?${queryParams}`;
      const closedMasterReport = await apiHandler.get(url);
      this.detailedReports.closedMaster = closedMasterReport;
    } catch (error) {
      console.error("Error fetching closed master report:", error);
    }
  }


  async fetchDaysInProcessReport({
    startDate,
    endDate,
    customerId
  }) {
    try {
      let queryParams = new URLSearchParams({
        startDate,
        endDate,
        customerId
      });

      let keysToDelete = [];

      queryParams.forEach((value, key) => {
        if ((!value && value !== 0) || value === 'undefined' || value === '') {
          keysToDelete.push(key);
        }
      });

      keysToDelete.forEach(key => {
        queryParams.delete(key);
      });

      const url = `/reports/daysInProcess?${queryParams}`;
      const daysInProcessReport = await apiHandler.get(url);
      this.detailedReports.daysInProcess = daysInProcessReport;
    } catch (error) {
      console.error("Error fetching days in process report:", error);
    }
  }

  async fetchProductionFootageReport({
    customerId,
    machineId,
    serviceOrderNumber,
    purchaseOrderNumber,
    dueDateFrom,
    dueDateTo,
    millNo,
    promiseDateFrom,
    promiseDateTo,
    enteredDateFrom,
    enteredDateTo,
    totalBy
  }) {
    try {
      let queryParams = new URLSearchParams({
        customerId,
        machineId,
        serviceOrderNumber,
        purchaseOrderNumber,
        dueDateFrom,
        dueDateTo,
        millNo,
        promiseDateFrom,
        promiseDateTo,
        enteredDateFrom,
        enteredDateTo,
        startDate: dueDateFrom,
        endDate: dueDateTo,
        totalBy
      });

      let keysToDelete = [];

      queryParams.forEach((value, key) => {
        if ((!value && value !== 0) || value === 'undefined' || value === '') {
          keysToDelete.push(key);
        }
      });

      keysToDelete.forEach(key => {
        queryParams.delete(key);
      });

      const url = `/reports/footage?${queryParams}`;
      const productionFootageReport = await apiHandler.get(url);
      this.detailedReports.productionFootage = productionFootageReport;
    } catch (error) {
      console.error("Error fetching production footage report:", error);

    }
  }

  async fetchProductionListReport({
    customerId,
    SONum,
    dueDateFrom,
    dueDateTo,
    PONum,
    promiseDateFrom,
    promiseDateTo,
    millJobNum,
    enteredDateFrom,
    enteredDateTo,
    machineId,
  }) {
    try {
      let queryParams = new URLSearchParams({
        customerId,
        SONum,
        dueDateFrom,
        dueDateTo,
        PONum,
        promiseDateFrom,
        promiseDateTo,
        millJobNum,
        enteredDateFrom,
        enteredDateTo,
        machineId,
      });

      let keysToDelete = [];

      queryParams.forEach((value, key) => {
        if ((!value && value !== 0) || value === 'undefined' || value === '') {
          keysToDelete.push(key);
        }
      });

      keysToDelete.forEach(key => {
        queryParams.delete(key);
      });

      const url = `/reports/productionList?${queryParams}`;
      const productionListReport = await apiHandler.get(url);
      this.detailedReports.productionList = productionListReport;
    } catch (error) {
      console.error("Error fetching production list report:", error);

    }
  }

  async fetchEmployeeTimesheetReport() {
    // needs updated with params when timesheet report endpoint implemented
    try {
      const url = `/reports/employee/timesheet`;
      const employeeTimesheetReport = await apiHandler.get(url);
      this.detailedReports.employeeTimesheet = employeeTimesheetReport;
    } catch (error) {
      console.error("Error fetching employee timesheet report:", error);
    }
  }

  async fetchEmployeePayrollReport({ customerId, startDate, endDate }) {
    try {

      const queryParams = new URLSearchParams({
        fromDate: startDate,
        toDate: endDate,
        customerId
      });

      const url = `/reports/employee/payroll?${queryParams}`;
      const employeePayrollReport = await apiHandler.get(url);
      this.detailedReports.employeePayroll = employeePayrollReport;
    } catch (error) {
      console.error("Error fetching employee payroll report:", error);

    }
  }

  async fetchEmployeeAttendanceReport({ employeeId, startDate, endDate }) {
    try {
      const queryParams = new URLSearchParams({
        employeeId,
        fromDate: startDate,
        toDate: endDate
      });
      const url = `/reports/employee/attendance?${queryParams}`;
      const employeeAttendanceReport = await apiHandler.get(url);
      this.detailedReports.employeeAttendance = employeeAttendanceReport;
    } catch (error) {
      console.error("Error fetching employee attendance report:", error);
      throw error;
    }
  }

  async fetchEmployeeScrapReport({ employeeId, fromDate, toDate }) {
    try {
      const params = new URLSearchParams({
        employeeId,
        fromDate,
        toDate
      });
      const url = `/reports/employee/scrap?${params}`;
      const employeeScrapReport = await apiHandler.get(url);
      this.detailedReports.employeeScrap = employeeScrapReport;
    } catch (error) {
      console.error("Error fetching employee scrap report");
      throw error;
    }
  }

  async fetchSalesByCustomerReport({ customerId }) {
    try {
      let queryParams = new URLSearchParams({
        customerId
      });

      let keysToDelete = [];

      queryParams.forEach((value, key) => {
        if ((!value && value !== 0) || value === 'undefined' || value === '') {
          keysToDelete.push(key);
        }
      });

      keysToDelete.forEach(key => {
        queryParams.delete(key);
      });

      const url = `/reports/sales-by-customer?${queryParams}`;
      const salesByCustomer = await apiHandler.get(url);
      this.detailedReports.salesByCustomer = salesByCustomer;
    } catch (error) {
      console.error("Error fetching sales by customer report:", error);
    }
  }

  async fetchSalesOutstandingReceivablesReport() {
    try {
      const url = `/reports/sales/outstanding-receivables`;
      const outstandingReceivables = await apiHandler.get(url);
      this.detailedReports.outstandingReceivables = outstandingReceivables;
    } catch (error) {
      console.error("Error fetching sales outstanding receivables report:", error);
    }
  }

  async fetchPayablesAccountCodes(accountCode) {
    try {
      let queryParams = new URLSearchParams({
        accountCodeId: accountCode
      });

      const url = `/reports/payables/accountCodes?${queryParams}`;
      const payablesAccountCodes = await apiHandler.get(url);
      this.detailedReports.payableAccountCodes = payablesAccountCodes;
    } catch (error) {
      console.error("Error fetching payables account codes:", error);
      throw error;
    }
  }

  async fetchPayablesOpenInvoice() {
    try {
      const url = `/reports/payables/openInvoice`;
      const payablesOpenInvoice = await apiHandler.get(url);
      this.detailedReports.payableOpenInvoice = payablesOpenInvoice;
    } catch (error) {
      console.error("Error fetching payables open invoice:", error);
      throw error;
    }
  }

  async fetchCheckRegister(checkNo) {
    try {
      let queryParams = new URLSearchParams({
        checkNo
      });

      const url = `/reports/payables/checkRegister?${queryParams}`;
      const payablesCheckRegister = await apiHandler.get(url);
      this.detailedReports.payableCheckRegister = payablesCheckRegister;
    } catch (error) {
      console.error("Error fetching payables check register:", error);
      throw error;
    }
  }

  async fetchPayablesByAccount(accountId) {
    try {
      let queryParams = new URLSearchParams({
        accountId
      });

      const url = `/reports/payables/byAccount?${queryParams}`;
      const payablesByAccount = await apiHandler.get(url);
      this.detailedReports.payablesByAccount = payablesByAccount;
    } catch (error) {
      console.error("Error fetching payables by account:", error);
      throw error;
    }
  }

  async fetchPayablesByVendor(vendorId) {
    try {
      let queryParams = new URLSearchParams({
        vendorId
      });

      const url = `/reports/payables/byVendor?${queryParams}`;
      const payablesByVendor = await apiHandler.get(url);
      this.detailedReports.payablesByVendor = payablesByVendor;
    } catch (error) {
      console.error("Error fetching payables by vendor:", error);
      throw error;
    }
  }

  async fetchAccountsPayable() {
    try {
      const url = `/reports/payables/accountsPayable`;
      const accountsPayable = await apiHandler.get(url);
      this.detailedReports.accountsPayable = accountsPayable;
    } catch (error) {
      console.error("Error fetching accounts payable:", error);
      throw error;
    }

  }

  async fetchOrdersByMonth(month, year) {
    try {
      let queryParams = new URLSearchParams({
        month,
        year
      });

      const url = `/reports/sales/orders-by-month?${queryParams}`;
      const ordersByMonth = await apiHandler.get(url);
      this.detailedReports.ordersByMonth = ordersByMonth
    } catch (error) {
      console.error("Error fetching orders by month:", error);
      throw error;
    }
  }

  async fetchSalesByMachine(machineId) {
    try {
      let queryParams = new URLSearchParams({
        machineId
      });

      const url = `/reports/sales/sales-by-machine?${queryParams}`;
      const salesByMachine = await apiHandler.get(url);
      this.detailedReports.salesByMachine = salesByMachine;
    } catch (error) {
      console.error("Error fetching sales by machine:", error);
      throw error;
    }
  }

  async fetchOrdersWritten() {
    try {
      const url = `/reports/sales/orders-written`;
      const ordersWritten = await apiHandler.get(url);
      this.detailedReports.ordersWritten = ordersWritten;
    } catch (error) {
      console.error("Error fetching orders written:", error);
      throw error;
    }
  }

  async fetchMonthlySales(startDate, endDate) {
    try {
      let queryParams = new URLSearchParams({
        startDate,
        endDate
      });

      const url = `/reports/sales/monthly-sales?${queryParams}`;
      const monthlySales = await apiHandler.get(url);
      this.detailedReports.monthlySales = monthlySales;
    } catch (error) {
      console.error("Error fetching monthly sales:", error);
      throw error;
    }
  }

  async createReport(reportData) {
    try {
      const newReport = await apiHandler.post('/reports', reportData);
      this.reports.push(newReport);
    } catch (error) {
      console.error("Error creating report:", error);
    }
  }

  async updateReport(reportId, reportData) {
    try {
      const updatedReport = await apiHandler.put(`/reports/${reportId}`, reportData);
      const index = this.reports.findIndex(report => report._id === reportId);
      if (index > -1) {
        this.reports[index] = updatedReport;
      }
    } catch (error) {
      console.error(`Error updating report with ID ${reportId}:`, error);
    }
  }

  async deleteReport(reportId) {
    try {
      await apiHandler.delete(`/reports/${reportId}`);
      this.reports = this.reports.filter(report => report._id !== reportId);
    } catch (error) {
      console.error(`Error deleting report with ID ${reportId}:`, error);
    }
  }
}

export default new ReportsStore();
