import { Document, Page, Text, View } from '@react-pdf/renderer';

const styles = {
  page: {
    flexDirection: 'row',
    padding: 20,
    fontSize: 12,
  },
  employeeContainer: {
    flex: 1,
    margin: 10,
    padding: 10,
  },
  employeeContainerHalf: {
    flex: 0.5,
    margin: 10,
    padding: 10,
  },
  name: {
    fontSize: 18,
    marginBottom: 20,
    fontWeight: 'bold',
    borderBottom: '1px solid black'
  },
  date: {
    flexDirection: 'row',
    justifyContent: 'start',
    gap: 10,
    marginBottom: 20,
  },
  weekdayText: {
    fontSize: 14,
    width: '10%',
  },
  dateText: {
    fontSize: 14,
    width: '50%',
  },
  blankSpace: {
    width: '40%',
    borderBottom: '1px solid black',
  },
};

const Employee = ({ employee, dates, halfWidth }) => (
  <View style={halfWidth ? styles.employeeContainerHalf : styles.employeeContainer}>
    <Text style={styles.name}>{employee.lastName}, {employee.firstName}</Text>
    {dates.map((date, index) => (
      <View key={index} style={styles.date}>
        <Text style={styles.weekdayText}>
          {date.toLocaleDateString('en-US', { weekday: 'short' })} 
        </Text>
        <Text style={styles.dateText}>
          {date.toLocaleDateString()}
        </Text>
        <View style={styles.blankSpace}></View>
      </View>
    ))}
  </View>
);

const EmployeeTimeSheetPDF = ({ employees, dates }) => {
  return (
    <Document>
      {employees?.reduce((acc, employee, index) => {
        if (index % 2 === 0) {
          acc.push([employee]);
        } else {
          acc[acc.length - 1].push(employee);
        }
        return acc;
      }, []).map((employeePair, index) => (
        <Page size="A4" style={styles.page} orientation="landscape" key={index}>
          {employeePair.map((employee, index) => (
            <Employee
              key={index}
              employee={employee}
              dates={dates}
              halfWidth={employeePair.length === 1}
            />
          ))}
        </Page>
      ))}
    </Document>
  )
}
export default EmployeeTimeSheetPDF;
