import axios, { CancelToken } from 'axios';
import authStore from '../stores/AuthStore';

class ApiHandler {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API || 'https://api.krate.app/api',
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getToken() {
    return window.localStorage.getItem('token');
  }

  setToken(token) {
    window.localStorage.setItem('token', token);
  }

  removeToken() {
    window.localStorage.removeItem('token');
    authStore.logout();
  }

  async getHeaders() {
    const token = this.getToken();
    return {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : undefined,
    };
  }

  handleErrorCode(error) {
    if (error.response) {
      const status = error.response.status;
      const data = error.response;
      const message = data && data.message ? data.message : 'An unexpected error occurred.';
      console.error(`API Error (${status}): ${message}`, data);

      if (status === 401) {
        this.removeToken();
      }
    } else {
      console.error('API Request Error:', error.message);
    }
  }

  async request(method, endpoint, data, successMessage) {
    try {
      const headers = await this.getHeaders();
      const cancelToken = CancelToken.source();

      const response = await this.api.request({
        method,
        url: endpoint,
        data,
        headers,
        cancelToken: cancelToken.token,
      });

      return response.data;
    } catch (error) {
      this.handleErrorCode(error);
      throw error;
    }
  }

  get(endpoint) {
    return this.request('get', endpoint);
  }

  post(endpoint, data, successMessage) {
    return this.request('post', endpoint, data, successMessage);
  }

  put(endpoint, data, successMessage) {
    return this.request('put', endpoint, data, successMessage);
  }

  delete(endpoint, data, successMessage) {
    return this.request('delete', endpoint, data, successMessage);
  }

  // Utility methods
  async ping() {
    //return this.get('/ping');
    return;
  }

  async getCurrentIP() {
    // Assuming there's an endpoint to get the current IP
    //return this.get('/current-ip');
    return;
  }

  async getApiIP() {
    // Assuming there's an endpoint to get the API's IP
    //return this.get('/api-ip');
    return;
  }
}

const apiHandler = new ApiHandler();
export default apiHandler;
