import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box } from "@mui/material";
import { AuthProvider } from "./auth/Auth";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/order/Orders";
import Receivables from "./pages/Receivables/Receivables";
import Employee from "./pages/Employee";
import NewEmployee from "./pages/employee/NewEmployee";
import Vendor from "./pages/Vendor";
import NewVendor from "./pages/vendor/NewVendor";
import EditVendor from "./pages/vendor/EditVendor";
import Productions from "./pages/Productions";
import Customers from "./pages/Customers";
import CustomerDetails from "./pages/customer/CustomerDetails";
import EmployeeDetails from "./pages/employee/EmployeeDetails";
import Payables from "./pages/Payables";
import Reports from "./pages/Reports";
import Users from "./pages/Users";
import PreferencesPage from "./pages/Preferences";
import Help from "./pages/Help";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import AddCustomer from "./pages/customer/AddCustomer";
import EditCustomer from "./pages/customer/EditCustomer";
import EditOrder from "./pages/order/EditOrder";
import OrderDetails from "./pages/order/OrderDetails";
import EditEmployee from "./pages/employee/EditEmployee";
import AddOrder from "./pages/order/AddOrder";
import EditMachineOperator from "./pages/machines/EditMachineOperator";
import NewMachineOperator from "./pages/machines/NewMachineOperator";
import MachineDetails from "./pages/machines/MachineDetails";
import EditPayable from "./pages/payables/EditPayable";
import AddPayable from "./pages/payables/AddPayable";
import PayableDetails from "./pages/payables/PayableDetails";
import OrderTabs from "./pages/OrderTabs";
import ShippingAddress from "./pages/customer/ShippingAddresses";
import PrivacyPage from "./pages/Privacy";
import LicensingPage from "./pages/Licensing";
import PayrollTabs from "./pages/PayrollTabs";
import AddReceivable from "./pages/Receivables/AddReceivable";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#FFFFFF",
      paper: "#F0F0F0",
    },
    primary: {
      main: "#1565C0",
    },
    secondary: {
      main: "#757575",
    },
    text: {
      primary: "#212121",
      secondary: "#757575",
    },
  },
  typography: {
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: "2.5rem",
    },
    h2: {
      fontWeight: 700,
      fontSize: "2.25rem",
    },
    h3: {
      fontWeight: 700,
      fontSize: "2rem",
    },
    // ... other typography variants
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#F7F7F7",
          boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
          borderRadius: "8px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        },
      },
    },
    // ... other component customizations
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#1C1C1C",
    },
    primary: {
      main: "#2196F3", // A slightly brighter blue for better visibility in dark mode
    },
    secondary: {
      main: "#BBBBBB", // A medium grey that contrasts well with the dark background
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#BBBBBB",
    },
  },
  typography: {
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: "2.5rem",
    },
    h2: {
      fontWeight: 700,
      fontSize: "2.25rem",
    },
    h3: {
      fontWeight: 700,
      fontSize: "2rem",
    },
    // ... other typography variants
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#242424",
          boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
        },
      },
    },
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.3), 0px 1px 1px 0px rgba(0,0,0,0.25), 0px 1px 3px 0px rgba(0,0,0,0.2)",
    // ... other shadow variants
  ],
});

function App() {
  const [isDarkMode] = useState(false);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router>
            <Box
              sx={{ height: "100vh", display: "flex", flexDirection: "column" }}
            >
              <Routes>
                <Route path="*" element={<LayoutWithNavbarAndFooter />} />
              </Routes>
            </Box>
          </Router>
        </LocalizationProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

function LayoutWithNavbarAndFooter() {
  const location = useLocation();
  const modalPages = [
    "/login",
    "/signup",
    "/forgot-password",
    "/reset-password",
  ];
  const isModal = modalPages.includes(location.pathname);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          minHeight: "calc(100vh - 200px)",
        }}
      >
        {!isModal && <><Navbar>
          <Routes>
            <Route
              path="/"
              element={<ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>} />
            <Route
              path="/orders"
              element={<ProtectedRoute>
                <OrderTabs />
              </ProtectedRoute>} />
            <Route
              path="/orders/add"
              element={<ProtectedRoute>
                <AddOrder />
              </ProtectedRoute>} />
            <Route
              path="/orders/edit/:orderId"
              element={<ProtectedRoute>
                <EditOrder />
              </ProtectedRoute>} />
            <Route
              path="/orders/:orderId"
              element={<ProtectedRoute>
                <OrderDetails />
              </ProtectedRoute>} />
            <Route
              path="/orders"
              element={<ProtectedRoute>
                <Orders />
              </ProtectedRoute>} />
            <Route
              path="/machine/add"
              element={<ProtectedRoute>
                <NewMachineOperator />
              </ProtectedRoute>} />
            <Route
              path="/machine/edit/:machineId"
              element={<ProtectedRoute>
                <EditMachineOperator />
              </ProtectedRoute>} />
            <Route
              path="/machine/:machineId"
              element={<ProtectedRoute>
                <MachineDetails />
              </ProtectedRoute>} />
            <Route
              path="/receivables"
              element={<ProtectedRoute>
                <Receivables />
              </ProtectedRoute>} />
            <Route
              path="/receivables/edit/:invoiceId"
              element={<ProtectedRoute>
                <AddReceivable mode="add" />
              </ProtectedRoute>} />
            <Route
              path="/employee"
              element={<ProtectedRoute>
                <Employee />
              </ProtectedRoute>} />
            <Route
              path="/employee/:employeeId"
              element={<ProtectedRoute>
                <EmployeeDetails mode="view" />
              </ProtectedRoute>} />
            <Route
              path="/employee/add"
              element={<ProtectedRoute>
                <NewEmployee mode="add" />
              </ProtectedRoute>} />
            <Route
              path="/employee/edit/:employeeId"
              element={<ProtectedRoute>
                <EditEmployee mode="edit" />
              </ProtectedRoute>} />
            <Route
              path="/vendor"
              element={<ProtectedRoute>
                <Vendor />
              </ProtectedRoute>} />
            <Route
              path="/vendor/add"
              element={<ProtectedRoute>
                <NewVendor mode="add" />
              </ProtectedRoute>} />
            <Route
              path="/vendor/edit/:vendorId"
              element={<ProtectedRoute>
                <EditVendor mode="edit" />
              </ProtectedRoute>} />
            <Route
              path="/productions"
              element={<ProtectedRoute>
                <Productions />
              </ProtectedRoute>} />
            <Route
              path="/payroll"
              element={<ProtectedRoute>
                <PayrollTabs />
              </ProtectedRoute>} />
            <Route
              path="/customers"
              element={<ProtectedRoute>
                <Customers />
              </ProtectedRoute>} />
            <Route
              path="/customer/add"
              element={<ProtectedRoute>
                <AddCustomer mode="add" />
              </ProtectedRoute>} />
            <Route
              path="/customer/edit/:customerId"
              element={<ProtectedRoute>
                <EditCustomer mode="edit" />
              </ProtectedRoute>} />
            <Route
              path="/customer/:customerId"
              element={<ProtectedRoute>
                <CustomerDetails mode="view" />
              </ProtectedRoute>} />
            <Route
              path="/customer/:customerId/shippingAddresses"
              element={<ProtectedRoute>
                <ShippingAddress />
              </ProtectedRoute>}
            />
            <Route
              path="/payables"
              element={<ProtectedRoute>
                <Payables />
              </ProtectedRoute>} />
            <Route
              path="/payable/:payableId"
              element={<ProtectedRoute>
                <PayableDetails />
              </ProtectedRoute>} />
            <Route
              path="/payable/edit/:payableId"
              element={<ProtectedRoute>
                <EditPayable mode="edit" />
              </ProtectedRoute>} />
            <Route
              path="/payable/add"
              element={<ProtectedRoute>
                <AddPayable mode="add" />
              </ProtectedRoute>} />
            <Route
              path="/reports"
              element={<ProtectedRoute>
                <Reports />
              </ProtectedRoute>} />
            <Route
              path="/users"
              element={<ProtectedRoute>
                <Users />
              </ProtectedRoute>} />
            <Route
              path="/preferences"
              element={<ProtectedRoute>
                <PreferencesPage />
              </ProtectedRoute>} />
            <Route
              path="/help"
              element={<ProtectedRoute>
                <Help />
              </ProtectedRoute>} />
            <Route
              path="/dashboard"
              element={<ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>} />
            <Route
              path="/licensing"
              element={<ProtectedRoute>
                <LicensingPage />
              </ProtectedRoute>} />
            <Route
              path="/privacy"
              element={<ProtectedRoute>
                <PrivacyPage />
              </ProtectedRoute>} />
          </Routes>
        </Navbar></>
        }
        {isModal &&
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        }
      </Box>
    </>
  );
}

export default App;
