// EmployeeScrapPDF.js
import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    marginBottom: 20,
  },
  header: {
    fontSize: 18,
    marginBottom: 20,
  },
  subHeader: {
    fontSize: 14,
    borderWidth: 1,
    width: '20%',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 3
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 12,
    padding: 3,
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 3,
    fontSize: 10,
  },
  tableCell: {
    margin: 5,
    fontSize: 8,
  },
});

const EmployeeScrapPDF = ({ report, startDate, endDate }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>Employee Scrap Report: {dayjs(startDate).format('MM/DD/YYYY')} - {dayjs(endDate).format('MM/DD/YYYY')}</Text>
      {report.map((employee, index) => (
        <View key={index} style={styles.section}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={[styles.subHeader, { width: '60%' }]}>Employee Name: {employee.name}</Text>
              <Text style={styles.subHeader}>Total Pieces: {employee.totalPieces}</Text>
              <Text style={styles.subHeader}>Total Feet: {employee.totalFeet?.toFixed(2) || 0}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>Date</Text>
              <Text style={styles.tableColHeader}>Order #</Text>
              <Text style={[styles.tableColHeader, { width: '40%' }]}>Customer</Text>
              <Text style={styles.tableColHeader}>Machine</Text>
              <Text style={styles.tableColHeader}>OD</Text>
              <Text style={styles.tableColHeader}>ID</Text>
              <Text style={styles.tableColHeader}>Pieces</Text>
              <Text style={styles.tableColHeader}>Feet</Text>
              <Text style={[styles.tableColHeader, { width: '40%' }]}>Comments</Text>
            </View>
            {employee.scrapEntries.map((entry, entryIndex) => (
              <View key={entryIndex} style={styles.tableRow}>
                <Text style={styles.tableCol}>{dayjs(entry.date).format('MM/DD/YYYY')}</Text>
                <Text style={styles.tableCol}>{entry.salesOrderNumber}</Text>
                <Text style={[styles.tableCol, { width: '40%' }]}>{entry.customer}</Text>
                <Text style={styles.tableCol}>{entry.machine}</Text>
                <Text style={styles.tableCol}>{entry.outerDiameter}</Text>
                <Text style={styles.tableCol}>{entry.innerDiameter}</Text>
                <Text style={styles.tableCol}>{entry.pieces}</Text>
                <Text style={styles.tableCol}>{entry.feet}</Text>
                <Text style={[styles.tableCol, { width: '40%' }]}>{entry.comments}</Text>
              </View>
            ))}
          </View>
        </View>
      ))}
    </Page>
  </Document>
);

export default EmployeeScrapPDF;
