import { makeAutoObservable } from "mobx";
import apiHandler from "../handlers/ApiHandler";

class JobStore {
  paginatedJobs = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: [],
  }

  constructor() {
    makeAutoObservable(this);
  }

  async fetchPaginatedJobs(page = 1, pageSize = 10, sortBy = 'id', filter = {}, search = '', searchField = 'id') {
    try {
      const { results, ...pagination } = await apiHandler.get(`/jobs?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`);
      this.paginatedJobs = { ...pagination, results: results };
      return { results, ...pagination };
    } catch (error) {
      console.error("Error fetching paginated jobs:", error);
    }
  }

  async fetchJobById(jobId) {
    try {
      const job = await apiHandler.get(`/jobs/${jobId}`);
      return job;
    } catch (error) {
      console.error("Error fetching job by ID:", error);
    }
  }

  async createJob(jobData) {
    try {
      const newJob = await apiHandler.post('/jobs', jobData);
      this.paginatedJobs.results.push(newJob);
    } catch (error) {
      console.error("Error creating job:", error);
    }
  }

  async updateJob(jobId, jobData) {
    try {
      const updatedJob = await apiHandler.put(`/jobs/${jobId}`, jobData);
      const index = this.paginatedJobs.results.findIndex(job => job._id === jobId);
      if (index > -1) {
        this.paginatedJobs.results[index] = updatedJob;
      }
    } catch (error) {
      console.error("Error updating job:", error);
    }
  }

  async deleteJob(jobId) {
    try {
      await apiHandler.delete(`/jobs/${jobId}`);
      this.paginatedJobs.results = this.paginatedJobs.results.filter(job => job._id !== jobId);
      return;
    } catch (error) {
      console.error("Error deleting job:", error);
    }
  }
}

export default new JobStore();