import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PayableStore from '../../stores/PayablesStore';
import PayableForm from './PayableForm';
import { Box, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import VendorSearch from '../../components/search/VendorSearch';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AddPayable = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [payable, setPayable] = useState({
    payablesId: '',
    amountWords: '',
    invoiceAmount: 0,
    paidAmount: 0,
    checkNo: '',
    datePaid: '',
    dateRecd: '',
    dueDate: '',
    expenseCode: '',
    commentsExternal: '',
    commentsInternal: '',
    enterBy: '',
    enterDate: '',
    invoiceDate: '',
    invoiceNo: '',
    payThis: true,
    vendor: {
      _id: '',
      vendorId: '',
      vendorName: ''
    },
    void: false
  });
  const [vendor, setVendor] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (newPayable) => {
    setLoading(true);
    try {
      await PayableStore.createPayable(newPayable);
      navigate('/payables');
    } catch (error) {
      setError('Failed to add new payable. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleVendorChange = (vendor) => {
    setVendor(vendor);
    if (vendor) {
      setPayable({
        ...payable,
        vendor: vendor
      });
    }
  };

  const handleCloseSnackbar = () => {
    setError(null);
  };

  return (
    <Box sx={{ m: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 2 }}>
        <Link to="/payables" style={{ textDecoration: 'none' }}>
          <ArrowBackIcon sx={{ color: 'primary.main', fontSize: 30, marginRight: '20px' }} />
        </Link>
        <Typography variant="h4">Add New Payable</Typography>
      </Box>
      <Box m={2}>
        <VendorSearch handleChange={handleVendorChange} />
      </Box>
      {loading ? (
        <CircularProgress />
      ) : vendor ? (
        <PayableForm initialPayableData={payable} onSubmit={handleSubmit} />
      ) : (
        <Box m={2}>
          <Typography variant="h6" sx={{ mb: 3 }}>Please select a vendor</Typography>
        </Box>
      )}
      <Snackbar
        open={error !== null}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddPayable;
