import { Document, Page, Text, View } from '@react-pdf/renderer';

const styles = {
  page: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleSection: {
    width: '100%',
    border: 1,
    margin: -0.5
  },
  title: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'left',
    padding: 2,
    fontWeight: 'bold'
  },
  topSection: {
    fontSize: 10,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    border: 1,
    margin: -0.5,
    height: 101
  },
  bottomSection: {
    fontSize: 10,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    border: 1,
    margin: -0.5,
    height: 440
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  cell: {
    fontSize: 10,
    border: 1,
    margin: -0.5,
    display: 'flex',
  },
  row16: {
    height: 16,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  row32: {
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  }
};

const CellView = ({ children, cellStyles }) => {
  return (
    <View style={[styles.cell, cellStyles]}>
      <Text style={{ padding: 1 }}>{children}</Text>
    </View>
  );

}

const DailyMachineSheetPDF = ({ orderInfo }) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.titleSection}>
          <Text style={styles.title}>Buckeye Hone Daily Machine Production</Text>
        </View>
        {/* Top Section */}
        <View style={styles.topSection}>
          <View style={[styles.column, { flex: 1.25 }]}>
            <CellView cellStyles={{ height: 36, justifyContent: 'flex-end', textAlign: 'right' }}>Machine #:</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center', textAlign: 'right' }}>SO#:</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center', textAlign: 'right' }}>Mill#:</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center', textAlign: 'right' }}>Customer:</CellView>
          </View>
          <View style={[styles.column, { flex: 1.5 }]}>
            <CellView cellStyles={{ height: 36, justifyContent: 'center' }}>{/* Blank */}</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center' }}>{orderInfo.salesOrderNumber || ''}</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center' }}>{orderInfo.millJobNumber || ''}</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center' }}>{orderInfo.customerName || ''}</CellView>
          </View>
          <View style={[styles.column, { flex: 4 }]} />
          <View style={[styles.column, { flex: .75 }]}>
            <CellView cellStyles={{ height: 36, justifyContent: 'flex-end', textAlign: 'right' }}>Bndls:</CellView>
            <CellView cellStyles={{ height: 36, justifyContent: 'flex-end', textAlign: 'right' }}>Tot Pcs:</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center', textAlign: 'right' }}>Tot Ftg:</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center', textAlign: 'right' }}>Tot Hrs:</CellView>
          </View>
          <View style={[styles.column, { flex: 1.25 }]}>
            <CellView cellStyles={{ height: 36, justifyContent: 'flex-end' }}>{orderInfo.bundleCount || ''}</CellView>
            <CellView cellStyles={{ height: 36, justifyContent: 'flex-end' }}>{orderInfo.totalPieces?.actual || ''}</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center' }}>{orderInfo.totalFeet?.actual || ''}</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center' }}>{orderInfo.totalHours || ''}</CellView>
          </View>
          <View style={[styles.column, { flex: .75 }]}>
            <CellView cellStyles={{ height: 36, justifyContent: 'flex-end', textAlign: 'right' }}>{/* Blank */}</CellView>
            <CellView cellStyles={{ height: 36, justifyContent: 'flex-end', textAlign: 'right' }}>Per Hr</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center', textAlign: 'right' }}>100%</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center', textAlign: 'right' }}>80%</CellView>
          </View>
          <View style={[styles.column, { flex: .75 }]}>
            <CellView cellStyles={{ height: 36, justifyContent: 'flex-end', textAlign: 'right' }}>Ftg</CellView>
            <CellView cellStyles={{ height: 36, justifyContent: 'flex-end', textAlign: 'right' }}>{orderInfo.feetPerHour || ''}</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center', textAlign: 'right' }}>{orderInfo.feetPerHour * 8 || ''}</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center', textAlign: 'right' }}>{(orderInfo.feetPerHour * 6.4) || ''}</CellView>
          </View>
          <View style={[styles.column, { flex: .75 }]}>
            <CellView cellStyles={{ height: 36, justifyContent: 'flex-end', textAlign: 'right' }}>Pcs</CellView>
            <CellView cellStyles={{ height: 36, justifyContent: 'flex-end', textAlign: 'right' }}>{(orderInfo.totalPieces?.actual / orderInfo.totalHours).toFixed(2) || ''}</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center', textAlign: 'right' }}>{((orderInfo.totalPieces?.actual / orderInfo.totalHours) * 8).toFixed(2) || ''}</CellView>
            <CellView cellStyles={{ height: 16, justifyContent: 'center', textAlign: 'right' }}>{((orderInfo.totalPieces?.actual / orderInfo.totalHours) * 6.4).toFixed(2) || ''}</CellView>
          </View>
          <View style={[styles.column, { flex: 2 }]} />
        </View>
        <View style={[styles.column, { height: 10, margin: -0.5, border: 1, width: '100%' }]} />

        {/* Table */}
        <View style={styles.bottomSection}>
          <View style={[styles.column, { flex: 1.2, backgroundColor: '#ffffcc' }]}>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, textAlign: 'center', flex: 1 }}>{/* Blank */}</CellView>
            </View>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, flex: 0.4, textAlign: 'center' }}>Date</CellView>
              <CellView cellStyles={{ height: 16, flex: 0.6, textAlign: 'center' }}>Employee</CellView>
            </View>
            {Array(13).fill(0).map((_, index) => (
              <View style={styles.row32} key={index}>
                <CellView cellStyles={{ flex: 0.4, textAlign: 'center' }}>{/* Blank */}</CellView>
                <CellView cellStyles={{ flex: 0.6, textAlign: 'center' }}>{/* Blank */}</CellView>
              </View>
            ))}
          </View>
          <View style={[styles.column, { flex: 1.5 }]}>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, textAlign: 'center', flex: 1 }}>Daily</CellView>
            </View>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Pcs</CellView>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Ftg</CellView>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Hrs</CellView>
            </View>
            {Array(13).fill(0).map((_, index) => (
              <View style={styles.row32} key={index}>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
              </View>
            ))}
          </View>
          <View style={[styles.column, { flex: 1.5, backgroundColor: '#ffffcc' }]}>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, textAlign: 'center', flex: 1 }}>Accumulative</CellView>
            </View>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Pcs</CellView>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Ftg</CellView>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Hrs</CellView>
            </View>
            {Array(13).fill(0).map((_, index) => (
              <View style={styles.row32} key={index}>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
              </View>
            ))}
          </View>
          <View style={[styles.column, { flex: 1.2 }]}>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, flex: .6 }}>{/* Blank */}</CellView>
              <CellView cellStyles={{ height: 16, flex: .4, textAlign: 'center', flex: 1 }}>Hrs</CellView>
            </View>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, flex: 0.6, textAlign: 'center' }}>Downtime</CellView>
              <CellView cellStyles={{ height: 16, flex: 0.4, textAlign: 'center' }}>Wrk</CellView>
            </View>
            {Array(13).fill(0).map((_, index) => (
              <View style={styles.row32} key={index}>
                <CellView cellStyles={{ flex: 0.6, textAlign: 'center' }}>{/* Blank */}</CellView>
                <CellView cellStyles={{ flex: 0.4, textAlign: 'center' }}>{/* Blank */}</CellView>
              </View>
            ))}
          </View>
          <View style={[styles.column, { flex: 1.5, backgroundColor: '#ffffcc' }]}>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, textAlign: 'center', flex: 1 }}>Rework</CellView>
            </View>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Emp</CellView>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Pcs</CellView>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Ftg</CellView>
            </View>
            {Array(13).fill(0).map((_, index) => (
              <View style={styles.row32} key={index}>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
              </View>
            ))}
          </View>
          <View style={[styles.column, { flex: 1.5 }]}>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, textAlign: 'center', flex: 1 }}>Oversize</CellView>
            </View>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Pcs</CellView>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Ftg</CellView>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Detail</CellView>
            </View>
            {Array(13).fill(0).map((_, index) => (
              <View style={styles.row32} key={index}>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
              </View>
            ))}
          </View>
          <View style={[styles.column, { flex: 1.5, backgroundColor: '#ffffcc' }]}>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, flex: 1 }}>{/* Blank */}</CellView>
              <CellView cellStyles={{ height: 16, flex: .5, textAlign: 'center' }}>Fore</CellView>
            </View>
            <View style={styles.row16}>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Scrap</CellView>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Bndl</CellView>
              <CellView cellStyles={{ height: 16, flex: 0.5, textAlign: 'center' }}>Man</CellView>
            </View>
            {Array(13).fill(0).map((_, index) => (
              <View style={styles.row32} key={index}>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
                <CellView cellStyles={{ flex: 0.5, textAlign: 'center' }}>{/* Blank */}</CellView>
              </View>
            ))}
          </View>
        </View>
        <View style={{ fontSize: 8, textAlign: 'right', width: '100%', border: 1 }}>
          <Text>F8.5.1-2(9-29-16)</Text>
        </View>
      </Page>
    </Document >
  )
};

export default DailyMachineSheetPDF;