import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Typography, Paper, Box, Button, Snackbar, Grid, TextField, Modal, IconButton, useTheme, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import dayjs from 'dayjs';
import { PDFViewer } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import OrdersByMonthPDF from '../../../pdf/OrdersByMonthPDF';
import { toJS } from 'mobx';
import { format } from 'date-fns';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const months = [
  { name: 'January', value: 1 },
  { name: 'February', value: 2 },
  { name: 'March', value: 3 },
  { name: 'April', value: 4 },
  { name: 'May', value: 5 },
  { name: 'June', value: 6 },
  { name: 'July', value: 7 },
  { name: 'August', value: 8 },
  { name: 'September', value: 9 },
  { name: 'October', value: 10 },
  { name: 'November', value: 11 },
  { name: 'December', value: 12 },
];

const OrdersByMonthReport = observer(() => {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [month, setMonth] = useState(1);
  const [year, setYear] = useState('');

  const theme = useTheme();

  useEffect(() => {
    const today = new Date();
    setMonth(today.getMonth() + 1);
    setYear(dayjs(today).format('YYYY'));
  }, [])

  const fetchOrdersByMonth = async (month, year) => {
    setLoading(true);
    ReportsStore.fetchOrdersByMonth(month, year).then(() => {
      setReport(ReportsStore.detailedReports.ordersByMonth);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch orders by month report', severity: 'error' });
      setLoading(false);
    });
  }

  const generateReports = () => {
    fetchOrdersByMonth(month, year);
  }

  const handleClose = () => {
    setViewPdf(false);
  }

  const columns = [
    { field: 'salesOrderNumber', headerName: 'Order No.', flex: 0.5 },
    { field: 'customerName', headerName: 'Customer Name', flex: 1 },
    { field: 'customerPONumber', headerName: 'PO No.', flex: 0.6 },
    {
      field: 'enteredDate',
      headerName: 'Date Entered',
      flex: .7,
      valueFormatter: (params) => format(new Date(params.value), 'MM/dd/yyyy')
    },
    { field: 'status', headerName: 'Status', flex: .5 },
    { field: 'millJobNumber', headerName: 'Mill No.', flex: 0.5 },
    {
      field: 'honeInnerDiameter',
      headerName: 'Hone ID',
      flex: .75,
      valueFormatter: (params) => `${params.value.lowerLimit?.toFixed(3) || 'N/A'}/${params.value.upperLimit?.toFixed(3) || 'N/A'}`
    },
    { field: 'tubeOuterDiameter', headerName: 'Tube OD', flex: .5, valueFormatter: (params) => params.value?.toFixed(3) || 'N/A'},
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: .7,
      valueFormatter: (params) => format(new Date(params.value), 'MM/dd/yyyy')
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: .7,
      valueFormatter: (params) => `$${params.value?.toFixed(2)}`
    },
  ];

  const CustomFooter = () => {
    const total = report?.result.reduce((sum, row) => sum + row.total, 0) || 0;
    const formattedTotal = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(total);
    return (
      <Box sx={{ padding: 2, textAlign: 'right' }}>
        <Typography variant="h6">Grand Total: {formattedTotal}</Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: 2 }}>
          <Typography variant="h4">Orders By Month Report</Typography>
        </Box>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Month</InputLabel>
              <Select
                value={month}
                onChange={(event) => setMonth(event.target.value)}
                label="Month"
              >
                {months.map((monthOption) => (
                  <MenuItem key={monthOption.value} value={monthOption.value}>
                    {monthOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Year"
              value={year}
              onChange={(event) => setYear(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
              <Button onClick={() => generateReports()} sx={{ width: '200px', fontWeight: 'bold' }}>Generate Report</Button>
              {report && (
                <Button onClick={() => setViewPdf(true)} sx={{ width: '200px' }}>View as PDF</Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 4 }}>
        {viewPdf ? (
          <Modal
            open={viewPdf}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
              <IconButton
                onClick={handleClose}
                sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
              >
                <CloseIcon />
              </IconButton>
              <PDFViewer width={'100%'} height={'100%'}>
                <OrdersByMonthPDF report={report} />
              </PDFViewer>
            </Paper>
          </Modal>
        ) : (
          <DataGridPro
            rows={report?.result || []}
            columns={columns}
            autoHeight
            loading={loading}
            getRowId={(row) => row.orderNumber}
            slots={{
              footer: CustomFooter,
            }}
          />
        )}
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default OrdersByMonthReport;
