import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 18,
    marginBottom: 10,
  },
  subheading: {
    fontSize: 14,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
});

const PayablesByAccountPDF = ({ report }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.heading}>Payables by Account Report</Text>
      </View>

      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { width: '10%' }]}>Payables ID</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Amount (Words)</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Invoice Amount</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Paid Amount</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Check No</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Date Paid</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Date Received</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Due Date</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Expense Code</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>External Comments</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Internal Comments</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Entered By</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Enter Date</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Invoice Date</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Invoice No</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Pay This</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Vendor</Text>
            <Text style={[styles.tableCell, { width: '10%' }]}>Void</Text>
          </View>
          {report.data.map((entry, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={[styles.tableCell, { width: '10%' }]}>{entry.payablesId}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{entry.amountWords}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{entry.invoiceAmount}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{entry.paidAmount}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{entry.checkNo}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{dayjs(entry.datePaid).format('MM/DD/YYYY')}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{dayjs(entry.dateRecd).format('MM/DD/YYYY')}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{dayjs(entry.dueDate).format('MM/DD/YYYY')}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{entry.expenseCode}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{entry.commentsExternal}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{entry.commentsInternal}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{entry.enterBy}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{dayjs(entry.enterDate).format('MM/DD/YYYY')}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{dayjs(entry.invoiceDate).format('MM/DD/YYYY')}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{entry.invoiceNo}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{entry.payThis ? 'Yes' : 'No'}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{entry.vendor}</Text>
              <Text style={[styles.tableCell, { width: '10%' }]}>{entry.void ? 'Yes' : 'No'}</Text>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default PayablesByAccountPDF;
