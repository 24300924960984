import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 12,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  header: {
    fontSize: 24,
    marginBottom: 10,
  },
  employeeName: {
    fontSize: 16,
    marginBottom: 5,
  },
  monthYear: {
    fontSize: 14,
    marginBottom: 5,
  },
  day: {
    marginBottom: 5,
  },
  total: {
    marginTop: 5,
    fontWeight: 'bold',
  },
  separator: {
    margin: 10,
    height: 1,
    backgroundColor: '#000',
  }
});

// Create Document Component
const EmployeeAttendancePDF = ({ report }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Employee Attendance Report</Text>
        {report.map((employee, index) => (
          <View key={index}>
            <Text style={styles.employeeName}>{employee.name}</Text>
            {employee.attendance.map((monthData, monthIndex) => (
              <View key={monthIndex}>
                <Text style={styles.monthYear}>Month: {monthData.month}/{monthData.year}</Text>
                {monthData.days.map((day, dayIndex) => (
                  <View key={dayIndex} style={styles.day}>
                    <Text>Date: {dayjs(day.date).format('MM/DD/YYYY')}</Text>
                    <Text>Hours: {day.hours}</Text>
                    <Text>Comments: {day.comments}</Text>
                  </View>
                ))}
                <Text style={styles.total}>Monthly Total: {monthData.monthlyTotal}</Text>
                <View style={styles.separator} />
              </View>
            ))}
            <Text style={styles.total}>Period Total: {employee.periodTotal}</Text>
            <View style={styles.separator} />
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export default EmployeeAttendancePDF;
