import { makeAutoObservable } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class AccountCodeStore {
  paginatedAccountCodes = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: [],
  };

  async fetchPaginatedAccountCodes(
    page = 1,
    pageSize = 10,
    sortBy = 'accountCode',
    filter = {},
    search = '',
    searchField = 'accountCode'
  ) {
    try {
      const { results, ...pagination } = await apiHandler.get(
        `/accountCodes?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`
      );
      // Assuming the actual customer data is in the response body
      this.paginatedAccountCodes = { ...pagination, results: results };
      return { results, ...pagination };
    } catch (error) {
      console.error("Error fetching paginated customers:", error);
      throw error;
    }
  };
}

export default new AccountCodeStore();