import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, Button, IconButton, List, Snackbar, TextField, Divider, ListItemText, ListItemButton, TablePagination, Typography } from '@mui/material';
import DynamicSearchFilter from '../../components/DynamicSearchFilter';
import ShopOrdersStore from '../../stores/ShopOrdersStore';
import MuiAlert from '@mui/material/Alert';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import JobStore from '../../stores/JobStore';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Production = observer(() => {
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOrders, setShowOrders] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [firstPass, setFirstPass] = useState(true);
  const [orderJobs, setOrderJobs] = useState([]);
  const [pieces, setPieces] = useState(0);
  const [hours, setHours] = useState(0);
  const [shopOrders, setShopOrders] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 5,
    totalPages: 0,
    results: []
  });

  useEffect(() => {
    if (firstPass) {
      setFirstPass(false);
      return;
    }
    fetchPaginatedShopOrders(page, pageSize, '-enteredDate', filter, search, 'salesOrderNumber');
  }, [filter, search, page, pageSize]);

  const handleDynamicSearch = (search, filter, sortBy) => {
    let filterBuilder = { 
      ...filter,
      machine: { $ne: null }
    };

    let keys = Object.keys(filter);
    if (keys.length > 0) {
      if (filter.dueDateFrom || filter.dueDateTo) {
        filterBuilder.dueDate = {};
        if (filter.dueDateFrom) {
          filterBuilder.dueDate.$gte = filter.dueDateFrom;
        }
        if (filter.dueDateTo) {
          filterBuilder.dueDate.$lte = filter.dueDateTo;
        }
      }
      if (filter.enteredDateFrom || filter.enteredDateTo) {
        filterBuilder.enteredDate = {};
        if (filter.enteredDateFrom) {
          filterBuilder.enteredDate.$gte = filter.enteredDateFrom;
        }
        if (filter.enteredDateTo) {
          filterBuilder.enteredDate.$lte = filter.enteredDateTo;
        }
      }
      if (filter.shipDateFrom || filter.shipDateTo) {
        filterBuilder.shipDate = {};
        if (filter.shipDateFrom) {
          filterBuilder.shipDate.$gte = filter.shipDateFrom;
        }
        if (filter.shipDateTo) {
          filterBuilder.shipDate.$lte = filter.shipDateTo;
        }
      }
      if (filter.total?.min || filter.total?.max) {
        filterBuilder.total = {};
        if (filter.total.min) filterBuilder.total.$gte = filter.total.min;
        if (filter.total.max) filterBuilder.total.$lte = filter.total.max;
      }
    }

    setFilter(filterBuilder);
    setSearch(search);
  };

  const fetchPaginatedShopOrders = async (page = 1, pageSize = 5, sortBy = '-enteredDate', filter = {}, search = '', searchField = 'salesOrderNumber') => {
    setLoading(true);
    ShopOrdersStore.fetchPaginatedShopOrders(page, pageSize, sortBy, filter, search, searchField).then(() => {
      setShopOrders(ShopOrdersStore.paginatedShopOrders);
      setLoading(false);
      setShowOrders(true);
    }).catch(error => {
      if (error.response?.data?.message && error.response.data.message === 'No documents found.') {
        setShopOrders({ hasMore: false, totalCount: 0, currentPage: 0, pageSize: 5, totalPages: 0, results: [] });
        setShowOrders(true);
        setLoading(false);
        setSnackbar({ open: true, message: 'No orders found', severity: 'info' });
        return;
      }
      setShowOrders(false);
      setSnackbar({ open: true, message: 'Failed to fetch shop orders', severity: 'error' });
      setLoading(false);
    })
  }

  const handleSelectOrder = (order) => {
    setShowOrders(false);

    JobStore.fetchPaginatedJobs(1, 20, '-enteredDate', { orderId: order._id }, '', 'salesOrderNumber').then(() => {
      const jobs = JobStore.paginatedJobs.results.filter(job => job.workType !== "S");
      setHours(jobs.reduce((acc, job) => acc + job.hours, 0));
      setPieces(jobs.reduce((acc, job) => acc + job.pieces, 0));
      setOrderJobs(jobs);
    }).catch(error => {
      console.error("Error fetching jobs for order:", error);
      setSnackbar({ open: true, message: 'Failed to fetch jobs for order', severity: 'error' });
    });

    setSelectedOrder(order);
  };

  const handleSave = async () => {
    const newJob = {
      workType: 'PR',
      orderId: selectedOrder._id,
      jobDate: new Date(),
      hours: hours,
      pieces: pieces,
      machineId: selectedOrder.machine,
      orderNumber: selectedOrder.orderNumber,
    }

    try {
      await JobStore.createJob(newJob);
    } catch (error) {
      console.error("Error creating job:", error);
      setSnackbar({ open: true, message: 'Failed to create job', severity: 'error' });
    }

    JobStore.fetchPaginatedJobs(1, 20, '-enteredDate', { orderId: selectedOrder._id }, '', 'salesOrderNumber').then(() => {
      const jobs = JobStore.paginatedJobs.results.filter(job => job.workType !== "S");
      setHours(jobs.reduce((acc, job) => acc + job.hours, 0));
      setPieces(jobs.reduce((acc, job) => acc + job.pieces, 0));
      setOrderJobs(jobs);
    }).catch(error => {
      console.error("Error fetching jobs for order:", error);
      setSnackbar({ open: true, message: 'Failed to fetch jobs for order', severity: 'error' });
    });
  }

  const EditableSequenceCell = ({ value, onChange }) => {
    const [editingValue, setEditingValue] = useState(value);

    const handleChange = (event) => {
      setEditingValue(event.target.value);
    };

    const handleBlur = () => {
      onChange(editingValue);
    };

    return (
      <TextField
        value={editingValue}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        size="small"
      />
    );
  };

  const CustomFooter = () => {
    return (
      <div style={{ textAlign: 'center', padding: '10px' }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    );
  }

  const objectInterface = {
    orderNumber: '',
    customerPONumber: '',
    millJobNumber: '',
    customer: '',
    status: '',
    paymentStatus: '',
    dueDate: new Date(),
    enteredDate: new Date(),
    shipDate: new Date(),
    total: { min: 0, max: 0 },
    isCancelled: false
  };

  const columns = [
    {
      field: 'id',
      headerName: '',
      flex: .5,
      disableColumnMenu: true,
      disableColumnFilter: true,
      sortable: false,
      align: 'right'
    },
    {
      field: 'pieces',
      headerName: 'Pieces',
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <EditableSequenceCell
          value={params.value}
          onChange={(newValue) => {
            setPieces(newValue);
          }}
        />
      )
    },
    {
      field: 'hours',
      headerName: 'Hours',
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <EditableSequenceCell
          value={params.value}
          onChange={(newValue) => {
            setHours(newValue);
          }}
        />
      )
    },
  ];

  const rows = [
    { id: 'Complete: ', pieces: orderJobs.reduce((acc, job) => acc + job.pieces, 0), hours: orderJobs.reduce((acc, job) => acc + job.hours, 0) },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
      <Box sx={{ width: '100%' }}>
        <DynamicSearchFilter
          defaultSort="-enteredDate"
          onSearch={handleDynamicSearch}
          objectInterface={objectInterface}
          label="Search SO#"
        />
      </Box>
      <Box sx={{ width: '100%' }}>
        {showOrders && shopOrders.results.length > 0 && (
          <>
            <List>
              {shopOrders.results.map((order) => (
                <Box key={order._id}>
                  <ListItemButton onClick={() => handleSelectOrder(order)}>
                    <ListItemText
                      primary={`Order: ${order.salesOrderNumber}`}
                      secondary={`Customer: ${order.customer.companyName || 'N/A'} 
                  | Mill No: ${order.millJobNumber}`}
                      secondaryTypographyProps={{ variant: 'body2', margin: '-2px' }}
                    />
                  </ListItemButton>
                  <Divider sx={{ width: '100%' }} />
                </Box>
              ))}
            </List>
            <TablePagination
              component="div"
              count={shopOrders.totalCount}
              page={shopOrders.currentPage - 1}
              onPageChange={(event, page) => setPage(page + 1)}
              rowsPerPage={shopOrders.pageSize}
              onRowsPerPageChange={(event) => {
                setPageSize(event.target.value);
                setPage(1);
              }}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </>
        )}

      </Box>
      {shopOrders.results.length > 0 && (
        <IconButton onClick={() => setShowOrders(!showOrders)} sx={{ marginBottom: 8 }}>
          <ArrowToggle open={showOrders} />
        </IconButton>
      )}
      <Box sx={{ height: 200, width: '100%' }}>
        {selectedOrder && (
          <Typography variant="h6" sx={{ marginBottom: 2 }} >
            Order: {selectedOrder.salesOrderNumber}
          </Typography>
        )}
        <DataGridPro
          rows={rows}
          columns={columns}
          slots={{
            footer: CustomFooter
          }}
        />
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ open: false, message: '', severity: 'info' })} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbar({ open: false, message: '', severity: 'info' })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
});

const ArrowToggle = ({ open }) => {
  return open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;
};

export default Production;
