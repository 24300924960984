import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import EmployeeStore from '../stores/EmployeeStore';
import DynamicSearchFilter from '../components/DynamicSearchFilter';
import {
  Box, Typography, List, ListItem, ListItemText, Paper,
  Button, CircularProgress, Snackbar, IconButton, ListItemSecondaryAction,
  TablePagination, useMediaQuery
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const filterObject = {
  firstName: '',
  lastName: '',
  email: '',
};

const Employee = observer(() => {
  const [employees, setEmployees] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({});
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('lg'));

  useEffect(() => {
    fetchPaginatedEmployees();
  }, []);

  const fetchPaginatedEmployees = async (page = 1, pageSize = 10, sortBy = 'firstName', filter = filterCriteria, search = searchQuery) => {
    setLoading(true);
    EmployeeStore.fetchPaginatedEmployees(page, pageSize, sortBy, filter, search).then(() => {
      setEmployees(EmployeeStore.paginatedEmployees);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch employees', severity: 'error' });
      setLoading(false);
    });
  };

  const handleViewEmployee = (employeeId) => {
    navigate(`/employee/${employeeId}`);
  };

  const handleEditEmployee = (employeeId) => {
    navigate(`/employee/edit/${employeeId}`);
  }

  const handleCreateEmployee = () => {
    navigate('/employee/add');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePageChange = (event, page) => {
    fetchPaginatedEmployees(page + 1, employees.pageSize);
  };

  const handleLimitChange = (event) => {
    fetchPaginatedEmployees(1, event.target.value);
  };

  const handleDynamicSearch = (search, filter, sortBy) => {
    setSearchQuery(search);
    setFilterCriteria(filter);
    fetchPaginatedEmployees(1, employees.pageSize, sortBy, filter, search);
  };

  const columns = [
    { field: 'firstName', headerName: 'First Name', flex: 1 },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: .5,
      align: 'right',
      renderCell: (params) =>
      <>
        <Button variant="outlined" sx={{ marginRight: '50px' }} onClick={() => handleViewEmployee(params.row._id)}>View</Button>
        <EditIcon onClick={() => handleEditEmployee(params.row._id)} />
      </>
    }
  ];

  const renderEmptyState = () => (
    <Box sx={{ textAlign: 'center', mt: 4 }}>
      <InfoIcon color="disabled" sx={{ fontSize: 60 }} />
      <Typography variant="subtitle1">No employees available, create a new employee by...</Typography>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" gutterBottom>Employees</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <DynamicSearchFilter
            defaultSort="firstName"
            onSearch={handleDynamicSearch}
            objectInterface={filterObject}
          />
          <IconButton color="primary" onClick={handleCreateEmployee}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      {employees.totalCount !== 0 ? (
        <>
          {isLargeScreen ? (
            <DataGridPro
              rows={employees.results}
              columns={columns}
              pageSize={employees.pageSize}
              rowCount={employees.totalCount}
              loading={loading}
              autoHeight
              getRowId={(row) => row._id}
            />
          ) : (
            <Paper sx={{ p: 2 }}>
              <List>
                {employees.results.map((employee, index) => (
                  <ListItem sx={{ m: 2 }} key={index}>
                    <ListItemText primary={`${employee.firstName || ''} ${employee.lastName || ''} ${employee.email || ''}`} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="edit" onClick={() => handleEditEmployee(employee._id)}>
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
          <TablePagination
            component="div"
            count={employees.totalCount}
            page={employees.currentPage > 0 ? employees.currentPage - 1 : 0}
            onPageChange={handlePageChange}
            rowsPerPage={employees.pageSize}
            onRowsPerPageChange={handleLimitChange}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </>
      ) : (
        renderEmptyState()
      )}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default Employee;
