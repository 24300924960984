import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import PayablesStore from '../../stores/PayablesStore';
import PayableForm from './PayableForm';
import { Box, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const EditPayable = () => {
    const { payableId } = useParams();
    const navigate = useNavigate();
    const [payable, setPayable] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        const fetchPayable = async () => {
            try {
                await PayablesStore.fetchPayableById(payableId);
                setPayable(PayablesStore.payable);
            } catch (error) {
                setError('Failed to fetch payable');
            } finally {
                setLoading(false);
            }
        };

        fetchPayable();
    }, [payableId]);

    const handleSubmit = async (updatedPayable) => {
        try {
            setLoading(true);
            await PayablesStore.updatePayable(payableId, updatedPayable);
            navigate('/payables');
        } catch (error) {
            setError('Failed to update payable');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            await PayablesStore.deletePayable(payableId);
            navigate('/payables');
        } catch (error) {
            setError('Failed to delete payable');
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setError(null);
    };

    return (
        <Box sx={{ m: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 2 }}>
                <Link to="/payables" style={{ textDecoration: 'none' }}>
                    <ArrowBackIcon sx={{ color: 'primary.main', fontSize: 30, marginRight: '20px' }} />
                </Link>
                <Typography variant="h4">Edit Payable</Typography>
            </Box>
            {loading ? (
                <CircularProgress />
            ) : (
                payable ? (
                    <PayableForm initialPayableData={payable} onSubmit={handleSubmit} onDelete={handleDelete} />
                ) : (
                    <Typography variant="body1">Payable not found</Typography>
                )
            )}
            <Snackbar open={error !== null} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default EditPayable;
