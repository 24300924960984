import React, { useState, useEffect } from "react";
import { Grid, TextField, Autocomplete, Button, Modal, Box, Typography, CircularProgress } from "@mui/material";
import AccountCodeStore from "../../stores/AccountCodeStore";
import { debounce } from "lodash";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '80%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  overflowY: 'scroll',
  p: 4,
};

const AccountCodeSearch = ({ margin = 0, handleChange, marginTop, showInactive = false }) => {

  const [modalState, setModalState] = useState(false);
  const [accountCodes, setAccountCodes] = useState({
    results: [],
    totalCount: 0,
  });
  const [open, setOpen] = useState(false);
  const loading = open && accountCodes.totalCount === 0;

  const handleInputChange = debounce((event, value, reason) => {
    const numOptions = accountCodes.results.filter((accountCode) =>
      accountCode.accountCode.toLowerCase().includes(value.toLowerCase())
    );
    if (value.length > 0 && numOptions.length < 10) {
      const filter = {
        $or: [
          { accountCode: { $regex: value, $options: 'i' } },
          { accountDescription: { $regex: value, $options: 'i' } },
          { accountBase: { $regex: value, $options: 'i' } },
          { acctCodeId: { $regex: value, $options: 'i' } }
        ]
      };

      getPaginatedAccountCodes(1, 20, 'accountCode', filter, '', 'accountCode')
    }
  }, 500);

  const getPaginatedAccountCodes = async (page, pageSize, sortBy, filter, search, searchField) => {
    const activeFilter = {
      ...filter,
      isActive: true
    }
    const usedFilter = showInactive ? filter : activeFilter;
    try {
      await AccountCodeStore.fetchPaginatedAccountCodes(page, pageSize, sortBy, usedFilter, search, searchField);
      setAccountCodes(AccountCodeStore.paginatedAccountCodes);
    } catch (error) {
      console.error('Failed to fetch account codes:', error);
    }
  };

  useEffect(() => {
    let active = true;

    let page = 1;
    let pageSize = 20;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        await getPaginatedAccountCodes(
          page,
          pageSize,
          'accountCode',
          {},
          '',
          'accountCode'
        );
        if (active) {
          setAccountCodes(AccountCodeStore.paginatedAccountCodes);
        }
      } catch (error) {
        console.error('Failed to fetch account codes:', error);
      }
    }
    )();

  }, [loading]);

  useEffect(() => {
    if (!open) {
      setAccountCodes({
        results: [],
        totalCount: 0,
      });
    }
  }, [open]);

  return (
    <>
      <Modal
        open={modalState}
        onClose={() => {
          setModalState(!modalState);
        }}
      >
        <Box sx={style}>
          <Typography variant="h4" sx={{ mb: 2 }}>Add New Account Code</Typography>
        </Box>
      </Modal>
      <Grid container margin={margin} marginTop={(marginTop || marginTop === 0) ? marginTop : (margin + 3)}>
        {accountCodes ? (
          <>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                fullWidth
                id="accountCode"
                getOptionLabel={(option) => `${option.acctCodeId} - ${option.accountCode} - ${option.accountDescription}`}
                options={accountCodes.results}
                onChange={(event, newValue) => {
                  handleChange(newValue);
                }}
                onInputChange={handleInputChange}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                loading={loading}
                isOptionEqualToValue={(option, value) => {
                  return option._id === value._id;
                }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Search Account Code"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                }
              />
            </Grid>
          </>
        ) : (
          <>
            Loading Account Codes...
          </>
        )}
      </Grid>
    </>
  )
};

export default AccountCodeSearch;
