import { Document, Page, Text, View } from '@react-pdf/renderer';

const styles = {
  page: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleSection: {
    marginBottom: 20,
    width: '60%'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    marginBottom: 10,
    width: '60%'
  },
  columnHeader: {
    width: '25%',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12,
  },
  cell: {
    width: '25%',
    fontSize: 10,
    textAlign: 'center',
  },
  lastColumn: {
    borderRightWidth: 0,
  },
  tableBorder: {
    borderTopWidth: 1,
    borderTopColor: '#000',
  },
};

const SalesReceivablesPDF = ({ report }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.titleSection}>
                    <Text key={'title'} style={styles.title}>Buckeye Hone Outstanding Receivables</Text>
                </View>
                {/* Table */}
                <View>
                    <View style={styles.tableContainer}>
                        <Text style={styles.columnHeader}>Amount Paid</Text>
                        <Text style={styles.columnHeader}>Check Amount</Text>
                        <Text style={styles.columnHeader}>Check Number</Text>
                        <Text style={styles.columnHeader}>Invoice Number</Text>
                        <Text style={styles.columnHeader}>Customer</Text>
                        <Text style={styles.columnHeader}>Invoice Date</Text>
                        <Text style={styles.columnHeader}>Payment Date</Text>
                    </View>
                    {report.receivables.map((rec, index) => (
                        <View key={index} style={styles.tableContainer}>
                            <Text style={styles.cell}>${rec.amtPaid.toFixed(2)}</Text>
                            <Text style={styles.cell}>${rec.checkAmt.toFixed(2)}</Text>
                            <Text style={styles.cell}>{rec.checkNo}</Text>
                            <Text style={styles.cell}>{rec.invoice.invoiceNumber}</Text>
                            <Text style={styles.cell}>{rec.invoice.customer.companyAbbreviation}</Text>
                            <Text style={styles.cell}>{rec.invoice.invoiceDate}</Text>
                            <Text style={[styles.cell, styles.lastColumn]}>{rec.invoice.paymentDate}</Text>
                        </View>
                    ))}
                    <View style={styles.tableContainer}>
                        {/* Intentionally left blank */}
                    </View>
                </View>
                <View style={styles.tableBorder} />
            </Page>
        </Document>
    )
}

export default SalesReceivablesPDF;