import { useState, useEffect } from "react";
import { Box, Typography, Snackbar, Alert, TextField, Button, IconButton, Paper, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
import { DatePicker } from "@mui/x-date-pickers";
import { useSearchParams } from "react-router-dom";
import PayrollStore from "../../stores/PayrollStore";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import JobStore from "../../stores/JobStore";

import EmployeeSearch from "../../components/search/EmployeeSearch";
import DataContainer from "../../components/DataContainer";
import EmployeeStore from "../../stores/EmployeeStore";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE);

const CustomFooterComponent = ({ total, label }) => {
  return (
    <Grid container>
      <Grid item xs={10}>
        <Typography align="right" variant="h6" m={2}>{label}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography align="right" variant="h6" m={2}>{total}</Typography>
      </Grid>
    </Grid>
  )
};


const CalculationTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialEmployeeId = searchParams.get('id') || '';
  const [employee, setEmployee] = useState(initialEmployeeId);
  const [payrollStartDate, setPayrollStartDate] = useState(new Date().setDate(new Date().getDate() - new Date().getDay()));
  const [payrollEndDate, setPayrollEndDate] = useState(new Date().setDate(new Date().getDate() + (6 - new Date().getDay())));
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isLoading, setIsLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [payHours, setPayHours] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [totalHours, setTotalHours] = useState(0);
  const [basePay, setBasePay] = useState(0);
  const [jobs, setJobs] = useState({
    results: [],
    totalCount: 0
  });
  const [reworkedJobs, setReworkedJobs] = useState([]);
  const [productionTime, setProductionTime] = useState(0);
  const [downTime, setDownTime] = useState(0);
  const [reworkTime, setReworkTime] = useState(0);
  const [incentiveTotal, setIncentiveTotal] = useState(0);
  const [incentiveHour, setIncentiveHour] = useState(0);
  const [incBonusHour, setIncBonusHour] = useState(0);
  const [incentive, setIncentive] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [bonusRate, setBonusRate] = useState(0);
  const [attend, setAttend] = useState(0);
  const [quality, setQuality] = useState(0);
  const [other, setOther] = useState(0);
  const [vacation, setVacation] = useState(0);
  const [holiday, setHoliday] = useState(0);
  const [productionAmount, setProductionAmount] = useState(0);
  const [reworkAdd, setReworkAdd] = useState(0);
  const [reworkSub, setReworkSub] = useState(0);
  const [downTimeTotal, setDownTimeTotal] = useState(0);
  const [scrapTotal, setScrapTotal] = useState(0);
  const [jobRate, setJobRate] = useState(0);
  const [totalPay, setTotalPay] = useState(0);

  useEffect(() => {
    if (employee) {
      let total = +basePay + +attend + +quality + +other + +vacation + +holiday;
      if (employee.fringeBenefits3Mo && (incentive > 0)) {
        total += incentive;
      }
      if (employee.fringeBenefits6Mo && (bonus > 0)) {
        total += bonus;
      }
      setTotalPay(total);
    } else {
      setTotalPay(0);
    }
  }, [attend, quality, other, vacation, holiday, basePay, employee, incentive, bonus]);

  const handleDateChange = (name, date) => {
    let startDate = payrollStartDate;
    let endDate = payrollEndDate;

    const startOfWeek = new Date(new Date(date).setDate(new Date(date).getDate() - new Date(date).getDay()));
    const endOfWeek = new Date(new Date(date).setDate(new Date(date).getDate() + (6 - new Date(date).getDay())));

    setPayrollStartDate(startOfWeek);
    setPayrollEndDate(endOfWeek);

    startDate = startOfWeek;
    endDate = endOfWeek;

    handleEmployeeChange(employee, startDate, endDate);
  };

  const handleEmployeeChange = async (event, startDate = payrollStartDate, endDate = payrollEndDate) => {
    let newEmployeeId = '';

    if (/[0-9,a-z,A-Z]{24}/.test()) {
      newEmployeeId = event;
      const employee = await EmployeeStore.fetchEmployeeById(newEmployeeId);
      setEmployee(employee);
      setSearchParams({ id: newEmployeeId });
      getEmployeeInfo(employee, startDate, endDate);
    } else if (event) {
      newEmployeeId = event._id;
      setEmployee(event);
      setSearchParams({ id: newEmployeeId });
      getEmployeeInfo(event, startDate, endDate);
    } else {
      setEmployee('');
      setSearchParams({});

      // Reset related data to their initial state
      setTotalHours(0);
      setPayHours({ results: [], totalCount: 0 });
      setJobs({ results: [], totalCount: 0 });
      setReworkedJobs([]);
      setProductionTime(0);
      setDownTime(0);
      setReworkTime(0);
      setIncentiveTotal(0);
      setIncentiveHour(0);
      setIncBonusHour(0);
      setIncentive(0);
      setBonus(0);
      setBonusRate(0);
      setBasePay(0);
      setProductionAmount(0);
      setReworkAdd(0);
      setReworkSub(0);
      setDownTimeTotal(0);
      setScrapTotal(0);
      setJobRate(0);
      setTotalPay(0);
    }
  };


  const getEmployeeInfo = async (employee, startDate, endDate) => {

    try {
      const jobFilter = {
        employeeId: employee._id,
        jobDate: {
          $gte: startDate,
          $lte: endDate
        }
      }

      const reworkFilter = {
        reworkEmpId: employee._id,
        jobDate: {
          $gte: startDate,
          $lte: endDate
        }
      }

      const payHourFilter = {
        employee: employee._id,
        workDate: {
          $gte: startDate,
          $lte: endDate
        }
      }

      let payHourTotal = 0;
      let basePay = 0;

      try {
        const payHours = await PayrollStore.fetchPaginatedPayHours(1, 50, '', payHourFilter, '', '');
        payHourTotal = payHours ? payHours.results.reduce((acc, cur) => acc + cur.hours, 0) : 0;
        if (payHours) {
          setPayHours(payHours);
          setTotalHours(payHourTotal);
          basePay = payHourTotal > 40 ? (employee.currentHourlyRate * 40) + ((payHourTotal - 40) * employee.currentOTRate) :
            employee.currentHourlyRate * payHourTotal;
        } else {
          setPayHours({ results: [], totalCount: 0 });
          setTotalHours(0);
        }
        setBasePay(basePay);
      } catch (error) {
        if (error.response && error.response.data.message === 'No documents found.') {
          setPayHours({ results: [], totalCount: 0 });
          setTotalHours(0);
          setBasePay(0);
        } else {
          setSnackbar({ open: true, message: 'Error fetching pay hours', severity: 'error' });
        }
      }

      const rework = await JobStore.fetchPaginatedJobs(1, 50, '', reworkFilter, '', '');
      if (rework) {
        const reworked = rework.results.map(job => {
          return {
            ...job,
            reworked: true
          }
        })
        setReworkedJobs(reworked);
      } else {
        setReworkedJobs([]);
      }

      const res = await JobStore.fetchPaginatedJobs(1, 50, '', jobFilter, '', '');
      if (res) {
        setJobs(res);
        setProductionTime(res.results.reduce((acc, cur) => acc + (cur.workType === 'P' ? cur.hours : 0), 0));
        setDownTime(res.results.reduce((acc, cur) => acc + (cur.workType === 'D' ? cur.hours : 0), 0));
        setReworkTime(res.results.reduce((acc, cur) => acc + (cur.workType === 'R' ? cur.hours : 0), 0));

        // Setting to consts as we need values for other calculations
        const prodAmt = res.results.reduce((acc, cur) => acc + (cur.workType === 'P' ? cur.orderId.rate * cur.feet : 0), 0);
        const rwrkAdd = res.results.reduce((acc, cur) => acc + (cur.workType === 'R' ? cur.orderId.rate * cur.feet : 0), 0);
        const downAmt = res.results.reduce((acc, cur) => acc + (cur.workType === 'D' ? cur.hours * employee.currentHourlyRate : 0), 0);
        const scrapAmt = res.results.reduce((acc, cur) => acc + (cur.workType === 'S' ? cur.orderId.rate * cur.feet : 0), 0);
        const rwrkSub = rework ? rework.results.reduce((acc, cur) => acc + (cur.orderId.rate * cur.feet), 0) : 0;

        setProductionAmount(prodAmt);
        setReworkAdd(rwrkAdd);
        setReworkSub(rwrkSub);
        setDownTimeTotal(downAmt);
        setScrapTotal(scrapAmt);

        const incentiveTotal = prodAmt + rwrkAdd - rwrkSub - downAmt - scrapAmt;
        setIncentiveTotal(incentiveTotal);
        const incentiveHour = incentiveTotal / payHourTotal;
        setIncentiveHour(incentiveHour);

        if (employee.fringeBenefits3Mo) {
          setIncentive(incentiveTotal - basePay);
        } else {
          setIncentive(0);
        }

        const incBaseDif = incentiveHour - employee.currentHourlyRate;

        const bonusRate = incBaseDif > 0.25 && incBaseDif <= 0.5 ? 1 :
          incBaseDif > 0.5 && incBaseDif <= 1.0 ? 1.5 :
            incBaseDif > 1.0 ? 2 : 0;

        setBonusRate(bonusRate);

        if (employee.fringeBenefits6Mo && (incentiveTotal - basePay > 0)) {
          setBonus(payHourTotal * bonusRate);
        } else {
          setBonus(0);
        }

        const incBonusHour = incentiveHour + bonusRate;
        setIncBonusHour(incBonusHour);

        const jobRate = employee.currentHourlyRate + bonusRate;
        setJobRate(jobRate);

      } else {
        setJobs({ results: [], totalCount: 0 });
        setProductionTime(0);
        setDownTime(0);
        setReworkTime(0);
        setIncentiveTotal(0);
        setIncentiveHour(0);
        setIncBonusHour(0);
        setIncentive(0);
      }
    } catch (error) {
      if (error.response && error.response.data.message === 'No documents found.') {
        setJobs({ results: [], totalCount: 0 });
      }
      else {
        setSnackbar({ open: true, message: 'Error fetching jobs', severity: 'error' });
      }
    }
    return;
  };

  const handleDelete = async (id) => {
    const res = await PayrollStore.deletePayHour(id);
    if (res) {
      setSnackbar({ open: true, message: 'Pay Hour deleted', severity: 'success' });
    } else {
      setSnackbar({ open: true, message: 'Error deleting pay hour', severity: 'error' });
    }
  };

  const handleSave = async () => {
    const payAmount = {
      employee: employee._id,
      payStartDate: payrollStartDate,
      payEndDate: payrollEndDate,
      basePay: +basePay,
      regularHours: totalHours > 40 ? 40 : totalHours,
      overtimeHours: totalHours > 40 ? totalHours - 40 : 0,
      vacationPay: +vacation,
      holidayPay: +holiday,
      incentivePay: +incentive,
      bonusPay: +bonus,
      attendancePay: +attend,
      qualityPay: +quality,
      otherPay: +other,
      totalPay: +totalPay,
    }

    const newPayAmount = await PayrollStore.createPayAmount(payAmount);
    if (newPayAmount) {
      setSnackbar({ open: true, message: 'Pay Amount saved', severity: 'success' });
    } else {
      setSnackbar({ open: true, message: 'Error saving pay amount', severity: 'error' });
    }
  }

  const hoursColumns = [
    { field: 'workDate', headerName: 'Date', flex: 1, valueFormatter: (params) => new Date(params.value).toLocaleDateString() },
    { field: 'hours', headerName: 'Hours', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      flex: .5,
      renderCell: (params) => (
        <IconButton
          color="error"
          onClick={() => handleDelete(params.row.id)}
        >
          <DeleteIcon />
        </IconButton>
      )
    }
  ];

  const totalPayColumns = [
    {
      field: 'incentive', headerName: 'Incentive', flex: 1, renderCell: () => {
        return <TextField disabled sx={{ m: -1 }} value={incentive > 0 ? `$${incentive.toFixed(2)}` : `($${basePay.toFixed(2)})`} onChange={(e) => setIncentive(e.target.value)} />
      }
    },
    {
      field: 'bonus', headerName: 'Bonus', flex: 1, renderCell: () => {
        return <TextField disabled sx={{ m: -1 }} value={`$${bonus.toFixed(2)}`} onChange={(e) => setBonus(e.target.value)} />
      }
    },
    {
      field: 'attend', headerName: 'Attend', flex: 1, renderCell: () => {
        return <TextField type="number" sx={{ m: -1 }} value={attend} onChange={(e) => setAttend(e.target.value)} />
      }
    },
    {
      field: 'quality', headerName: 'Quality', flex: 1, renderCell: () => {
        return <TextField type="number" sx={{ m: -1 }} value={quality} onChange={(e) => setQuality(e.target.value)} />
      }
    },
    {
      field: 'other', headerName: 'Other', flex: 1, renderCell: () => {
        return <TextField type="number" sx={{ m: -1 }} value={other} onChange={(e) => setOther(e.target.value)} />
      }
    },
    {
      field: 'vacation', headerName: 'Vacation', flex: 1, renderCell: () => {
        return <TextField type="number" sx={{ m: -1 }} value={vacation} onChange={(e) => setVacation(e.target.value)} />
      }
    },
    {
      field: 'holiday', headerName: 'Holiday', flex: 1, renderCell: () => {
        return <TextField type="number" sx={{ m: -1 }} value={holiday} onChange={(e) => setHoliday(e.target.value)} />
      }
    }
  ];

  const prodReworkColumns = [
    { field: 'orderNumber', headerName: 'Order #', flex: 1, renderCell: (params) => params.row?.orderId?.salesOrderNumber ? params.row.orderId.salesOrderNumber : 'N/A' },
    { field: 'machineId', headerName: 'Machine #', flex: 1, renderCell: (params) => params.row?.machineId?.name ? params.row.machineId.name : 'N/A' },
    { field: 'jobDate', headerName: 'Date', flex: 1.5, valueFormatter: (params) => new Date(params.value).toLocaleDateString() },
    { field: 'feet', headerName: 'Feet', flex: 1 },
    { field: 'hours', headerName: 'Hours', flex: 1 },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1.5,
      renderCell: (params) => {
        const value = params.row?.orderId?.rate ? (params.row.orderId.rate * params.row.feet).toFixed(2) : 'N/A'
        return (params.row?.reworked ?
          <span style={{ color: 'red' }}>${value}</span> :
          <span>${value}</span>
        )
      }
    },
  ];

  const downtimeColumns = [
    { field: 'orderNumber', headerName: 'Order #', flex: 1, renderCell: (params) => params.row?.orderId?.salesOrderNumber ? params.row.orderId.salesOrderNumber : 'N/A' },
    { field: 'machineId', headerName: 'Machine #', flex: 1, renderCell: (params) => params.row?.machineId?.name ? params.row.machineId.name : 'N/A' },
    { field: 'jobDate', headerName: 'Date', flex: 1.5, valueFormatter: (params) => new Date(params.value).toLocaleDateString() },
    { field: 'feet', headerName: 'Feet', flex: 1 },
    { field: 'hours', headerName: 'Hours', flex: 1 },
    { field: 'rate', headerName: 'Rate', flex: 1, renderCell: (params) => params.row?.rate ? params.row.rate.toFixed(2) : 'N/A' },
    { field: 'amount', headerName: 'Amount', flex: 1.5, renderCell: (params) => params.row?.rate ? `$${(params.row.rate * params.row.feet).toFixed(2)}` : 'N/A' },
  ];

  const scrapColumns = [
    { field: 'orderNumber', headerName: 'Order #', flex: 1, renderCell: (params) => params.row?.orderId?.salesOrderNumber ? params.row.orderId.salesOrderNumber : 'N/A' },
    { field: 'jobDate', headerName: 'Date', flex: 1.5, valueFormatter: (params) => new Date(params.value).toLocaleDateString() },
    { field: 'feetPcs', headerName: 'Feet/Pieces', flex: 1, renderCell: (params) => `${params.row?.pieces || 'N/A'}/${params.row?.feet || 'N/A'}` },
    { field: 'hours', headerName: 'Hours', flex: 1 },
    { field: 'amount', headerName: 'Amount', flex: 1.5, renderCell: (params) => params.row?.orderId?.rate ? `$${(params.row.orderId.rate * params.row.feet).toFixed(2)}` : 'N/A' }
  ];


  const renderEmptyState = () => (
    <Box sx={{ textAlign: 'center', marginTop: 8 }}>
      <AddCircleOutlineIcon sx={{ fontSize: 60 }} />
      <Typography variant="h6" sx={{ mt: 2 }}>
        No Employee Selected
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        Select an employee to view calculations and record data.
      </Typography>
    </Box>
  );


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: 2, gap: 2 }}>
        <EmployeeSearch handleChange={handleEmployeeChange} marginTop={1} />
        <DatePicker
          label="Start Date"
          value={payrollStartDate}
          sx={{ mt: 2, mb: 1, width: '100%' }}
          onChange={(date) => handleDateChange('payrollStartDate', date)}
        />
        <DatePicker
          label="End Date"
          value={payrollEndDate}
          sx={{ mt: 2, mb: 1, width: '100%' }}
          onChange={(date) => handleDateChange('payrollEndDate', date)}
        />
      </Box>
      {employee ? (
        <>
          {employee && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} >
                <DataGridPro
                  columns={hoursColumns}
                  rows={payHours.results}
                  pageSize={payHours.totalCount}
                  loading={isLoading}
                  getRowId={(row) => row._id}
                  slots={{
                    footer: CustomFooterComponent,
                  }}
                  slotProps={{
                    footer: { total: totalHours, label: 'Total Hours: ' }
                  }}
                />
              </Grid>

              <Grid item xs={12} md={8}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, m: 2 }}>
                  <Paper flex={1} sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, m: 2 }}>
                      <DataContainer label="Hours Worked:" value={totalHours} justifyContent={'space-between'} />
                      <DataContainer label="Hourly Rate:" value={`$${employee.currentHourlyRate?.toFixed(2) || 0}`} justifyContent={'space-between'} />
                      <DataContainer label="Base Pay:" value={`$${(basePay).toFixed(2) || 0}`} justifyContent={'space-between'} />
                    </Box>
                  </Paper>
                  <Paper flex={1} sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, m: 2 }}>
                      <DataContainer label="Production:" value={productionTime} justifyContent={'space-between'} />
                      <DataContainer label="Down Time:" value={downTime} justifyContent={'space-between'} />
                      <DataContainer label="Rework:" value={reworkTime} justifyContent={'space-between'} />
                    </Box>
                  </Paper>
                  <Paper flex={1} sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, m: 2 }}>
                      <DataContainer label="Incentive Total" value={`$${incentiveTotal.toFixed(2) || 0}`} justifyContent={'space-between'} />
                      <DataContainer label="Incentive/Hr" value={`$${incentiveHour.toFixed(2) || 0}`} justifyContent={'space-between'} />
                      <DataContainer label="Inct+Bon/Hr" value={`$${incBonusHour.toFixed(2) || 0}`} justifyContent={'space-between'} />
                    </Box>
                  </Paper>
                </Box>
                <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', m: 2 }}>
                  <DataGridPro
                    columns={totalPayColumns}
                    rows={[{ id: 1 }]}
                    slots={{
                      footer: CustomFooterComponent,
                    }}
                    slotProps={{
                      footer: { total: `$${(totalPay).toFixed(2)}`, label: 'Total Pay: ' }
                    }}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={showDetails ? <CloseIcon /> : <AddIcon />}
                    onClick={() => { setShowDetails(!showDetails); }}
                  >
                    {showDetails ? 'Hide Details' : 'Show Details'}
                  </Button>
                  <Button onClick={handleSave}>
                    Save
                  </Button>
                </Box>
              </Grid>

            </Grid>
          )}

          {showDetails && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Paper sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                <Typography variant="h6" align="center" sx={{ m: 2 }}>Employee Information</Typography>
                <Grid container spacing={2} sx={{ display: 'flex', pr: 16 }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <DataContainer label="Hourly Rate:" value={`$${employee.currentHourlyRate.toFixed(2)}`} justifyContent={'end'} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DataContainer label="Overtime Rate:" value={`$${employee.currentOTRate.toFixed(2)}`} justifyContent={'end'} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DataContainer label="3 Mo Fringe:" value={employee.fringeBenefits3Mo ? 'Yes' : 'No'} justifyContent={'end'} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DataContainer label="6 Mo Fringe:" value={employee.fringeBenefits6Mo ? 'Yes' : 'No'} justifyContent={'end'} />
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={8}>
                <Grid item sm={12} md={6} xl={3}>
                  <Typography variant="h6" align="center" sx={{ m: 2 }}>Production Information</Typography>
                  <DataGridPro
                    columns={prodReworkColumns}
                    rows={jobs.results.filter(job => job.workType === 'P')}
                    getRowId={(row) => row._id}
                  />
                </Grid>
                <Grid item sm={12} md={6} xl={3}>
                  <Typography variant="h6" align="center" sx={{ m: 2 }}>Rework Information</Typography>
                  <DataGridPro
                    columns={prodReworkColumns}
                    rows={jobs.results.filter(job => job.workType === 'R').concat(reworkedJobs)}
                    getRowId={(row) => row._id}
                  />
                </Grid>
                <Grid item sm={12} md={6} xl={3}>
                  <Typography variant="h6" align="center" sx={{ m: 2 }}>Downtime Information</Typography>
                  <DataGridPro
                    columns={downtimeColumns}
                    rows={jobs.results.filter(job => job.workType === 'D')}
                    getRowId={(row) => row._id}
                  />
                </Grid>
                <Grid item sm={12} md={6} xl={3}>
                  <Typography variant="h6" align="center" sx={{ m: 2 }}>Scrap Information</Typography>
                  <DataGridPro
                    columns={scrapColumns}
                    rows={jobs.results.filter(job => job.workType === 'S')}
                    getRowId={(row) => row._id}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2, mt: 10 }}>
                <Paper sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                  <Typography variant="h6" align="center" sx={{ m: 2 }}>Payroll Numbers</Typography>
                  <Grid container spacing={2} sx={{ display: 'flex', py: 1, pr: 16 }}>
                    <Grid item sm={10} md={4}>
                      <DataContainer label="Production Total:" value={`$${incentiveTotal.toFixed(2) || 0}`} justifyContent={'end'} />
                    </Grid>
                    <Grid item sm={10} md={4}>
                      <DataContainer label="Incentive Rate:" value={`$${incentiveHour.toFixed(2) || 0}`} justifyContent={'end'} />
                    </Grid>
                    <Grid item sm={10} md={4}>
                      <DataContainer label="Job Rate:" value={`$${jobRate.toFixed(2)}`} justifyContent={'end'} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ display: 'flex', py: 1, pr: 16 }}>
                    <Grid item sm={10} md={8}>
                      <DataContainer label="IncentiveRate-Job Rate:" value={`$${(incentiveHour - jobRate).toFixed(2) || 0}`} justifyContent={'end'} />
                    </Grid>
                    <Grid item sm={10} md={4}>
                      <DataContainer label="Bonus Rate:" value={`$${bonusRate.toFixed(2)}`} justifyContent={'end'} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ display: 'flex', py: 1, pr: 16 }}>
                    <Grid item sm={10} md={4} lg={2.4}>
                      <DataContainer label="Production:" value={`$${productionAmount.toFixed(2)}`} justifyContent={'end'} />
                    </Grid>
                    <Grid item sm={10} md={4} lg={2.4}>
                      {/* Rework where this employee is reworkEmpId - orderId.rate * feet */}
                      <DataContainer label="Rework+:" value={`$${reworkAdd.toFixed(2)}`} justifyContent={'end'} />
                    </Grid>
                    <Grid item sm={10} md={4} lg={2.4}>
                      {/* Rework where this employee is original worker - orderId.rate * feet */}
                      <DataContainer label="Rework-:" value={`($${reworkSub.toFixed(2)})`} justifyContent={'end'} />
                    </Grid>
                    <Grid item sm={10} md={8} lg={2.4}>
                      <DataContainer label="Downtime:" value={`$${downTimeTotal.toFixed(2)}`} justifyContent={'end'} />
                    </Grid>
                    <Grid item sm={10} md={4} lg={2.4}>
                      <DataContainer label="Scrap:" value={`($${scrapTotal.toFixed(2)})`} justifyContent={'end'} />
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Box>
          )}
        </>
      ) : (
        renderEmptyState()
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>{snackbar.message}</Alert>
      </Snackbar>
    </Box>
  )
};

export default CalculationTab;