import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import UsersStore from '../stores/UsersStore';
import {
  Box,
  Typography,
  Button,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Paper,
  useMediaQuery,
  useTheme
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const Users = observer(() => {
  const [users, setUsers] = useState([]);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const handleUserApproval = (userId) => {
    try {
      if (!isAdmin()) {
        throw new Error('Only admins can approve users.');
      }

      const userToApprove = users.find(user => user.id === userId);
      if (!userToApprove) {
        throw new Error('User not found.');
      }

      const updatedUsers = users.map(user => {
        if (user.id === userId) {
          return { ...user, isPending: false };
        }
        return user;
      });

      setUsers(updatedUsers);
      console.log(`User ${userId} approved successfully.`);
    } catch (error) {
      console.error('Error approving user:', error.message);
    }
  };

  const isAdmin = () => {
    const currentUser = UsersStore.currentUser;
    return currentUser && currentUser.role === 'admin';
  };

  useEffect(() => {
    UsersStore.fetchUsers().then(() => {
      setUsers(UsersStore.users);
    });
  }, []);

  return (
    <>
      <Paper elevation={2} sx={{ p: 2, m: 2 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Approved Users
        </Typography>
        <Box maxHeight={400} overflow="auto">
          {users.filter(user => !user.isPending).map((user, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.name} secondary={user.email} />
              {index < users.length - 1 && <Divider />}
            </ListItem>
          ))}
        </Box>
      </Paper>

      <Paper elevation={2} sx={{ p: 2, m: 2 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Pending Users
        </Typography>
        <Box maxHeight={400} overflow="auto">
          {users.filter(user => user.isPending).map((user, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.name} secondary={user.email} />
              {isAdmin() ? (
                <Button variant="contained" color="primary" onClick={() => handleUserApproval(user.id)}>
                  Approve
                </Button>
              ) : (
                <Typography variant="subtitle1" color="error">Pending</Typography>
              )}
              {index < users.length - 1 && <Divider />}
            </ListItem>
          ))}
        </Box>
      </Paper>
    </>
  );
});

export default Users;
