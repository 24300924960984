import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MachineStore from '../../stores/MachineStore';
import MachineForm from './MachineForm';
import { 
  Box, Typography, CircularProgress, Menu, MenuItem, 
  IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';

const EditMachineOperator = () => {
  const { machineId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [machine, setMachine] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

  useEffect(() => {
    setLoading(true);
    MachineStore.fetchMachineById(machineId).then((machine) => {
      setMachine(machine);
      setLoading(false);
    }).catch((error) => {
      console.error('Failed to fetch machine:', error);
      setSnackbar({ open: true, message: 'Failed to fetch machine', severity: 'error' });
      setLoading(false);
    });
  }, [machineId]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await MachineStore.updateMachine(machineId, machine);
      setLoading(false);
      navigate('/orders');
    } catch (error) {
      console.error('Failed to update machine:', error);
      setSnackbar({ open: true, message: 'Failed to update machine', severity: 'error' });
      setLoading(false);
    }
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setDeleteDialogOpen(false);
    setLoading(true);
    try {
      await MachineStore.deleteMachine(machineId);
      navigate('/orders');
    } catch (error) {
      console.error('Failed to delete machine:', error);
      setSnackbar({ open: true, message: 'Failed to delete machine', severity: 'error' });
      setLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const handleChange = (updatedMachine) => {
    setMachine(updatedMachine);
  };

  const handleBack = () => {
    navigate('/orders');
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const handleMenuAction = (action) => {
    switch (action) {
      case 'Back':
        handleBack();
        break;
      case 'Delete':
        handleDelete();
        break;
      default:
        break;
    }
    handleMenuClose();
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5" component="h1">Edit Machine</Typography>
        <Box>
          <IconButton onClick={handleMenuOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleMenuAction('Delete')}>Delete</MenuItem>
          </Menu>
          <IconButton onClick={handleBack}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        machine &&
        <>
          <MachineForm machine={machine} onSubmit={handleSubmit} back={handleBack} onChange={handleChange} />
          <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ mt: 2 }}>Save</Button>
          <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
            <DialogTitle>Delete Machine</DialogTitle>
            <DialogContent>
              <Typography variant="body1">Are you sure you want to delete this machine?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteCancel}>Cancel</Button>
              <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditMachineOperator;
