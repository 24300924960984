import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EmployeeStore from '../../stores/EmployeeStore';
import EmployeeForm from './EmployeeForm';
import { Box, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import UserSearch from '../../components/search/UserSearch';

const AddEmployee = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
    const [employee, setEmployee] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        birthDate: '',
        address: {
            name: '',
            street: '',
            line2: '',
            city: '',
            postalCode: '',
            state: '',
            country: '',
        },
        employeeId: '',
        nameID: '',
        adpID: '',
        driversLicenseNumber: '',
        driversLicenseState: '',
        filingStatus: '',
        fringeBenefits3Mo: '',
        fringeBenefits6Mo: '',
        hirDate: '',
        finalDate: '',
        currentHourlyRate: '',
        currentOTRate: '',
        currentSalary: '',
        hireHourlyRate: '',
        hireOTRate: '',
        hireSalary: '',
        titleCode: '',
        employmentType: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
        honeDate: '',
        lockerNumber: '',
        maritalStatus: '',
        socialSecurityNumber: ''
    });

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await EmployeeStore.createEmployee(employee);
            setLoading(false);
            navigate('/employee'); 
        } catch (error) {
            console.error('Failed to create employee:', error);
            setSnackbar({ open: true, message: 'Failed to create employee', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (updatedEmployee) => {
        setEmployee(updatedEmployee);
    }

    const handleUserChange = (user) => {
        if (user) {
            setEmployee({
                ...employee,
                user: user._id
            });
        }
    }

    const handleBack = () => {
        navigate('/employee');
    };

    return (
        <Box sx={{ m: 4 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>Add New Employee</Typography>
            {loading ? (
                <CircularProgress />
            ) : (
                <div>
                    <Box m={2}>
                        <Typography variant="h6" sx={{ mb: 3 }}>Please select a user</Typography>
                        <UserSearch margin={1} handleChange={handleUserChange} />
                    </Box>
                    <EmployeeForm employee={employee} onSubmit={handleSubmit} back={handleBack} onChange={handleChange} />
                </div>
            )}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default AddEmployee;
