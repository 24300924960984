import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PayablesStore from '../../stores/PayablesStore';
import {
  Box, Typography, CircularProgress, Button, Paper, Menu, MenuItem, IconButton
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const PayableDetails = () => {
  const [payable, setPayable] = useState(null);
  const navigate = useNavigate();
  const { payableId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    PayablesStore.fetchPayableById(payableId).then((fetchedPayable) => {
      setLoading(false);
      if (fetchedPayable) {
        setPayable(fetchedPayable);
      } else {
        console.error('Fetched payable is not valid');
        setPayable(null);
      }
    }).catch(error => {
      setLoading(false);
      console.error('Error fetching payable:', error);
    });
  }, [payableId]);

  const handleEdit = () => {
    navigate(`/payable/edit/${payableId}`);
  };

  const handleDelete = () => {
    if (payable) {
      PayablesStore.deletePayable(payable.id).then(() => {
        navigate('/payables');
      }).catch((error) => {
        console.error('Error deleting payable:', error);
      });
    }
  };

  const handleBack = () => {
    navigate('/payables');
  };

  const formatDate = (date) => {
    return date
      ? new Date(date).toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      : 'N/A';
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    handleMenuClose();
    if (action === 'edit') {
      handleEdit();
    } else if (action === 'delete') {
      handleDelete();
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Button variant="outlined" onClick={handleBack}>
          Back
        </Button>
        <Typography variant="h4" gutterBottom>
          Invoice #{payable?.invoiceNumber}
        </Typography>
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleMenuItemClick('edit')}>
            <EditIcon sx={{ mr: 1 }} />
            Edit
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('delete')}>
            <DeleteIcon sx={{ mr: 1 }} />
            Delete
          </MenuItem>
        </Menu>
      </Box>
      <Typography variant="h3" gutterBottom>
        {payable?.vendor.vendorName}
      </Typography>

      <Box>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Details
          </Typography>
          <Typography><strong>Due Date:</strong> {formatDate(payable?.dueDate)}</Typography>
          <Typography><strong>Expense Code:</strong> {payable?.expenseCode}</Typography>
          <Typography><strong>Distribution Total:</strong> ${payable?.invoiceAmount.toFixed(2)}</Typography>
        </Paper>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
        <Button variant="outlined" onClick={handleEdit}>
          Edit
        </Button>
        <Button variant="outlined" onClick={handleBack}>
          Back
        </Button>
      </Box>
    </Box>
  );
};

export default PayableDetails;
