import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import DynamicSearchFilter from '../components/DynamicSearchFilter';
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
  List,
  ListItemButton,
  ListItemText,
  Snackbar,
  Alert,
  Button,
  Divider,
  Grid,
  Tab,
  Modal,
  IconButton,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import { TablePagination } from '@mui/material';
import JobStore from '../stores/JobStore';
import ShopOrdersStore from '../stores/ShopOrdersStore';
import { format } from 'date-fns';
import CustomerStore from '../stores/CustomerStore';
import ProductionTab from '../components/ProductionTabs/ProductionTab';
import ReworkTab from '../components/ProductionTabs/ReworkTab';
import DowntimeTab from '../components/ProductionTabs/DowntimeTab';
import ScrapTab from '../components/ProductionTabs/ScrapTab';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import { PDFViewer } from '@react-pdf/renderer';
import JobSheetsPDF from '../pdf/JobSheetsPDF';
import DailyMachineSheetPDF from '../pdf/DailyMachineSheetPDF';

const checkIfId = (key) => {
  return /.{24}/.test(key);
}

function ProductionFooter(props) {

  const style = {
    flex: 1,
    pl: '10px',
    pr: '10px',
  }

  return (
    <>
      <Divider />
      <Box sx={{ display: 'flex', pt: 1, pb: 1 }}>
        <Box sx={style}>
          <Typography variant="body2" align='right' pr={2} >Total:</Typography>
        </Box>
        <Box sx={style}>
          <Typography variant="body2" >{props.pieces}</Typography>
        </Box>
        <Box sx={style}>
          <Typography variant="body2" >{props.feet}</Typography>
        </Box>
        <Box sx={style}>
          <Typography variant="body2" >{props.hours}</Typography>
        </Box>
      </Box>
    </>

  )
};

function DowntimeFooter(props) {

  const style = {
    flex: 1,
    pl: '10px',
    pr: '10px',
  }

  return (
    <>
      <Divider />
      <Box sx={{ display: 'flex', pt: 1, pb: 1 }}>
        <Box sx={style}>
          <Typography variant="body2" align='right' pr={2} >Total:</Typography>
        </Box>
        <Box sx={style}>
          <Typography variant="body2" >{props.hours}</Typography>
        </Box>
      </Box>
    </>

  )
};

function ScrapFooter(props) {
  const style = {
    flex: 1,
    pl: '10px',
    pr: '10px',
  }

  return (
    <>
      <Divider />
      <Box sx={{ display: 'flex', pt: 1, pb: 1 }}>
        <Box sx={style}>
          <Typography variant="body2" align='right' pr={2} >Total:</Typography>
        </Box>
        <Box sx={style}>
          <Typography variant="body2" >{props.pieces}</Typography>
        </Box>
        <Box sx={style}>
          <Typography variant="body2" >{props.feet}</Typography>
        </Box>
      </Box>
    </>

  )
};

const Productions = observer(() => {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  // Shop Order fields
  const [shopOrders, setShopOrders] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 20,
    totalPages: 0,
  });
  const [shopOrder, setShopOrder] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState('');
  const [searchField, setSearchField] = useState('salesOrderNumber');
  const [sortBy, setSortBy] = useState('-dueDate');
  const [isLoading, setIsLoading] = useState(false);
  // Jobs
  const [productions, setProductions] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [tab, setTab] = useState('P');
  const [viewJobPdf, setViewJobPdf] = useState(false);
  const [viewMachinePdf, setViewMachinePdf] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOrderSelect = (shopOrder) => {
    setShopOrder(shopOrder);

    if (checkIfId(shopOrder.customer)) {
      CustomerStore.fetchCustomerById(shopOrder.customer).then((customer) => {
        setShopOrder({ ...shopOrder, customer: customer });
      });
    }

    const jobFilter = { orderId: shopOrder._id };
    JobStore.fetchPaginatedJobs(1, 100, '-dueDate', jobFilter).then((jobs) => {
      if (jobs) {
        setProductions(jobs);
      } else {
        setSnackbar({ open: true, message: `No productions found for Shop Order #${shopOrder.salesOrderNumber}`, severity: 'error' });
      }

    });
  };

  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleAddProduction = async (newProduction) => {
    newProduction.orderId = shopOrder._id;
    newProduction.orderNumber = shopOrder.salesOrderNumber;
    if (!newProduction.machineId) {
      setSnackbar({ open: true, message: 'Please select a machine', severity: 'warning' });
      return;
    }
    if (!newProduction.jobDate) {
      setSnackbar({ open: true, message: 'Please select a job date', severity: 'warning' });
      return;
    }
    if (!newProduction.employeeId) {
      setSnackbar({ open: true, message: 'Please select an employee', severity: 'warning' });
      return;
    }

    newProduction = {
      ...newProduction,
      feet: parseInt(newProduction.feet || 0),
      pieces: parseInt(newProduction.pieces || 0),
      hours: parseFloat(newProduction.hours || 0),
    }

    try {
      const response = await JobStore.createJob(newProduction);
      if (response) {
        setSnackbar({ open: true, message: 'Job added successfully', severity: 'success' });
      } else {
        setSnackbar({ open: true, message: 'Failed to add job', severity: 'error' });
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to add job', severity: 'error' });
    }
  }

  const deleteJob = async (id) => {
    try {
      await JobStore.deleteJob(id);
      setSnackbar({ open: true, message: 'Job deleted successfully', severity: 'success' });
      const jobFilter = { orderId: shopOrder._id };
      JobStore.fetchPaginatedJobs(1, 100, '-dueDate', jobFilter).then((jobs) => {
        if (jobs) {
          setProductions(jobs);
        } else {
          setSnackbar({ open: true, message: `No productions found for SO #${shopOrder.salesOrderNumber}`, severity: 'error' });
        }

      });
    } catch (error) {
      console.log(error);
      setSnackbar({ open: true, message: 'Failed to delete job', severity: 'error' });
    };
  }

  const handleDynamicSearch = (searchQuery, filterCriteria, sortBy) => {

    setSearch(searchQuery);
    if (sortBy) {
      setSortBy(sortBy);
    }

    let keys = Object.keys(filterCriteria);
    if (keys.length > 0) {
      let filterBuilder = {};
      if (filterCriteria.dueDateFrom || filterCriteria.dueDateTo) {
        filterBuilder.dueDate = {};
        if (filterCriteria.dueDateFrom) filterBuilder.dueDate.$gte = new Date(filterCriteria.dueDateFrom).toISOString();
        if (filterCriteria.dueDateTo) filterBuilder.dueDate.$lte = new Date(filterCriteria.dueDateTo).toISOString();
      }
      if (filterCriteria.promiseDateFrom || filterCriteria.promiseDateTo) {
        filterBuilder.promiseDate = {};
        if (filterCriteria.promiseDateFrom) filterBuilder.promiseDate.$gte = new Date(filterCriteria.promiseDateFrom).toISOString();
        if (filterCriteria.promiseDateTo) filterBuilder.promiseDate.$lte = new Date(filterCriteria.promiseDateTo).toISOString();
      }
      if (filterCriteria.shipDateFrom || filterCriteria.shipDateTo) {
        filterBuilder.shipDate = {};
        if (filterCriteria.shipDateFrom) filterBuilder.shipDate.$gte = new Date(filterCriteria.shipDateFrom).toISOString();
        if (filterCriteria.shipDateTo) filterBuilder.shipDate.$lte = new Date(filterCriteria.shipDateTo).toISOString();
      }
      setFilter(filterBuilder);
    } else {
      setFilter({});
    }
  };

  const orderInterface = {
    salesOrderNumber: '',
    customerPONumber: '',
    millJobNumber: '',
    customer: '',
    paymentStatus: '',
    dueDate: new Date(),
    promiseDate: new Date(),
    shipDate: new Date(),
  };

  useEffect(() => {
    if (search || filter.dueDate || filter.promiseDate || filter.shipDate) {
      fetchPaginatedShopOrders();
    }
  }, [page, pageSize, sortBy, filter, search, searchField]);

  const fetchPaginatedShopOrders = async () => {
    setIsLoading(true);

    ShopOrdersStore.fetchPaginatedShopOrders(page, pageSize, sortBy, filter, search, searchField)
      .then(() => {
        setShopOrders(ShopOrdersStore.paginatedShopOrders);
      })
      .catch(error => {
        setSnackbar({ open: true, message: 'Failed to fetch shop orders', severity: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns = [
    { field: 'machineName', headerName: 'Machine', flex: 1, sortable: false, filterable: false, renderCell: (params) => params.row.machineId?.name },
    { field: 'pieces', headerName: 'Pieces', flex: 1, sortable: false, filterable: false, },
    { field: 'feet', headerName: 'Feet', flex: 1, sortable: false, filterable: false },
    { field: 'hours', headerName: 'Hours', flex: 1, sortable: false, filterable: false, },
  ];

  const downTimeColumns = [
    { field: 'machineName', headerName: 'Machine', flex: 1, sortable: false, filterable: false, renderCell: (params) => params.row.machineId?.name },
    { field: 'hours', headerName: 'Hours', flex: 1, sortable: false, filterable: false, },
  ]

  const scrapColumns = [
    { field: 'machineName', headerName: 'Machine', flex: 1, sortable: false, filterable: false, renderCell: (params) => params.row.machineId?.name },
    { field: 'pieces', headerName: 'Pieces', flex: 1, sortable: false, filterable: false, },
    { field: 'feet', headerName: 'Footage', flex: 1, sortable: false, filterable: false, },
  ]


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" gutterBottom>Productions</Typography>
      </Box>
      <Box >
        <Typography variant='h6' align='left' margin={2.5} gutterBottom>Search for a Shop Order</Typography>
        <DynamicSearchFilter
          defaultSort="-dueDate"
          onSearch={handleDynamicSearch}
          objectInterface={orderInterface}
        />
      </Box>

      {shopOrders.totalCount > 0 && (
        <>
          {shopOrder ? (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <Typography variant="h6" align='center' gutterBottom>Order #{shopOrder.salesOrderNumber}</Typography>
                <Typography variant="h6" align='center' gutterBottom>Customer: {shopOrder.customer?.companyName || 'N/A'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: 2 }}>
                <Button variant="contained" onClick={() => setShopOrder(null)}>Change Selected Order</Button>
                <Button variant="outlined" startIcon={<PrintIcon />} onClick={() => setViewJobPdf(true)}>Print Job Sheet</Button>
                <Button variant="outlined" startIcon={<PrintIcon />} onClick={() => setViewMachinePdf(true)}>Print Machine Production Sheet</Button>
              </Box>
              {viewJobPdf && (
                <Modal
                  open={viewJobPdf}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
                    <IconButton
                      onClick={() => setViewJobPdf(false)}
                      sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <PDFViewer width="100%" height="100%">
                      <JobSheetsPDF orderInfo={shopOrder} />
                    </PDFViewer>
                  </Paper>
                </Modal>
              )}
              {viewMachinePdf && (
                <Modal
                  open={viewMachinePdf}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
                    <IconButton
                      onClick={() => setViewMachinePdf(false)}
                      sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <PDFViewer width="100%" height="100%">
                      <DailyMachineSheetPDF orderInfo={shopOrder} />
                    </PDFViewer>
                  </Paper>
                </Modal>
              )}
            </>
          ) : (
            <>
              <Typography variant="h6" align='center' gutterBottom>Select an Order</Typography>

              <List>
                {shopOrders.results.map((shopOrder) => (
                  <React.Fragment key={shopOrder._id}>
                    <ListItemButton onClick={() => handleOrderSelect(shopOrder)}>
                      <ListItemText
                        primary={`Order #${shopOrder.salesOrderNumber}`}
                        secondary={
                          `Customer: ${shopOrder.customer.companyName || ''}, Due: ${shopOrder.dueDate ? format(new Date(shopOrder.dueDate), 'MM/dd/yyyy') : 'N/A'}, 
                          Promised: ${shopOrder.promiseDate ? format(new Date(shopOrder.promiseDate), 'MM/dd/yyyy') : 'N/A'}, 
                          Shipped: ${shopOrder.shipDate ? format(new Date(shopOrder.shipDate), 'MM/dd/yyyy') : 'N/A'}, Total: $${shopOrder.total}`
                        }
                      />
                    </ListItemButton>
                    <Divider />
                  </React.Fragment>

                ))}
              </List>
              <TablePagination
                component="div"
                count={shopOrders.totalCount}
                page={shopOrders.currentPage - 1}
                onPageChange={handlePageChange}
                rowsPerPage={shopOrders.pageSize}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[5, 10, 20]}
              />
            </>
          )}
        </>
      )}

      <Divider sx={{ m: 2 }} />
      {shopOrder && (
        <Box>
          <TabContext value={tab}>
            <TabList onChange={handleTabChange} centered>
              <Tab label="Production" value="P" />
              <Tab label="Rework" value="R" />
              <Tab label="Downtime" value="D" />
              <Tab label="Scrap" value="S" />
            </TabList>
            <>
              <TabPanel value="P">
                <ProductionTab
                  productions={productions.results.filter((production) => production.workType === 'P')}
                  handleAddProduction={handleAddProduction}
                  deleteJob={deleteJob}
                />
              </TabPanel>
              <TabPanel value="R">
                <ReworkTab
                  productions={productions.results.filter((production) => production.workType === 'R')}
                  handleAddProduction={handleAddProduction}
                  deleteJob={deleteJob}
                />
              </TabPanel>
              <TabPanel value="D">
                <DowntimeTab
                  productions={productions.results.filter((production) => production.workType === 'D')}
                  handleAddProduction={handleAddProduction}
                  deleteJob={deleteJob}
                />
              </TabPanel>
              <TabPanel value="S">
                <ScrapTab
                  productions={productions.results.filter((production) => production.workType === 'S')}
                  handleAddProduction={handleAddProduction}
                  deleteJob={deleteJob}
                />
              </TabPanel>
            </>
          </TabContext>
        </Box>

      )}


      <Divider sx={{ m: 2 }} />

      <Box sx={{ display: 'flex', justifyContent: 'center', m: 2 }}>

        {productions.totalCount > 0 && (
          <Paper sx={{ width: '100%', p: 4, pb: 8 }}>
            <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item xl={6} sx={{ width: '100%', mb: 4 }}>
                <Typography variant="h6" align='center' gutterBottom>Production Stats</Typography>
                <DataGridPro
                  rows={productions.results.filter((production) => production.workType === 'P')}
                  columns={columns}
                  getRowId={(row) => row._id}
                  slots={{
                    footer: ProductionFooter
                  }}
                  slotProps={{
                    footer: {
                      pieces: productions.results.filter((production) => production.workType === 'P').reduce((sum, production) => sum + production.pieces, 0),
                      feet: productions.results.filter((production) => production.workType === 'P').reduce((sum, production) => sum + production.feet, 0),
                      hours: productions.results.filter((production) => production.workType === 'P').reduce((sum, production) => sum + production.hours, 0),
                    }
                  }}
                />
              </Grid>
              <Grid item xl={6} sx={{ width: '100%', mb: 4 }}>
                <Typography variant="h6" align='center' gutterBottom>Rework Stats</Typography>
                <DataGridPro
                  rows={productions.results.filter((production) => production.workType === 'R')}
                  columns={columns}
                  getRowId={(row) => row._id}
                  slots={{
                    footer: ProductionFooter
                  }}
                  slotProps={{
                    footer: {
                      pieces: productions.results.filter((production) => production.workType === 'R').reduce((sum, production) => sum + production.pieces, 0),
                      feet: productions.results.filter((production) => production.workType === 'R').reduce((sum, production) => sum + production.feet, 0),
                      hours: productions.results.filter((production) => production.workType === 'R').reduce((sum, production) => sum + production.hours, 0),
                    }
                  }}
                />
              </Grid>
              <Grid item xl={6} sx={{ width: '100%', mb: 4 }}>
                <Typography variant="h6" align='center' gutterBottom>Downtime Stats</Typography>
                <DataGridPro
                  rows={productions.results.filter((production) => production.workType === 'D')}
                  columns={downTimeColumns}
                  getRowId={(row) => row._id}
                  slots={{
                    footer: DowntimeFooter
                  }}
                  slotProps={{
                    footer: {
                      hours: productions.results.filter((production) => production.workType === 'D').reduce((sum, production) => sum + production.hours, 0),
                    }
                  }}
                />
              </Grid>
              <Grid item xl={6} sx={{ width: '100%', mb: 4 }}>
                <Typography variant="h6" align='center' gutterBottom>Scrap Stats</Typography>
                <DataGridPro
                  rows={productions.results.filter((production) => production.workType === 'S')}
                  columns={scrapColumns}
                  getRowId={(row) => row._id}
                  slots={{
                    footer: ScrapFooter
                  }}
                  slotProps={{
                    footer: {
                      pieces: productions.results.filter((production) => production.workType === 'S').reduce((sum, production) => sum + production.pieces, 0),
                      feet: productions.results.filter((production) => production.workType === 'S').reduce((sum, production) => sum + production.feet, 0),
                    }
                  }}
                />
              </Grid>
            </Grid>

          </Paper>
        )}
      </Box>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ 'vertical': 'bottom', 'horizontal': 'center' }}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default Productions;
