import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Box, Typography, Button, TextField, TablePagination, Snackbar,
  Autocomplete, CircularProgress, debounce
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { DataGridPro } from '@mui/x-data-grid-pro';
import MachineSearch from '../../components/search/MachineSearch';
import ShopOrdersStore from '../../stores/ShopOrdersStore';
import { toJS } from 'mobx';
import { format } from 'date-fns';
import CustomerStore from '../../stores/CustomerStore';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MachineAssignment = observer(() => {
  const [machine, setMachine] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('-enteredDate');
  const [filterCriteria, setFilterCriteria] = useState({});
  const [assignedOrders, setAssignedOrders] = useState([]);
  const [sortModel, setSortModel] = useState([{
    field: 'sequence',
    sort: 'asc',
  }]);
  const [shopOrders, setShopOrders] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });

  const handleMachineChoice = async (machine) => {
    setMachine(machine);
    await fetchAssignedOrders(machine);
  };

  const handleAssignOrder = (order) => {
    let maxSequence = assignedOrders.reduce((max, order) => (order.sequence > max ? order.sequence : max), 0);
    order.sequence = maxSequence + 1;
    order.machine = machine._id;

    setAssignedOrders([...assignedOrders, toJS(order)]);

    let newShopOrders = shopOrders.results.filter((shopOrder) => shopOrder._id !== order._id);
    setShopOrders({ ...shopOrders, results: newShopOrders });
  }

  const handlePageChange = (event, page) => {
    fetchPaginatedShopOrders(page + 1, shopOrders.pageSize, sortBy, filterCriteria, searchQuery);
  }

  const handleRowsPerPageChange = (event) => {
    fetchPaginatedShopOrders(1, event.target.value, sortBy, filterCriteria, searchQuery);
  };

  const handleInputChange = debounce(async (event, value, reason) => {
    if (value.length > 0 && reason === 'input') {
      const customerSearchFilter = {
        companyName: { $regex: value, $options: 'i' },
      }

      let filter = {
        finishedDate: null,
        machine: null,
        receivedDate: {
          $ne: null
        },
      }

      try {
        const customers = await CustomerStore.fetchPaginatedCustomers(1, 20, 'companyName', customerSearchFilter, '', 'companyName');
        if (customers && customers.results.length > 0) {
          filter = {
            ...filter,
            $or: [
              {
                customer: {
                  $in: customers.results.map((customer) => customer._id)
                }
              },
              { salesOrderNumber: { $regex: value, $options: 'i' } },
              { millJobNumber: { $regex: value, $options: 'i' } }
            ]
          }
        } else {
          filter = {
            ...filter,
            $or: [
              { salesOrderNumber: { $regex: value, $options: 'i' } },
              { millJobNumber: { $regex: value, $options: 'i' } }
            ]
          }
        }
      } catch (error) {
        filter = {
          ...filter,
          $or: [
            { salesOrderNumber: { $regex: value, $options: 'i' } },
            { millJobNumber: { $regex: value, $options: 'i' } }
          ]
        }
      }
      fetchPaginatedShopOrders(1, 100, sortBy, filter);
      setFilterCriteria(filter);
    } else {
      setFilterCriteria({
        finishedDate: null,
        machine: null
      });
    }
  }, 500);

  const fetchPaginatedShopOrders = async (page = 1, pageSize = 10, sortBy = '-enteredDate', filter = {}, search = '', searchField = 'salesOrderNumber') => {
    setLoading(true);
    ShopOrdersStore.fetchPaginatedShopOrders(page, pageSize, sortBy, filter, search, searchField).then(() => {
      setShopOrders(ShopOrdersStore.paginatedShopOrders);
      setLoading(false);
    }).catch(error => {
      if (error.response?.data?.message && error.response.data.message === 'No documents found.') {
        setShopOrders({ hasMore: false, totalCount: 0, currentPage: 0, pageSize: 10, totalPages: 0, results: [] });
        setLoading(false);
        setSnackbar({ open: true, message: 'No orders found', severity: 'info' });
        return;
      }
      setSnackbar({ open: true, message: 'Failed to fetch shop orders', severity: 'error' });
      setLoading(false);
    })
  };

  const fetchAssignedOrders = async (machine) => {
    const filter = {
      machine: machine._id,
      finishedDate: null
    };
    setLoading(true);
    ShopOrdersStore.fetchPaginatedShopOrders(1, 20, 'sequence', filter, '').then(() => {
      setAssignedOrders(toJS(ShopOrdersStore.paginatedShopOrders.results));
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch assigned orders', severity: 'error' });
      setLoading(false);
    })
  }

  const handleSave = async () => {
    assignedOrders.forEach(async (order) => {
      try {
        await ShopOrdersStore.updateShopOrder(order._id, order);
      } catch (error) {
        setSnackbar({ open: true, message: `Failed to save assignment: ${order.salesOrderNumber}`, severity: 'error' });
        return
      }
    });

    setSnackbar({ open: true, message: 'Machine assignments saved', severity: 'success' });
  }

  const columns = [
    {
      field: 'sequence',
      headerName: "Sequence",
      flex: 1,
      sortDirection: 'asc',
      disableColumnMenu: true,
      disableColumnFilter: true,
      renderCell: (params) => (
        <EditableSequenceCell
          value={params.value}
          onChange={(newValue) => {
            setAssignedOrders((prev) => {
              let updatedOrders = prev.map((order) => {
                if (order._id === params.row._id) {
                  return { ...order, sequence: +newValue };
                }
                return order;
              });

              setSortModel([{ field: 'sequence', sort: 'asc' }]);
              return updatedOrders;
            });
          }}
        />
      )
    },
    { field: 'salesOrderNumber', headerName: "Shop Order #", flex: 1, disableColumnMenu: true, disableColumnFilter: true, sortable: false },
    { field: 'customerName', headerName: "Customer", flex: 2, disableColumnMenu: true, disableColumnFilter: true, sortable: false },
    { field: 'millJobNumber', headerName: "Mill #", flex: 1, disableColumnMenu: true, disableColumnFilter: true, sortable: false },
    { field: 'tubeOuterDiameter', headerName: "Tube OD", flex: 1, disableColumnMenu: true, disableColumnFilter: true, sortable: false },
    {
      field: 'honeInnerDiameter',
      headerName: "Hone to Max",
      flex: 1,
      valueFormatter: ({ value }) => value.upperLimit ? value.upperLimit : 0,
      disableColumnMenu: true, disableColumnFilter: true,
      sortable: false
    },
    { field: 'totalHours', headerName: "Hours", flex: 1, disableColumnMenu: true, disableColumnFilter: true, sortable: false },
    {
      field: 'totalPieces',
      headerName: "Pieces",
      flex: 1,
      valueFormatter: ({ value }) => value.actual ? value.actual : 0,
      disableColumnMenu: true, disableColumnFilter: true,
      sortable: false
    },
    {
      field: 'totalFeet',
      headerName: "Feet",
      flex: 1,
      valueFormatter: ({ value }) => value.actual ? value.actual : 0,
      disableColumnMenu: true, disableColumnFilter: true,
      sortable: false
    }
  ];

  useEffect(() => {
    const filter = {
      finishedDate: null,
      machine: null,
      receivedDate: {
        $ne: null
      }
    };
    fetchPaginatedShopOrders(1, 100, 'enteredDate', filter, '');
  }, []);

  const EditableSequenceCell = ({ value, onChange }) => {
    const [editingValue, setEditingValue] = useState(value);

    const handleChange = (event) => {
      setEditingValue(event.target.value);
    };

    const handleBlur = () => {
      onChange(editingValue);
    };

    return (
      <TextField
        value={editingValue}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        size="small"
      />
    );
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Machine Assignment
      </Typography>

      <MachineSearch label="Select Machine" handleChange={handleMachineChoice} />

      {machine && (
        <Box marginTop={3}>
          <Autocomplete
            disablePortal
            fullWidth
            id="orders"
            getOptionLabel={(option) => `${option.salesOrderNumber} - ${option.customerName} - ${option.millJobNumber} - ${format(new Date(option.receivedDate), 'MM/dd/yyyy')}`}
            options={shopOrders?.results}
            onChange={(event, newValue) => {
              handleAssignOrder(newValue);
            }}
            onInputChange={handleInputChange}
            renderInput={(params) =>
              <TextField
                {...params}
                label="Search Open Orders"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            }
          />
          <Box>
            <Typography variant="h5" componenet="h2" gutterBottom align='center' margin={2}>
              Machine Assignments for {machine.name} / {machine.operators.join(', ')}
            </Typography>
          </Box>
          <Box>
            <DataGridPro
              rows={assignedOrders}
              columns={columns}
              loading={loading}
              getRowId={(row) => row._id}
              disableColumnSelector={true}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
            />

            <TablePagination
              component="div"
              count={shopOrders?.totalCount}
              page={shopOrders?.currentPage - 1}
              onPageChange={handlePageChange}
              rowsPerPage={shopOrders?.pageSize}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={[10]}
            />
          </Box>
          <Box margin={2}>
            <Button onClick={handleSave}>Save</Button>
          </Box>
        </Box>
      )}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )

});

export default MachineAssignment;
