import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    Typography, Box, Button, Snackbar, Grid, TextField, Modal, Paper, useTheme, IconButton
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import dayjs from 'dayjs';
import { PDFViewer } from '@react-pdf/renderer';
import PlugsPDF from '../../../pdf/PlugsPDF';
import CloseIcon from '@mui/icons-material/Close';
import CustomerSearch from '../../../components/search/CustomerSearch';
import MachineSearch from '../../../components/search/MachineSearch';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const PlugReport = observer(() => {
    const [report, setReport] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [viewPdf, setViewPdf] = useState(false);
    const theme = useTheme();
    const [customerId, setCustomerId] = useState('');
    const [machineId, setMachineId] = useState('');
    const [serviceOrderNumber, setServiceOrderNumber] = useState('');
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');
    const [millNo, setMillNo] = useState('');
    const [dueDateFrom, setDueDateFrom] = useState(null);
    const [dueDateTo, setDueDateTo] = useState(null);
    const [promiseDateFrom, setPromiseDateFrom] = useState(null);
    const [promiseDateTo, setPromiseDateTo] = useState(null);
    const [enteredDateFrom, setEnteredDateFrom] = useState(null);
    const [enteredDateTo, setEnteredDateTo] = useState(null);

    /**
     * Get the plugs report
     * @param {*} startDate - YYYY-MM-DD
     * @param {*} endDate - YYYY-MM-DD
     */
    const fetchPlugsReport = async (params) => {
        setLoading(true);
        ReportsStore.fetchPlugsReport(params).then(() => {
            setReport(ReportsStore.detailedReports.plugs.result);
            setLoading(false);
        }).catch(error => {
            setSnackbar({ open: true, message: 'Failed to fetch plugs report', severity: 'error' });
            setLoading(false);
        });
    }

    const generateReports = () => {
        const dueStart = dueDateFrom ? dayjs(dueDateFrom).format('YYYY-MM-DD') : '';
        const dueEnd = dueDateTo ? dayjs(dueDateTo).format('YYYY-MM-DD') : '';
        const promiseStart = promiseDateFrom ? dayjs(promiseDateFrom).format('YYYY-MM-DD') : '';
        const promiseEnd = promiseDateTo ? dayjs(promiseDateTo).format('YYYY-MM-DD') : '';
        const enteredStart = enteredDateFrom ? dayjs(enteredDateFrom).format('YYYY-MM-DD') : '';
        const enteredEnd = enteredDateTo ? dayjs(enteredDateTo).format('YYYY-MM-DD') : '';

        const plugsReportParams = {
            customerId,
            machineId,
            serviceOrderNumber,
            purchaseOrderNumber,
            dueDateFrom: dueStart,
            dueDateTo: dueEnd,
            millNo,
            promiseDateFrom: promiseStart,
            promiseDateTo: promiseEnd,
            enteredDateFrom: enteredStart,
            enteredDateTo: enteredEnd
        }

        fetchPlugsReport(plugsReportParams);
    };

    const handleClose = () => {
        setViewPdf(false);
    }

    const columns = [
        { field: 'honeToMax', headerName: 'Hone to Max', flex: 1 },
        { field: 'plugs', headerName: 'Plugs', flex: 1 }
    ]

    return (
        <Box sx={{ height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: 2 }}>
                    <Typography variant="h4">Plugs Report</Typography>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={3} padding={2}>


                        {/* Customer Name Dropdown */}
                        <Grid item xs={12} md={6}>
                            <CustomerSearch
                                sx={{ width: '100%' }}
                                handleChange={(event) => setCustomerId(event ? event._id : '')}
                                showAdd={false}
                                marginTop={0}
                                showInactive={true}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <MachineSearch handleChange={(event) => setMachineId(event ? event._id : '')} marginTop={0} />
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '10px',
                                    width: '100%',
                                }}
                            >
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-flexible-1"
                                    label="Service Order #"
                                    multiline
                                    onChange={(event) => setServiceOrderNumber(event.target.value)}
                                    maxRows={4}
                                />
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-flexible-2"
                                    label="Purchase Order #"
                                    multiline
                                    onChange={(event) => setPurchaseOrderNumber(event.target.value)}
                                    maxRows={4}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <DatePicker
                                    label="Due Date From"
                                    value={dueDateFrom}
                                    onChange={(newValue) => setDueDateFrom(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                                <DatePicker
                                    label="Due Date To"
                                    value={dueDateTo}
                                    onChange={(newValue) => setDueDateTo(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '10px',
                                    width: '100%',
                                }}
                            >
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-flexible-1"
                                    label="Mill/Job #"
                                    multiline
                                    onChange={(event) => setMillNo(event.target.value)}
                                    maxRows={4}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <DatePicker
                                    label="Promise Date From"
                                    value={promiseDateFrom}
                                    onChange={(newValue) => setPromiseDateFrom(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                                <DatePicker
                                    label="Promise Date To"
                                    value={promiseDateTo}
                                    onChange={(newValue) => setPromiseDateTo(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <DatePicker
                                    label="Entered Date From"
                                    value={enteredDateFrom}
                                    onChange={(newValue) => setEnteredDateFrom(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                                <DatePicker
                                    label="Entered Date To"
                                    value={enteredDateTo}
                                    onChange={(newValue) => setEnteredDateTo(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
                            <Button onClick={() => generateReports()} sx={{ width: '200px', fontWeight: 'bold' }}>Generate Report</Button>
                            {report.length > 0 && (
                                <Button onClick={() => setViewPdf(true)} sx={{ width: '200px', fontWeight: 'bold' }}>View as PDF</Button>
                            )}
                        </Box>
                    </Grid>
                </LocalizationProvider>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 4 }}>
                {viewPdf ? (
                    <Modal
                        open={viewPdf}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
                            <IconButton
                                onClick={handleClose}
                                sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <PDFViewer width={'100%'} height={'100%'}>
                                <PlugsPDF
                                    report={report}
                                    startDate={dayjs(dueDateFrom).format('MM/DD/YYYY')}
                                    endDate={dayjs(dueDateTo).format('MM/DD/YYYY')}
                                />
                            </PDFViewer>
                        </Paper>
                    </Modal>
                ) : (
                    <DataGridPro
                        rows={report}
                        columns={columns}
                        autoHeight
                        loading={loading}
                        getRowId={(row) => row.honeToMax}
                    />
                )}

            </Box>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );

});

export default PlugReport;