import React, { useState } from 'react';
import {
  Box, Select, MenuItem, Paper, FormControl, InputLabel
} from '@mui/material';
import OpenInvoiceReport from './OpenInvoiceReport';
import AccountCodesReport from './AccountCodesReport';
import PayablesByAccountReport from './PayablesByAccount';
import PayablesByVendorReport from './PayablesByVendorReport';
import AccountsPayableReport from './AccountsPayableReport';
import CheckRegisterReport from './CheckRegisterReport';

const Payables = () => {
  const [report, setReport] = useState(0);

  const handleReportChange = (event) => {
    setReport(event.target.value);
  };

  const renderReport = () => {
    switch (report) {
      case 0: 
        return <OpenInvoiceReport />;
      // case 1: 
      //   return <AccountCodesReport />;
      // case 2: 
      //   return <PayablesByAccountReport />;
      case 3:
        return <PayablesByVendorReport />;
      // case 4:
      //   return <AccountsPayableReport />;
      case 5:
        return <CheckRegisterReport />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box sx={{ margin: 2, marginTop: 4 }}>
        <FormControl fullWidth>
          <InputLabel id="payables-report-select-label">Select Report</InputLabel>
          <Select
            labelId="payables-report-select-label"
            value={report}
            onChange={handleReportChange}
          >
            <MenuItem value={0}>Open Invoice</MenuItem>
            {/* <MenuItem value={1}>Account Codes</MenuItem> */}
            {/* <MenuItem value={2}>Payables By Account</MenuItem> */}
            <MenuItem value={3}>Payables By Vendor</MenuItem>
            {/* <MenuItem value={4}>Accounts Payable</MenuItem> */}
            <MenuItem value={5}>Check Register</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {renderReport()}
    </Box>
  );
};

export default Payables;
