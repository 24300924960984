import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    marginBottom: 20,
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
    borderBottom: '1px solid #000',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 20,
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '12.5%',
    padding: 5,
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  tableCell: {
    fontSize: 10,
  },
});

const ProductionListPDF = ({ data, startDate, endDate }) => {
  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.title}>Production List Report: {startDate || 'N/A'} - {endDate || 'N/A'}</Text>
        {data.map(machine => (
          <View key={machine._id} style={styles.section}>
            <Text style={styles.header}>Machine: {machine.machineDetails.name} / {machine.machineDetails.operators.join(', ')}</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>Order#</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>Mill No</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>Pieces</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>OD</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>ID</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>Feet</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>OAL</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>Weight</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>Hours</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>Due</Text>
                </View>
              </View>
              {machine.jobs.map(job => (
                <View key={job.soNumber} style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{job.soNumber}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{job.millNo}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{job.pieces}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{job.outerDiameter.$numberDecimal}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{job.innerDiameter.$numberDecimal}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{job.feet.$numberDecimal}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{job.OAL.$numberDecimal}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{job.weight.$numberDecimal}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{job.hours.$numberDecimal}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{dayjs(job.dueDate).format('MM/DD/YYYY')}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default ProductionListPDF;
