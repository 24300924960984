import { makeAutoObservable } from "mobx";
import apiHandler from "../handlers/ApiHandler";

class MachineStore {
  machine = null;
  paginatedMachines = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  async fetchPaginatedMachines(
    page = 1,
    pageSize = 10,
    sortBy = "name",
    filter = {},
    search = "",
    searchField = "name"
  ) {
    try {
      const { results, ...pagination } = await apiHandler.get(
        `/machines?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(
          filter
        )}&search=${search}&searchField=${searchField}`
      );

      // Post-fetch sorting of machines by numeric value of `name`
      const sortedResults = results.sort((a, b) => {
        const nameA = parseInt(a.name, 10);
        const nameB = parseInt(b.name, 10);
        return nameA - nameB;
      });

      // Assuming the actual machine data is in the response body
      this.paginatedMachines = { ...pagination, results: sortedResults };
      return { ...pagination, results: sortedResults };
    } catch (error) {
      console.error("Error fetching paginated machines:", error);
      return null;
    }
  }

  async fetchMachineById(machineId) {
    try {
      const response = await apiHandler.get(`/machines/${machineId}`);
      // If the response contains a null contact, replace it with an empty array
      this.machine = await response;
      return response;
    } catch (error) {
      console.error("Error fetching machine by ID:", error);
      return null;
    }
  }

  async createMachine(machineData) {
    try {
      const newMachine = await apiHandler.post("/machines", machineData);
      this.paginatedMachines.results.push(newMachine);
    } catch (error) {
      console.error("Error creating machine:", error);
    }
  }

  async updateMachine(machineId, machineData) {
    try {
      const updatedMachine = await apiHandler.put(
        `/machines/${machineId}`,
        machineData
      );
      const index = this.paginatedMachines.results.findIndex(
        (machine) => machine._id === machineId
      );
      if (index > -1) {
        this.paginatedMachines.results[index] = updatedMachine;
      }
    } catch (error) {
      console.error("Error updating machine:", error);
    }
  }

  async deleteMachine(machineId) {
    try {
      await apiHandler.delete(`/machines/${machineId}`);
      this.paginatedMachines.results = this.paginatedMachines.results.filter(
        (machine) => machine._id !== machineId
      );
    } catch (error) {
      console.error("Error deleting machine:", error);
    }
  }
}

export default new MachineStore();
