import {
  Box,
  TextField,
  Grid,
  Button,
  Typography,
  FormControlLabel
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const InvoiceForm = ({ invoice, onSubmit, onDelete, onChange, back }) => {

  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange({
      ...invoice,
      [name]: value
    });
  }

  const handleSubChange = (type, event) => {
    onChange({
      ...invoice,
      [type]: { ...invoice[type], [event.target.name]: event.target.value },
    });
  }

  const handleDateChange = (name, date) => {
    const validDate = date instanceof Date && !isNaN(date) ? date : null;
    onChange({
      ...invoice,
      [name]: validDate
    });
  }

  const initialDates = {
    invoiceDate: invoice.invoiceDate ? new Date(invoice.invoiceDate) : null,
    discountDate: invoice.dueDate ? new Date(invoice.dueDate) : null,
    paymentDate: invoice.paymentDate ? new Date(invoice.paymentDate) : null,
  }

  return (
    <Box sx={{ pt: 2 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 3 }}>
              <TextField fullWidth label="SO#" value={invoice.salesOrderNumber} onChange={handleChange} margin="normal" />
              <TextField fullWidth label="Customer Name" disabled value={invoice.customerName || invoice.customer?.companyName} margin="normal" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField fullWidth label="Invoice Number" name="invoiceNumber" value={invoice.invoiceNumber} onChange={handleChange} margin="normal" />
            <DatePicker
              sx={{ mt: 2, mb: 2, width: '100%' }}
              label="Invoice Date"
              name="invoiceDate"
              value={initialDates.invoiceDate}
              onChange={(date) => handleDateChange('invoiceDate', date)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField fullWidth type="number" label="Bundles" name="bundles" value={invoice.bundles} onChange={handleChange} margin="normal" />
            <TextField fullWidth type="number" label="Pieces" name="pieces" value={invoice.pieces} onChange={handleChange} margin="normal" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField fullWidth type="number" label="Total Feet" name="feet" value={invoice.feet} onChange={handleChange} margin="normal" />
            <TextField fullWidth type="number" label="Weight" name="weight" value={invoice.weight} onChange={handleChange} margin="normal" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField fullWidth type="number" label="Charges" name="additionalCharges" value={invoice.additionalCharges} onChange={handleChange} margin="normal" />
            <TextField fullWidth type="number" label="Total" name="totalAmount" value={invoice.totalAmount} onChange={handleChange} margin="normal" />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', pt: 2 }}>
          <Button variant="contained" onClick={onSubmit}>Add Invoice</Button>
        </Box>
      </LocalizationProvider>
    </Box >
  )
};

export default InvoiceForm;