import { makeAutoObservable } from "mobx";
import apiHandler from "../handlers/ApiHandler";

class ShopOrdersStore {
  paginatedShopOrders = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: [],
  }

  constructor() {
    makeAutoObservable(this);
  }

  async fetchPaginatedShopOrders(page = 1, pageSize = 10, sortBy = '-dueDate', filter = {}, search = '', searchField = 'orderNumber') {
    try {
      const { results, ...pagination } = await apiHandler.get(`/orders?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`);
      this.paginatedShopOrders = { ...pagination, results: results };
      return { results, ...pagination };
    } catch (error) {
      throw (error);
    }
  }

  async fetchShopOrderById(shopOrderId) {
    try {
      const shopOrder = await apiHandler.get(`/orders/${shopOrderId}`);
      return shopOrder;
    } catch (error) {
      console.error("Error fetching shop order by ID:", error);
    }
  }

  async createShopOrder(shopOrderData) {
    try {
      const newShopOrder = await apiHandler.post('/orders', shopOrderData);
      this.paginatedShopOrders.results.push(newShopOrder);
    } catch (error) {
      console.error("Error creating shop order:", error);
    }
  }

  async updateShopOrder(shopOrderId, shopOrderData) {
    try {
      const updatedShopOrder = await apiHandler.put(`/orders/${shopOrderId}`, shopOrderData);
      const index = this.paginatedShopOrders.results.findIndex(shopOrder => shopOrder._id === shopOrderId);
      if (index > -1) {
        this.paginatedShopOrders.results[index] = updatedShopOrder;
      }
    } catch (error) {
      console.error("Error updating shop order:", error);
    }
  }

  async deleteShopOrder(shopOrderId) {
    try {
      await apiHandler.delete(`/orders/${shopOrderId}`);
      this.paginatedShopOrders.results = this.paginatedShopOrders.results.filter(shopOrder => shopOrder._id !== shopOrderId);
    } catch (error) {
      console.error("Error deleting shop order:", error);
    }
  }
}

export default new ShopOrdersStore();