import React, { useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Checkbox, FormControlLabel, InputAdornment, TextField, ListItemText, Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const formatFieldName = (fieldName) => {
  if (fieldName.toUpperCase() === fieldName) {
    return fieldName;
  }

  return fieldName
    .replace(/([A-Z])([a-z])/g, ' $1$2')
    .replace(/_/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase())
    .trim();
};

const DynamicSearchFilter = ({ defaultSort, onSearch, objectInterface, label }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({});
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState(defaultSort || '');
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSearchSubmit = () => {
    onSearch(searchQuery, filterCriteria, `${sortOrder === 'desc' ? '-' : ''}${sortBy}`);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterCriteria({ ...filterCriteria, [event.target.name]: event.target.value });
  };

  const handleRangeChange = (event, minMax) => {
    if (minMax === 'min') {
      setFilterCriteria(({ ...filterCriteria, [event.target.name]: { ...filterCriteria[event.target.name], min: event.target.value } }))
    } else if (minMax === 'max') {
      setFilterCriteria(({ ...filterCriteria, [event.target.name]: { ...filterCriteria[event.target.name], max: event.target.value } }))
    }
  }

  const handleCheckboxChange = (event) => {
    setFilterCriteria({ ...filterCriteria, [event.target.name]: event.target.checked });
  };

  const handleDateChange = (name) => (date) => {
    setFilterCriteria({ ...filterCriteria, [name]: date });
  };

  const handleSortMenuOpen = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortAnchorEl(null);
  };

  const handleSortOrderChange = (order) => () => {
    setSortOrder(order);
    handleSortMenuClose();
  };

  const handleFilterMenuOpen = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '100%', m: 2 }}>
      <TextField
        label={label || "Search"}
        variant="outlined"
        value={searchQuery}
        onChange={handleSearchChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleSearchSubmit();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearchSubmit}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
      />
      <IconButton onClick={handleFilterMenuOpen}>
        <FilterListIcon />
      </IconButton>
      <Menu
        anchorEl={filterAnchorEl}
        open={Boolean(filterAnchorEl)}
        onClose={handleFilterMenuClose}
      >
        {Object.keys(objectInterface).map((key) => {
          const type = typeof objectInterface[key];
          const formattedKey = formatFieldName(key);
          if (type === 'boolean') {
            return (
              <MenuItem key={key}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterCriteria[key] || false}
                      onChange={handleCheckboxChange}
                      name={key}
                    />
                  }
                  label={formattedKey}
                />
              </MenuItem>
            );
          } else if (type === 'object' && objectInterface[key] instanceof Date) {
            return (
              <MenuItem key={key}>
                <MobileDatePicker
                  sx={{ marginRight: 1 }}
                  label={`${formattedKey} From`}
                  value={filterCriteria[`${key}From`] || null}
                  onChange={handleDateChange(`${key}From`)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <MobileDatePicker
                  sx={{ marginLeft: 1 }}
                  label={`${formattedKey} To`}
                  value={filterCriteria[`${key}To`] || null}
                  onChange={handleDateChange(`${key}To`)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </MenuItem>
            );
          } else if (type === 'object' && 'min' in objectInterface[key] && 'max' in objectInterface[key]) {
            return (
              <MenuItem key={key}>
                <TextField
                  sx={{ marginRight: 1 }}
                  label={`${formattedKey} From`}
                  value={filterCriteria[key]?.min || 0}
                  onChange={(e) => handleRangeChange(e, 'min')}
                  name={`${key}`}
                  variant="outlined"
                />
                <TextField
                  sx={{ marginLeft: 1 }}
                  label={`${formattedKey} To`}
                  value={filterCriteria[key]?.max || 0}
                  onChange={(e) => handleRangeChange(e, 'max')}
                  name={`${key}`}
                  variant="outlined"
                />
              </MenuItem>
            );
          }
          return null;
        })}
      </Menu>
      <IconButton onClick={handleSortMenuOpen}>
        <SortIcon />
      </IconButton>
      <Menu
        anchorEl={sortAnchorEl}
        open={Boolean(sortAnchorEl)}
        onClose={handleSortMenuClose}
      >
        <MenuItem>
          <ListItemText primary="Sort Field" />
          <ArrowDropDownIcon />
        </MenuItem>
        <Divider />
        {Object.keys(objectInterface).map((key) => (
          <MenuItem key={key} onClick={() => setSortBy(key)}>
            {formatFieldName(key)}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={handleSortOrderChange('asc')}>
          Ascending
        </MenuItem>
        <MenuItem onClick={handleSortOrderChange('desc')}>
          Descending
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default DynamicSearchFilter;