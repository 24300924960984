import { makeAutoObservable } from "mobx";
import apiHandler from "../handlers/ApiHandler";

class CustomerStore {
  customers = [];
  customer = null;
  paginatedCustomers = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: [],
  };
  shippingAddresses = [];
  contacts = [];

  constructor() {
    makeAutoObservable(this);
  }

  async fetchCustomers() {
    try {
      const { results } = await apiHandler.get('/customers');
      // Assuming the actual customer data is in the response body
      this.customers = results
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  }

  async fetchPaginatedCustomers(
    page = 1,
    pageSize = 10,
    sortBy = 'companyName',
    filter = {},
    search = '',
    searchField = 'companyName'
  ) {
    try {
      const { results, ...pagination } = await apiHandler.get(
        `/customers?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`
      );
      // Assuming the actual customer data is in the response body
      this.paginatedCustomers = { ...pagination, results: results };
      return { results, ...pagination };
    } catch (error) {
      console.error("Error fetching paginated customers:", error);
      throw error;
    }
  };

  async fetchCustomerById(customerId) {
    try {
      const response = await apiHandler.get(`/customers/${customerId}`);
      // If the response contains a null contact, replace it with an empty array
      if (response.contacts && response.contacts.length > 0 && response.contacts[0] === null) {
        response.contacts = [];
      }
      this.customer = await response;
      return response;
    } catch (error) {
      console.error("Error fetching customer by ID:", error);
    }
  }

  async createCustomer(customerData) {
    try {
      const response = await apiHandler.post('/customers', customerData);
      const newCustomer = response
      this.customers.push(newCustomer);
    } catch (error) {
      console.error("Error creating customer:", error);
    }
  }

  async updateCustomer(customerId, customerData) {
    try {
      const response = await apiHandler.put(`/customers/${customerId}`, customerData);
      const updatedCustomer = response
      const index = this.customers.findIndex(customer => customer._id === customerId);
      if (index > -1) {
        this.customers[index] = updatedCustomer;
      }
      this.customer = updatedCustomer;
    } catch (error) {
      console.error("Error updating customer:", error);
    }
  }

  async deleteCustomer(customerId) {
    try {
      await apiHandler.delete(`/customers/${customerId}`);
      this.customers = this.customers.filter(customer => customer._id !== customerId);
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
  }

  async getCustomerShippingAddresses(customerId) {
    try { 
      const response = await apiHandler.get(`/customers/${customerId}/shippingAddresses`);
      this.shippingAddresses = response;
      return response;
    } catch (error) {
      console.error("Error fetching customer shipping addresses:", error);
      throw error;
    }
  }

  async getCustomerShippingAddress(customerId, addressId) {
    try {
      const response = await apiHandler.get(`/customers/${customerId}/shippingAddresses/${addressId}`);
      return response;
    } catch (error) {
      console.error("Error fetching customer shipping address:", error);
    }
  }

  async createCustomerShippingAddress(customerId, addressData) {
    try {
      const response = await apiHandler.post(`/customers/${customerId}/shippingAddresses`, addressData);
      this.shippingAddresses.push(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateCustomerShippingAddress(customerId, addressId, addressData) {
    try {
      const response = await apiHandler.put(`/customers/${customerId}/shippingAddresses/${addressId}`, addressData);
      const index = this.shippingAddresses.findIndex(address => address._id === addressId);
      if (index > -1) {
        this.shippingAddresses[index] = response;
      }
      return response;
    } catch (error) {
      console.error("Error updating customer shipping address:", error);
      throw error;
    }
  }

  async deleteCustomerShippingAddress(customerId, addressId) {
    try {
      await apiHandler.delete(`/customers/${customerId}/shippingAddresses/${addressId}`);
      this.shippingAddresses = this.shippingAddresses.filter(address => address._id !== addressId);
    } catch (error) {
      console.error("Error deleting customer shipping address:", error);
    }
  }

  async getCustomerContacts(customerId) {
    try {
      const response = await apiHandler.get(`/customers/${customerId}/contacts`);
      return response;
    } catch (error) {
      console.error("Error fetching customer contacts:", error);
    }
  }

  async getCustomerContact(customerId, contactId) {
    try {
      const response = await apiHandler.get(`/customers/${customerId}/contacts/${contactId}`);
      return response; 
    } catch (error) {
      console.error("Error fetching customer contact:", error);
    }
  }

  async createCustomerContact(customerId, contactData) {
    try {
      const response = await apiHandler.post(`/customers/${customerId}/contacts`, contactData);
      this.contacts.push(response);
    } catch (error) {
      console.error("Error creating customer contact:", error);
    }
  }

  async updateCustomerContact(customerId, contactId, contactData) {
    try {
      const response = await apiHandler.put(`/customers/${customerId}/contacts/${contactId}`, contactData);
      const index = this.contacts.findIndex(contact => contact._id === contactId);
      if (index > -1) {
        this.contacts[index] = response;
      }
    } catch (error) {
      console.error("Error updating customer contact:", error);
    }
  }

  async deleteCustomerContact(customerId, contactId) {
    try {
      await apiHandler.delete(`/customers/${customerId}/contacts/${contactId}`);
      this.contacts = this.contacts.filter(contact => contact._id !== contactId);
    } catch (error) {
      console.error("Error deleting customer contact:", error);
    }
  }

  // Additional methods as needed...
}

export default new CustomerStore();
