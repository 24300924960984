import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Snackbar, Alert, List, ListItemButton, ListItemText, Button, Divider, Modal, Paper, IconButton, useTheme } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import DynamicSearchFilter from '../../components/DynamicSearchFilter';
import InvoiceStore from '../../stores/InvoiceStore';
import ReceivablesStore from '../../stores/ReceivablesStore';
import InvoiceForm from './InvoiceForm';
import ShopOrdersStore from '../../stores/ShopOrdersStore';
import { format } from 'date-fns';
import { PDFViewer } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import InvoicePDF from '../../pdf/InvoicePDF';
import authStore from '../../stores/AuthStore';

const AddReceivable = () => {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [shopOrders, setShopOrders] = useState(null);
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState({
    salesOrderNumber: '',
    invoiceNumber: '',
    invoiceDate: new Date(),
    customer: '',
    customerName: '',
    bundles: 0,
    pieces: 0,
    feet: 0,
    weight: 0,
    additionalCharges: 0,
    totalAmount: 0
  });
  const [viewPdf, setViewPdf] = useState(false);
  const [pdfData, setPdfData] = useState(null);

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (invoiceId && invoiceId !== 'new' && !selectedOrder) {
      fetchInvoice(invoiceId);
      return;
    }
    if (selectedOrder) {
      const invoiceFilter = { order: selectedOrder._id };
      fetchOrderInvoices(1, 10, '-invoiceDate', invoiceFilter);
      generateNewInvoiceNumber().then((invoiceNumber) => {
        setInvoice({ ...invoice, invoiceNumber });
      });
    }
  }, [selectedOrder]);

  const fetchOrderInvoices = async (page, pageSize, sortBy, filter, search, searchField) => {
    setLoading(true);
    try {
      const { results } = await InvoiceStore.fetchPaginatedInvoices(page, pageSize, sortBy, filter, search, searchField);
      setInvoices(results);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to fetch invoices', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchInvoice = async (invoiceId) => {
    const filter = { _id: invoiceId };
    const paginatedInvoice = await InvoiceStore.fetchPaginatedInvoices(1, 1, '-invoiceDate', filter);
    const invoice = paginatedInvoice.results[0];
    if (invoice && invoice.order) {
      const order = await ShopOrdersStore.fetchShopOrderById(invoice.order);
      handleOrderSelect(order);
    } else {
      setSnackbar({ open: true, message: 'Invoice not found', severity: 'error' });
    }
  };

  const goBack = () => {
    navigate('/receivables');
  };

  const generateNewInvoiceNumber = async () => {
    try {
      const { results } = await InvoiceStore.fetchPaginatedInvoices(1, 30, '-invoiceDate,-invoiceNumber');
      const lastInvoice = results[0];
      const lastInvoiceNumber = lastInvoice.invoiceNumber;
      const invoiceNumber = +lastInvoiceNumber;
      return invoiceNumber + 1;
    } catch (error) {
      console.error('Error generating new invoice number:', error);
    }
  };

  const handleSearch = (search) => {
    setSelectedOrder(null);
    if (search.length > 0) {
      fetchPaginatedShopOrders(1, 5, '-enteredDate', {}, search, 'salesOrderNumber');
    }
  };

  const fetchPaginatedShopOrders = async (page = 1, pageSize = 5, sortBy = '-enteredDate', filter = {}, search = '', searchField = '') => {
    setLoading(true);
    ShopOrdersStore.fetchPaginatedShopOrders(page, pageSize, sortBy, filter, search, searchField)
      .then(() => {
        setShopOrders(ShopOrdersStore.paginatedShopOrders);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data?.message === 'No documents found.') {
          setShopOrders({ hasMore: false, totalCount: 0, currentPage: 0, pageSize: 5, totalPages: 0, results: [] });
          setSnackbar({ open: true, message: 'No orders found', severity: 'info' });
        } else {
          setSnackbar({ open: true, message: 'Failed to fetch shop orders', severity: 'error' });
        }
        setLoading(false);
      });
  };

  const handleOrderSelect = (order) => {
    setSelectedOrder(order);
    setInvoice({
      ...invoice,
      salesOrderNumber: order.salesOrderNumber,
      customerName: order.customerName,
      bundles: order.bundleCount,
      pieces: order.totalPieces?.actual || 0,
      feet: order.totalFeet?.actual || 0,
      weight: order.weight,
      additionalCharges: order.charges,
      totalAmount: order.total,
      customerPONumber: order.customerPONumber,
      millNo: order.millJobNumber,
      billingAddress: order.billingAddress,
      shippingAddress: order.shippingAddress,
      outerHone: order.tubeOuterDiameter.toFixed(3) || 'N/A',
      innerHone: order.honeInnerDiameter.upperLimit.toFixed(3) || 'N/A',
      orderComments: order.comments,
      costPerFoot: order.costPerFoot.toFixed(2) || 'N/A',
      oal: order.OAL,
      customer: order.customer,
      order: order._id
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const updateInvoice = (updatedInvoice) => {
    setInvoice(updatedInvoice);
  };

  const handleAddInvoice = async () => {
    const newInvoice = { ...invoice, createdBy: authStore.userId };
    try {
      await InvoiceStore.createInvoice(newInvoice);
      setSnackbar({ open: true, message: 'Invoice added successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to add invoice', severity: 'error' });
    }
    const invoiceFilter = { order: selectedOrder._id };
    fetchOrderInvoices(1, 10, '-invoiceDate', invoiceFilter);
    generateNewInvoiceNumber().then((invoiceNumber) => {
      setInvoice({ ...invoice, invoiceNumber });
    });
  };

  const printPdf = async (invoice) => {
    const pdfData = {
      ...invoice,
      salesOrderNumber: selectedOrder.salesOrderNumber,
      customerName: selectedOrder.customerName,
      bundles: selectedOrder.bundleCount,
      pieces: selectedOrder.totalPieces?.actual || 0,
      feet: selectedOrder.totalFeet?.actual || 0,
      weight: selectedOrder.weight,
      additionalCharges: selectedOrder.charges,
      totalAmount: selectedOrder.total,
      customerPONumber: selectedOrder.customerPONumber,
      millNo: selectedOrder.millJobNumber,
      billingAddress: selectedOrder.billingAddress,
      shippingAddress: selectedOrder.shippingAddress,
      outerHone: selectedOrder.tubeOuterDiameter.toFixed(3) || 'N/A',
      innerHone: selectedOrder.honeInnerDiameter.upperLimit.toFixed(3) || 'N/A',
      orderComments: selectedOrder.comments,
      costPerFoot: selectedOrder.costPerFoot.toFixed(2) || 'N/A',
      oal: selectedOrder.OAL,
    };

    setPdfData(pdfData);
    setViewPdf(true);
  };

  const handleDeleteInvoice = async (invoice) => {
    try {
      await InvoiceStore.deleteInvoice(invoice._id);
      setSnackbar({ open: true, message: 'Invoice deleted successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to delete invoice', severity: 'error' });
    }
    const invoiceFilter = { order: selectedOrder._id };
    fetchOrderInvoices(1, 10, '-invoiceDate', invoiceFilter);
    generateNewInvoiceNumber().then((invoiceNumber) => {
      setInvoice({ ...invoice, invoiceNumber });
    });
  };

  const handleClose = () => {
    setViewPdf(false);
    setPdfData(null);
  };

  const columns = [
    { field: 'invoiceNumber', headerName: 'Invoice', flex: 1, type: 'string' },
    { field: 'invoiceDate', headerName: 'Date', flex: 1, valueFormatter: (params) => format(new Date(params.value), 'MM/dd/yyyy') },
    { field: 'bundles', headerName: 'Bundles', flex: 1, type: 'number' },
    { field: 'pieces', headerName: 'Pieces', flex: 1, type: 'number' },
    { field: 'feet', headerName: 'Total Feet', flex: 1, type: 'number' },
    { field: 'weight', headerName: 'Weight', flex: 1, type: 'number' },
    { field: 'additionalCharges', headerName: 'Charges', flex: 1, type: 'number' },
    { field: 'totalAmount', headerName: 'Total', flex: 1, type: 'number' },
    {
      field: 'actions', headerName: 'Actions', flex: 1, headerAlign: 'center', align: 'center', renderCell: (params) => (
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2, width: '100%' }}>
          <Button onClick={() => printPdf(params.row)}>Print</Button>
          <IconButton color="warning" onClick={() => handleDeleteInvoice(params.row)}><DeleteIcon /></IconButton>
        </Box>
      )
    }
  ];

  return (
    <Box sx={{ m: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ m: 2 }}>{invoiceId === 'new' ? 'Add New Invoice' : 'Edit Invoices'}</Typography>
        <IconButton onClick={goBack}>
          <CloseIcon />
        </IconButton>
      </Box>
      {invoiceId === 'new' && (
        <DynamicSearchFilter
          defaultSort="-enteredDate"
          label="Search Orders"
          onSearch={handleSearch}
          objectInterface={{ /* Define your order interface here */ }}
        />
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <Box m={2}>
          {selectedOrder ? (
            <Box>
              {invoiceId === 'new' && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button variant="outlined" color="primary" onClick={() => setSelectedOrder(null)}>Change Order</Button>
                </Box>
              )}
              <InvoiceForm invoice={invoice} onChange={updateInvoice} onSubmit={handleAddInvoice} />
              <Typography variant="h5" sx={{ m: 2 }}>Existing Invoices</Typography>
              <DataGridPro
                columns={columns}
                rows={invoices}
                getRowId={(row) => row._id}
              />
            </Box>
          ) : (
            <Box>
              <List>
                {shopOrders?.results.map((order) => (
                  <Box key={order._id}>
                    <ListItemButton onClick={() => handleOrderSelect(order)}>
                      <ListItemText
                        primary={`Order: ${order.salesOrderNumber}`}
                        secondary={`Customer: ${order.customer.companyName || 'N/A'} | Mill No: ${order.millJobNumber}`}
                        secondaryTypographyProps={{ variant: 'body2', margin: '-2px' }}
                      />
                    </ListItemButton>
                    <Divider sx={{ width: '100%' }} />
                  </Box>
                ))}
              </List>
            </Box>
          )}
        </Box>
      )}
      {viewPdf && (
        <Modal
          open={viewPdf}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
            <IconButton
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
            >
              <CloseIcon />
            </IconButton>
            <PDFViewer width="100%" height="100%">
              <InvoicePDF invoice={pdfData} />
            </PDFViewer>
          </Paper>
        </Modal>
      )}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddReceivable;
