import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Snackbar, Alert, Paper } from '@mui/material';
import authStore from '../stores/AuthStore';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  const handleSubmit = async () => {
    try {
      authStore.setEmail(email);
      await authStore.forgotPassword();
      setSeverity('success');
      setSnackbarMessage('Password reset email sent');
    } catch (error) {
      setSeverity('error');
      setSnackbarMessage(error.message || 'Failed to send password reset email');
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: 400, mx: 'auto', my: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Forgot Password</Typography>
      <Box component="form" noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
        <Button variant="contained" color="primary" onClick={handleSubmit}>Send Reset Email</Button>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ForgotPasswordPage;
