import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VendorStore from '../../stores/VendorStore';
import VendorForm from './VendorForm';
import { Box, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';

const AddVendor = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  const handleSubmit = async (vendor) => {
    setLoading(true);
    try {
      await VendorStore.createVendor(vendor);
      navigate('/vendor', { state: { message: 'Vendor added successfully', severity: 'success' } });
    } catch (error) {
      console.error('Failed to add new vendor:', error);
      setSnackbar({ open: true, message: 'Failed to add new vendor. Please try again.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/vendor');
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: '' });
  };

  return (
    <Box sx={{ m: 4 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Add New Vendor</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <VendorForm onSubmit={handleSubmit} back={handleBack} />
        </>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AddVendor;
