import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import VendorStore from '../stores/VendorStore';
import DynamicSearchFilter from '../components/DynamicSearchFilter';
import { useNavigate } from 'react-router-dom';
import {
  Box, Typography, List, ListItem, Paper, CircularProgress, Snackbar, IconButton,
  ListItemText, ListItemSecondaryAction, TablePagination, useMediaQuery
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { DataGridPro } from '@mui/x-data-grid-pro';
import CheckIcon from '@mui/icons-material/Check';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Vendor = observer(() => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage]  = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState('vendorName');
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({});
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('lg'));

  useEffect(() => {
    fetchPaginatedVendors();
  }, [page, pageSize, filter, search]);

  const fetchPaginatedVendors = async () => {
    setLoading(true);
    try {
      await VendorStore.fetchPaginatedVendors(
        page,
        pageSize,
        sortBy,
        filter,
        search
      );
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to fetch vendors', severity: 'error' });
      setLoading(false);
    }
  };
  

  const handleDynamicSearch = (search, filter, sortBy) => {
    setSearchQuery(search);
    setFilterCriteria(filter);
    const updatedFilter = {
      ...filter,
      vendorName: { $ne: null, $ne: '' },
      $or: [
        { vendorName: { $regex: search, $options: 'i' } },
        { vendorId: { $regex: search, $options: 'i' } },
        { vendorOurAcct: { $regex: search, $options: 'i' } },
      ]
    };
    setPage(1);
    setFilter(updatedFilter);
  };

  const handleEditVendor = (vendorId) => {
    navigate(`/vendor/edit/${vendorId}`);
  };

  const handleCreateVendor = () => {
    navigate('/vendor/add');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };
  
  const handleLimitChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };
  

  const columns = [
    { field: 'vendorName', headerName: 'Vendor Name', flex: 1 },
    { field: 'vendorId', headerName: 'Vendor ID', flex: 1 },
    { field: 'vendorOurAcct', headerName: 'Buckeye Account #', flex: 1 },
    {
      field: 'active',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        params.value ? <CheckIcon /> : null
      )
    },
    { field: 'comments', headerName: 'Comments', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      align: 'right',
      renderCell: (params) => (
        <IconButton edge="end" aria-label="edit" onClick={() => handleEditVendor(params.row._id)}>
          <EditIcon />
        </IconButton>
      )
    }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" gutterBottom>Vendors</Typography>
          <DynamicSearchFilter
            defaultSort="vendorName"
            onSearch={handleDynamicSearch}
            objectInterface={{ name: '', vendorId: '', contactName: '' }}
          />
          <IconButton color="primary" onClick={handleCreateVendor}>
            <AddIcon />
          </IconButton>
      </Box>

      {loading && <CircularProgress />}
      {isLargeScreen ? (
        <DataGridPro
          rows={VendorStore.paginatedVendors.results}
          columns={columns}
          pageSize={VendorStore.paginatedVendors.pageSize}
          rowCount={VendorStore.paginatedVendors.totalCount}
          loading={loading}
          autoHeight
          getRowId={(row) => row._id}
        />
      ) : (
        <List sx={{ width: '100%' }}>
          {VendorStore.paginatedVendors.results.map((vendor, index) => (
            <ListItem sx={{ m: 2 }} key={index}>
              <ListItemText primary={`${vendor.vendorName}`} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={() => handleEditVendor(vendor._id)}>
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      <TablePagination
        component="div"
        count={VendorStore.paginatedVendors.totalCount}
        page={VendorStore.paginatedVendors.currentPage > 0 ? VendorStore.paginatedVendors.currentPage - 1 : 0}
        onPageChange={handlePageChange}
        rowsPerPage={VendorStore.paginatedVendors.pageSize}
        onRowsPerPageChange={handleLimitChange}
        rowsPerPageOptions={[10, 25, 50]}
      />

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default Vendor;
