import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import ShopOrdersStore from '../../stores/ShopOrdersStore';
import {
  Box, Typography, Snackbar, Alert, CircularProgress, IconButton,
  useMediaQuery, List, ListItem, ListItemText, ListItemSecondaryAction, TablePagination
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import DynamicSearchFilter from '../../components/DynamicSearchFilter';
import CustomerStore from '../../stores/CustomerStore';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE);

const OrdersPage = observer(() => {
  const [orders, setOrders] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState('');
  const [searchField, setSearchField] = useState('salesOrderNumber');
  const [sortBy, setSortBy] = useState('-enteredDate');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isLoading, setIsLoading] = useState(false);
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const navigate = useNavigate();

  useEffect(() => {
    fetchPaginatedOrders();
  }, [page, pageSize, sortBy, filter, search, searchField]);

  const fetchPaginatedOrders = async () => {
    setIsLoading(true);

    ShopOrdersStore.fetchPaginatedShopOrders(page, pageSize, sortBy, filter, search, searchField)
      .then(() => {
        setOrders(ShopOrdersStore.paginatedShopOrders);
      })
      .catch(error => {
        setSnackbar({ open: true, message: 'Failed to fetch orders', severity: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleViewOrder = (orderId) => {
    navigate(`/orders/${orderId}`);
  };

  const handleEditOrder = (orderId) => {
    navigate(`/orders/edit/${orderId}`)
  };

  const handleDeleteOrder = (orderId) => {
    // Add logic for deleting order
  };

  const handleAddOrder = () => {
    navigate('/orders/add');
  };

  const handleDynamicSearch = async (searchQuery, filterCriteria, sortBy) => {
    if (sortBy) {
      setSortBy(sortBy);
    }

    let filterBuilder = {};

    let keys = Object.keys(filterCriteria);
    if (keys.length > 0) {
      if (filterCriteria.dueDateFrom || filterCriteria.dueDateTo) {
        filterBuilder.dueDate = {};
        if (filterCriteria.dueDateFrom) filterBuilder.dueDate.$gte = new Date(filterCriteria.dueDateFrom).toISOString();
        if (filterCriteria.dueDateTo) filterBuilder.dueDate.$lte = new Date(filterCriteria.dueDateTo).toISOString();
      }
      if (filterCriteria.enteredDateFrom || filterCriteria.enteredDateTo) {
        filterBuilder.enteredDate = {};
        if (filterCriteria.enteredDateFrom) filterBuilder.enteredDate.$gte = new Date(filterCriteria.enteredDateFrom).toISOString();
        if (filterCriteria.enteredDateTo) filterBuilder.enteredDate.$lte = new Date(filterCriteria.enteredDateTo).toISOString();
      }
      if (filterCriteria.shipDateFrom || filterCriteria.shipDateTo) {
        filterBuilder.shipDate = {};
        if (filterCriteria.shipDateFrom) filterBuilder.shipDate.$gte = new Date(filterCriteria.shipDateFrom).toISOString();
        if (filterCriteria.shipDateTo) filterBuilder.shipDate.$lte = new Date(filterCriteria.shipDateTo).toISOString();
      }
      if (filterCriteria.total?.min || filterCriteria.total?.max) {
        filterBuilder.total = {};
        if (filterCriteria.total.min) filterBuilder.total.$gte = filterCriteria.total.min;
        if (filterCriteria.total.max) filterBuilder.total.$lte = filterCriteria.total.max;
      }
      if (filterCriteria.honeIDLowerLimit?.min || filterCriteria.honeIDLowerLimit?.max) {
        filterBuilder['honeInnerDiameter.lowerLimit'] = {};
        if (filterCriteria.honeIDLowerLimit.min) filterBuilder['honeInnerDiameter.lowerLimit'].$gte = filterCriteria.honeIDLowerLimit.min;
        if (filterCriteria.honeIDLowerLimit.max) filterBuilder['honeInnerDiameter.lowerLimit'].$lte = filterCriteria.honeIDLowerLimit.max;
      }
      if (filterCriteria.honeIDUpperLimit?.min || filterCriteria.honeIDUpperLimit?.max) {
        filterBuilder['honeInnerDiameter.upperLimit'] = {};
        if (filterCriteria.honeIDUpperLimit.min) filterBuilder['honeInnerDiameter.upperLimit'].$gte = filterCriteria.honeIDUpperLimit.min;
        if (filterCriteria.honeIDUpperLimit.max) filterBuilder['honeInnerDiameter.upperLimit'].$lte = filterCriteria.honeIDUpperLimit.max;
      }
      if (filterCriteria.tubeOuterDiameter?.min || filterCriteria.tubeOuterDiameter?.max) {
        filterBuilder.tubeOuterDiameter = {};
        if (filterCriteria.tubeOuterDiameter.min) filterBuilder.tubeOuterDiameter.$gte = filterCriteria.tubeOuterDiameter.min;
        if (filterCriteria.tubeOuterDiameter.max) filterBuilder.tubeOuterDiameter.$lte = filterCriteria.tubeOuterDiameter.max;
      }
    }

    if (searchQuery) {
      const customerSearchFilter = {
        $or: [
          { companyName: { $regex: searchQuery, $options: 'i' } },
          { companyAbbreviation: { $regex: searchQuery, $options: 'i' } },
          { companyId: { $regex: searchQuery, $options: 'i' } }
        ]
      };

      try {
        const { results: customers } = await CustomerStore.fetchPaginatedCustomers(1, 30, 'companyName', customerSearchFilter, '', 'companyName');

        if (customers && customers.length > 0) {
          const customerIds = customers.map(customer => customer._id);
          filterBuilder.customer = { $in: customerIds };
        } else {
          const searchFilter = {
            $or: [
              { shopOrderNumber: { $regex: searchQuery, $options: 'i' } },
              { salesOrderNumber: { $regex: searchQuery, $options: 'i' } },
              { orderNumber: { $regex: searchQuery, $options: 'i' } },
              { millJobNumber: { $regex: searchQuery, $options: 'i' } },
              { customerPONumber: { $regex: searchQuery, $options: 'i' } },
            ]
          }
          filterBuilder = { ...filterBuilder, ...searchFilter };
        }

      } catch (error) {
        const searchFilter = {
          $or: [
            { shopOrderNumber: { $regex: searchQuery, $options: 'i' } },
            { salesOrderNumber: { $regex: searchQuery, $options: 'i' } },
            { orderNumber: { $regex: searchQuery, $options: 'i' } },
            { millJobNumber: { $regex: searchQuery, $options: 'i' } },
            { customerPONumber: { $regex: searchQuery, $options: 'i' } },
          ]
        }

        filterBuilder = { ...filterBuilder, ...searchFilter };
      }
    }
    setPage(1);
    setFilter(filterBuilder);
  };

  const handleFilterChange = (model) => {
    let filterBuilder = {};
    if (model.items.length > 0) {
      model.items.forEach((item) => {
        if (item.value && item.operator === 'contains') {
          filterBuilder[item.field] = { $regex: item.value, $options: 'i' };
        }
      });
      setFilter(filterBuilder);
    } else {
      setFilter({});
    }
  };

  const handleTableSortChange = (model) => {
    let sort = '';
    if (model[0]) {
      sort = model[0].sort === 'asc' ? model[0].field : `-${model[0].field}`;
    } else {
      sort = '';
    }
    setSortBy(sort);
  }

  const orderInterface = {
    orderNumber: '',
    customerPONumber: '',
    millJobNumber: '',
    customer: '',
    status: '',
    paymentStatus: '',
    dueDate: new Date(),
    enteredDate: new Date(),
    shipDate: new Date(),
    total: { min: 0, max: 0 },
    honeIDLowerLimit: {
      min: 0,
      max: 0,
    },
    honeIDUpperLimit: {
      min: 0,
      max: 0
    },
    tubeOuterDiameter: {
      min: 0,
      max: 0
    },
    isCancelled: false,
  };

  const columns = [
    { field: 'salesOrderNumber', headerName: 'Order No.', flex: 0.5 },
    { field: 'customerName', headerName: 'Customer Name', flex: 1 },
    { field: 'customerPONumber', headerName: 'PO No.', flex: 0.6 },
    {
      field: 'enteredDate',
      headerName: 'Date Entered',
      flex: .7,
      valueFormatter: (params) => format(new Date(params.value), 'MM/dd/yyyy')
    },
    { field: 'millJobNumber', headerName: 'Mill No.', flex: 0.5 },
    {
      field: 'honeInnerDiameter',
      headerName: 'Hone ID',
      flex: .75,
      valueFormatter: (params) => `${params.value.lowerLimit.toFixed(3)}/${params.value.upperLimit.toFixed(3)}`
    },
    { field: 'tubeOuterDiameter', headerName: 'Tube OD', flex: .5, valueFormatter: (params) => params.value.toFixed(3) },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: .7,
      valueFormatter: (params) => format(new Date(params.value), 'MM/dd/yyyy')
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: .7,
      valueFormatter: (params) => `$${params.value.toFixed(2)}`
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      align: 'right',
      headerAlign: 'center',
      minWidth: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleViewOrder(params.row._id)}>
            <InfoIcon />
          </IconButton>
          <IconButton onClick={() => handleEditOrder(params.row._id)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteOrder(params.row._id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" gutterBottom>Orders</Typography>
        <DynamicSearchFilter
          defaultSort="-enteredDate"
          objectInterface={orderInterface}
          onSearch={handleDynamicSearch}
        />
        <IconButton color="primary" onClick={handleAddOrder}>
          <AddIcon />
        </IconButton>
      </Box>

      {isLoading ? (
        <CircularProgress />
      ) : (
        isLargeScreen ? (
          <DataGridPro
            rows={orders.results}
            columns={columns}
            pageSize={orders.pageSize}
            rowCount={orders.totalCount}
            loading={isLoading}
            autoHeight
            getRowId={(row) => row._id}
            onPageChange={handlePageChange}
            onPageSizeChange={handleRowsPerPageChange}
          />
        ) : (
          <List>
            {orders.results.map((order) => (
              <ListItem
                key={order._id}
                button
                onClick={() => handleViewOrder(order._id)}
              >
                <ListItemText
                  primary={`Order #${order.salesOrderNumber}`}
                  secondary={`Customer: ${order.customerName}, Date: ${format(new Date(order.enteredDate), 'MM/dd/yyyy')}, Due: ${format(new Date(order.dueDate), 'MM/dd/yyyy')}, Total: $${order.total}`}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEditOrder(order._id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteOrder(order._id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )
      )}

      <TablePagination
        component="div"
        count={orders.totalCount}
        page={orders.currentPage > 0 ? orders.currentPage - 1 : 0}
        onPageChange={handlePageChange}
        rowsPerPage={orders.pageSize}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[10, 25, 50]}
      />

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default OrdersPage;
