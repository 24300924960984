import { makeAutoObservable } from "mobx";
import apiHandler from "../handlers/ApiHandler";

class CommissionCodeStore {
  paginatedCommissionCodes = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: [],
  }
  commissionCode = null;

  async fetchPaginatedCommissionCodes(
    page = 1,
    pageSize = 20,
    sortBy = 'commissionCode',
    filter = {},
    search = '',
    searchField = 'commissionCode'
  ) {
    try {
      const { results, ...pagination } = await apiHandler.get(
        `/commissionCodes?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`
      );
      this.paginatedCommissionCodes = { ...pagination, results: results }
      return { results, ...pagination }
    } catch (error) {
      console.error("Error fetching account codes: ", error);
      throw error;
    }
  }

  async fetchCommissionCodeById(id) {
    try {
      const response = await apiHandler.get(`/commissionCodes/${id}`);
      this.commissionCode = response
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

}

const commissionCodeStore = new CommissionCodeStore();
export default commissionCodeStore;